import React, {useEffect, useState}  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Card, 
  Dropdown,
  Carousel
} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import DatePicker from "react-datepicker"
import { Formik } from 'formik'
import * as yup from 'yup'
import { Auth } from 'aws-amplify'
import * as AWS from 'aws-sdk'
import 'react-phone-number-input/style.css'
import "react-datepicker/dist/react-datepicker.css"
import './style/profile.css';
import { sidebar_check } from '../actions/sidebar'
import { progress_value } from '../actions/progress'
import fetch from '../helpers/methods'
import { income } from '../actions/income'
import Header from './Header'
const schema = yup.object().shape({
  job: yup.string().required('Please input your job title'),
  companyName: yup.string().required('Please input your company name'),
  share: yup.string().required('Please your share of company'),
  salary: yup.string().required('Please input your salary'),
  addIncomeMonthly: yup.string().required('Please input your monthly additional income amount'),
  monthlyAmount: yup.string().required('Please input your monthly amount'),
  outStandingAmount: yup.string().required('Please input your outstanding amount'),
  description: yup.string().required('Please input your notification'),
  cardLimit: yup.string().required('Please input your card limit')
});

const Profile = ({
  job,
  setJob,
  joinDate,
  setJoinDate,
  companyName,
  setCompanyName,
  industry,
  setIndustry,
  employmentStatus,
  setEmploymentStatus,
  duration,
  setDuration,
  share,
  setShare,
  salary,
  setSalary,
  expenditure,
  setExpenditure,
  addIncomeType,
  setAddIncomeType,
  addIncomeMonthly,
  setAddIncomeMonthly,
  bank,
  check,
  setCheck,
  handleCheck,
  setBank,
  mortgageMonthlyAmount,
  setMortgageMonthlyAmount,
  outStandingAmount,
  setOutStandingAmount,
  description,
  setDescription,
  cardLimit,
  setCardLimit,
  handleData,
  loanType,
  setLoanType,
  setMortgageBank,
  mortgageBank,
  establishDate,
  setEstablishDate,
  handleAlert,
  income_list,
  set_loan_list,
  addIncome,
  removeIncome,
  credit_list,
  addCredit,
  removeCredit,
  add_Income,
  setAddIncome,
  add_Credit,
  setAddCredit,
  addLoan,
  add_loan,
  setAddLoan,
  loan_list,
  removeLoan,
  set_credit_list
}) => {
  const dispatch = useDispatch();
  const[sidebar, setSidebar]=useState(false)
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
  useEffect(() => {
    fetch()
    .then(data => {
      data=data.data.data;
      const employment_status = data.employment_status;
      const job_title_c = data.job_title;
      const date_of_joining_c = data.date_of_joining;
      const company_name = data.company_name;
      const industry= data.industry;
      const date_of_establishing_company_c = data.date_of_establishing_company;
      const years_working_c = data.years_working;
      const company_share_c = data.company_share;
      const fixed_monthly_salary_c = data.fixed_monthly_salary;
      const expenditure = data.expenditure;
      const additional_income = data.additional_income;
      const credit_card_limits = data.credit_card_limits;
      const requesting_loan_amount = data.requesting_loan_amount;
      const bank=data.bank;
      const loan_type=data.loan_type;
      const mortgage_bank = data.mortgage_bank;
      const outStanding_amount=data.outStanding_amount;
      const add_income=data.add_income;
      const add_credit=data.add_credit;
      const description=data.description;
      const creditList=data.credit_list;
      const loanList=data.loan_list;
      const checkbox=data.checkbox;
      const payload = {
        employment_status:employment_status,
        job_title_c:job_title_c,
        date_of_joining_c:date_of_joining_c,
        company_name:company_name,
        industry:industry,
        date_of_establishing_company_c:date_of_establishing_company_c,
        years_working_c:years_working_c,
        company_share_c:company_share_c,
        fixed_monthly_salary_c:fixed_monthly_salary_c,
        expenditure:expenditure,
        additional_income_amount:additional_income,
        credit_card_limits:credit_card_limits,
        requesting_loan_amount:requesting_loan_amount,
        additional_income_type:'',
        bank:bank,
        description:description,
        loan_type:loan_type,
        mortgage_bank:mortgage_bank,
        outStanding_amount:outStanding_amount,
        add_income:add_income,
        add_credit:add_credit,
        creditList:creditList,
        loanList:loanList,
        checkbox:checkbox
      }
      dispatch(income(payload))
      //setJob(job_title_c)
      // if (date_of_joining_c)
      //   setJoinDate(new Date(date_of_joining_c))
      // else
        setJoinDate(date_of_joining_c)
        setJob(job_title_c)
      setCompanyName(company_name)
      setIndustry(industry)
      setEstablishDate(date_of_establishing_company_c)
      setDuration(years_working_c);
      setShare(company_share_c);
      setBank(bank);
      setSalary(fixed_monthly_salary_c);
      setExpenditure(expenditure);
      setAddIncome(additional_income);
      setCardLimit(credit_card_limits);
      setAddLoan(requesting_loan_amount);
      setMortgageMonthlyAmount(requesting_loan_amount);
      setDescription(description);
      setLoanType(loan_type);
      setMortgageBank(mortgage_bank)
      setAddIncome(add_income);
      setOutStandingAmount(outStanding_amount)
      setEmploymentStatus(employment_status) 
      setAddCredit(add_credit)
      setCheck(checkbox)
      setAddIncomeMonthly(additional_income)
      if(creditList)
      set_credit_list(creditList)
      if (loanList)
        set_loan_list(loanList)
    })
   
    
  },[])

  const reduxData = useSelector(state => state.income)
  //console.log('reduxData',reduxData)
  const bcf_job = reduxData.currentUser.job_title_c;
  const bcf_company = reduxData.currentUser.company_name;
  const bcf_joinDate = reduxData.currentUser.date_of_joining_c;
  const bcf_establishDate = reduxData.currentUser.date_of_establishing_company_c;
  const bcf_industry =  reduxData.currentUser.industry;
  const bcf_share = reduxData.currentUser.company_share_c;
  const bcf_salary = reduxData.currentUser.fixed_monthly_salary_c;
  const bcf_expenditure = reduxData.currentUser.expenditure;
  const bcf_addIncomeType = reduxData.currentUser.additional_income_type;
  const bcf_addIncomeMonthly = reduxData.currentUser.additional_income_amount;
  const bcf_bank = reduxData.currentUser.creditList;
  const bcf_cardLimit = reduxData.currentUser.credit_card_limits;
  const bcf_mortgageMonthlyAmount = reduxData.currentUser.requesting_loan_amount;
  const bcf_description = reduxData.currentUser.description;
  const bcf_duration = reduxData.currentUser.years_working_c;
  const bcf_loanType = reduxData.currentUser.loan_type;
  const bcf_mortgageBank = reduxData.currentUser.mortgage_bank;
  const bcf_add_Income= reduxData.currentUser.add_income;
  const bcf_add_Credit= reduxData.currentUser.add_credit;
  const bcf_outStandingAmount = reduxData.currentUser.outStanding_amount;
  const bcf_employmentStatus=reduxData.currentUser.employment_status;
  const bcf_check=reduxData.currentUser.checkbox;
  
    
   
  return (
    <body class={`${sidebar ? 'overlay-body' : ''}`} style={{width:'100%'}}>
       <Header/>
       <div class="home" style={{backgroundColor:'#f4f4f6' }}>

     
        
    <Container fluid className='app-container'>
      <div className='pb-5' style={{paddingTop:'120px' , marginBottom:'60px'}}>
        <h1 style={{
          fontFamily: 'AGaramond-regular',
          fontSize: '64px',
          letterSpacing: '-5px',
        }}
        >Income</h1>
        <p>
        Share details about your financial situation to help the bank 
        calculate the best suited loan amount for you.
        </p>
        <Formik
          validationSchema={schema}
          onSubmit={console.log}
          initialValues={{
          
           industry:industry,
          }}
        >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Card className='striped-tabled-with-hover mt-5 py-5 px-3 ' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px'}}>
            <h4 style={{
              fontFamily: 'AGaramond-regular',
              fontSize: '32px',
              letterSpacing: '-2px',
            }}
            >Employment status</h4>
            <div key='inline-radio' style={{width:'100%'}}>
        {bcf_employmentStatus ?
            <div>
            <Form.Check 
              inline
              type='radio'
              label='Employee at a company'
              value='Employee at a company'
              name='group'
              id='radio_1'
              //disabled={bcf_employmentStatus?true:false}
              checked={bcf_employmentStatus==='Employee at a company'}
              onChange={(e) => setEmploymentStatus(e.target.value)}
            />
            <Form.Check 
              inline
              type='radio'
              label='Self-employed'
              name='group'
              value='Self-employed'
              id='radio_2' 
             // disabled={bcf_employmentStatus?true:false}
              checked={bcf_employmentStatus==='Self-employed'}
              onChange={(e) => setEmploymentStatus(e.target.value)}
            />
             <Form.Check 
              inline
              type='radio'
              label='Others'
              name='group'
              value='Others'
              id='radio_3' 
              //disabled={bcf_employmentStatus?true:false}
              checked={bcf_employmentStatus==='Others'}
              onChange={(e) => setEmploymentStatus(e.target.value)}
            />
            </div>
            :
            <div>
            <Form.Check 
            inline
            type='radio'
            label='Employee at a company'
            value='Employee at a company'
            name='group'
            id='radio_1'
            //disabled={bcf_employmentStatus?true:false}
           // checked={bcf_employmentStatus==='Employee at a company'}
            onChange={(e) => setEmploymentStatus(e.target.value)}
          />
          <Form.Check 
            inline
            type='radio'
            label='Self-employed'
            name='group'
            value='Self-employed'
            id='radio_2' 
           // disabled={bcf_employmentStatus?true:false}
           // checked={bcf_employmentStatus==='Self-employed'}
            onChange={(e) => setEmploymentStatus(e.target.value)}
          />
           <Form.Check 
            inline
            type='radio'
            label='Others'
            name='group'
            value='Others'
            id='radio_3' 
            //disabled={bcf_employmentStatus?true:false}
            //checked={bcf_employmentStatus==='Others'}
            onChange={(e) => setEmploymentStatus(e.target.value)}
          />
          </div>
        }
           
            <Form.Group className="d-flex justify-content-between align-items-center mt-3 gap-2 income-mobile" controlId="formBasicEmail">
              <div style={{width:'100%'}}>
                <Form.Label>Job title</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="E.g.Lawyer, Teacher, Engineer" 
                  name='job'
                  //disabled={bcf_job}
                  defaultValue={bcf_job ? bcf_job : job}
                  onChange={e => {
                    handleChange(e)
                    setJob(e.target.value)
                  }}
                />
              </div>
              <div style={{width:'100%'}}> 
              {employmentStatus == 'Self-employed' ?
                <>
                  <Form.Label>Date of establishing the company</Form.Label>
                  <DatePicker 
                    defaultValue={bcf_establishDate ? bcf_establishDate : new Date()}                    
                    selected={establishDate?new Date(establishDate):new Date()}
                    onChange={(date) => setEstablishDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat={"dd-MM-yyyy"}
                  />
                </>
                :
                <>
                  <Form.Label>Date of joining</Form.Label>
                  <DatePicker 
                    defaultValue={bcf_joinDate ? bcf_joinDate : new Date()}                    
                    selected={joinDate?new Date(joinDate):new Date()}
                   // disabled={bcf_joinDate ? true : false}
                    onChange={(date) => setJoinDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat={"dd-MM-yyyy"}
                  />
                </>
              }
              </div>
            </Form.Group>
            <Form.Group className="d-flex justify-content-between align-items-center mt-3 gap-2" controlId="formBasicEmail">
              <div style={{width:'100%'}}>
                <Form.Label>Company name</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="IT Web Service" 
                  name='companyName'
                  //disabled={bcf_company}
                  defaultValue={bcf_company ? bcf_company : companyName}
                  onChange={e => {
                    handleChange(e)
                    setCompanyName(e.target.value)
                  }}
                />
              </div>
              <div style={{width:'100%'}}> 
                <Form.Label>Industry</Form.Label>
                <Form.Select 
                  onChange={e => setIndustry(e.target.value)}  
                  //disabled={bcf_industry}
                  defaultValue={bcf_industry ? bcf_industry : industry}
                >
                  <option industry='Finance'>Finance</option>
                  <option industry='Healthcare'>Healthcare</option>
                  <option industry='Technology'>Technology</option>
                  <option industry='FinReal Estateance'>Real Estate</option>
                  <option industry='Food Industry'>Food Industry</option>
                  <option industry='FiTransportnance'>Transport</option>
                  <option industry='Other'>Other</option>
                </Form.Select>
              </div>
            </Form.Group>

          { employmentStatus === 'Self-employed' && 
            <Form.Group className="d-flex justify-content-between align-items-center mt-3 gap-2" controlId="formBasicEmail">
             <div style={{width:'100%'}}> 
              <Form.Label>How long have you been working there?</Form.Label>
              <Form.Select
                defaultValue={bcf_duration ? bcf_duration : duration}
                onChange={e => setDuration(e.target.value)}
              >
                <option duration='0 to 5 years'>0 to 5 years</option>
                <option duration='5 to 10 years'>5 to 10 years</option>
                <option duration='10 to 20 years'>10 to 20 years</option>
                <option duration='+20 years'>+20 years</option>
              </Form.Select>
            </div>
            <div style={{width:'100%'}}>
              <Form.Label>You share of the company</Form.Label>
              <Form.Control 
                type="number" 
                placeholder="Your shares percentage" 
                defaultValue={bcf_share ? bcf_share :share}
                onChange={e => {
                  handleChange(e)
                  setShare(e.target.value)
                }}
              />
            </div>
          </Form.Group>
          }
            </div>
          </Card>
          <Card className='striped-tabled-with-hover mt-5 py-5 px-3' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px'}}>
            <h4 style={{
              fontFamily: 'AGaramond-regular',
              fontSize: '32px',
              letterSpacing: '-2px',
            }}
            >Income</h4>
            <p>Share all earnings that are currently coming into your bank account.</p>
              <Form.Group>
                <Form.Label>Fixed monthly income</Form.Label>
                <InputGroup>
                  <Form.Control 
                    type='number' 
                    placeholder='E.g 25,000' 
                    defaultValue={bcf_salary ? bcf_salary : salary} 
                   // disabled={bcf_salary}
                    style={{width:'30%'}} 
                    onChange={e => {
                      handleChange(e)
                      setSalary(e.target.value)
                    }} 
                    />
                  <InputGroup.Text>AED</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className='mt-3'>
                <div className='d-flex justify-content-between'>
                  <h5 style={{
                      fontFamily: 'AGaramond-regular',
                      fontSize: '24px',
                      letterSpacing: '-1px',
                    }}
                    >Additional Income
                  </h5>
                </div>
                <div>
                    <Col lg={5}>
                            <Form.Label>Monthly amount</Form.Label>
                    </Col>
                    { income_list.map((item, index) => 
                      <Row className='mb-3'>
                          <InputGroup>
                            <Form.Control 
                              type='number' 
                              placeholder='E.g.5000'
                              defaultValue={bcf_addIncomeMonthly ? bcf_addIncomeMonthly : addIncomeMonthly}
                            //  disabled={bcf_addIncomeMonthly}
                              onChange={e => {
                                handleChange(e)
                                setAddIncomeMonthly(e.target.value)
                              }}
                            />
                            <InputGroup.Text>AED</InputGroup.Text>
                          </InputGroup>
                      </Row>
                    )}
                  </div>
              </Form.Group>
              
          </Card>
          <Card className='striped-tabled-with-hover mt-5 py-5 px-3' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px'}}>
            <h4 style={{
              fontFamily: 'AGaramond-regular',
              fontSize: '32px',
              letterSpacing: '-2px',
            }}
            >Monthly Expenditure</h4>
            <p>Share your monthly expenditure.</p>
              <Form.Group>
                <InputGroup>
                  <Form.Control 
                    type='number' 
                    placeholder='E.g 25,000' 
                    defaultValue={bcf_expenditure ? bcf_expenditure : expenditure} 
                    //disabled={bcf_expenditure}
                    style={{width:'30%'}} 
                    onChange={e => {
                      handleChange(e)
                      setExpenditure(e.target.value)
                    }} 
                    />
                  <InputGroup.Text>AED</InputGroup.Text>
                </InputGroup>
              </Form.Group>
          </Card>
          <Card className='striped-tabled-with-hover mt-5 py-5 px-3' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px'}}>
            <h4 style={{
              fontFamily: 'AGaramond-regular',
              fontSize: '32px',
              letterSpacing: '-2px',
            }}
            >Liabilities</h4>
              <p>Declare any outgoing payments or debts that are currently being deducted from your bank account.</p>
              <div className='d-flex justify-content-between mt-3'>
                <h5 style={{
                  fontFamily: 'AGaramond-regular',
                  fontSize: '24px',
                  letterSpacing: '-2px',
                }}
                >Credit Cards</h5>               
              </div>
                <div>
                   { credit_list.map((item, index) => 
                    <Row className='mb-3'>
                          
                      <Col lg={1} style={{display:'flex', flexDirection:'column',justifyContent:'flex-end'}}>
                        <Button variant='dark' style={{borderRadius:'20px', width:'37px'}}>
                          {item.id + 1} 
                        </Button>
                      </Col>
                      <Col lg={5}>
                        <Form.Group>
                          <Form.Label>Bank</Form.Label>
                          <Form.Select
                            name={"bank"+index}
                            id={"bank"+index}
                            key={"bank"+index}
                            value={credit_list ? credit_list[index]['name']  : item.value}
                            onChange={e => {
                              console.log(e.target.value);                              
                              credit_list[index]["name"]=e.target.value                             
                              handleChange(e)
                            }}
                           // disabled={bcf_bank}
                          >
                            <option value='United Arab Bank'>United Arab Bank</option>
                            <option value='Commercial Bank of Dubai'>Commercial Bank of Dubai</option>
                            <option value='National Bank of Fujairah'>National Bank of Fujairah</option>
                            <option value='Al Hilal Bank'>Al Hilal Bank</option>
                            <option value='Emirates NBD'>Emirates NBD</option>
                            <option value='FAB (First Abu Dhabi Bank)'>FAB (First Abu Dhabi Bank)</option>
                            <option value='Mashreq'>Mashreq</option>
                            <option value='RAK Bank'>RAK Bank</option>
                            <option value='Bank of Baroda'>Bank of Baroda</option>
                            <option value='HSBC'>HSBC</option>
                            <option value='Sharjah Islamic Bank'>Sharjah Islamic Bank</option>
                            <option value='Standard Charted Bank'>Standard Charted Bank</option>
                            <option value='Emirates Islamic Bank'>Emirates Islamic Bank</option>
                            <option value='Dubai Islamic Bank'>Dubai Islamic Bank</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col lg={5}>
                        <Form.Group>
                          <Form.Label>Card limit</Form.Label>
                          <InputGroup inline>
                            <Form.Control 
                              type='number'
                              defaultValue={credit_list ? item.value : ''}
                              //disabled={bcf_cardLimit}
                              onChange={e => {
                                handleChange(e)
                                credit_list[index]["value"]=e.target.value
                              }}
                            />
                            <InputGroup.Text>AED</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      { item.id == 0 &&
                          <Col lg={1} style={{display:'flex', flexDirection:'column',justifyContent:'flex-end'}}>
                            <Button variant='outline-dark' style={{borderRadius:'20px', width:'37px'}} onClick={() => addCredit(item.id)}>
                              +
                            </Button>
                          </Col>
                        }
                        { item.id !== 0 &&
                          <Col lg={1} style={{display:'flex', flexDirection:'column',justifyContent:'flex-end'}}>
                            <Button variant='outline-dark' style={{borderRadius:'20px', width:'37px'}} onClick={() => removeCredit(item.id)}>
                              -
                            </Button>
                          </Col>
                        }
                    </Row>
              )}
                </div>
              <hr></hr>
              <div className='d-flex justify-content-between mt-3'>
                <h5 className='mt-3' style={{
                  fontFamily: 'AGaramond-regular',
                  fontSize: '24px',
                  letterSpacing: '-2px',
                }}
                >Current loans and mortgages</h5>
                </div>
              <Form.Group>                
                  <div>
                     { loan_list.map((item, index) => 
                      <>
                      <Row>
                        <Col lg={1} style={{display:'flex', alignItems:'center',justifyContent:'center'}}>
                          <Button variant='dark' style={{borderRadius:'20px', width:'37px'}}>
                            {item.id + 1}
                          </Button>
                        </Col>
                        <Col lg={10}>
                          <Row>
                            <Col>
                              <Form.Label>Type</Form.Label>
                              <Form.Select
                               name={"type"+index}
                               id={"type"+index}
                               key={"type"+index}
                               value={loan_list ? loan_list[index]['type']  : item.value}
                               onChange={e => {
                                handleChange(e)
                                loan_list[index]["type"]=e.target.value
                              }}
                              // disabled={bcf_loanType}
                              defaultValue={loan_list ? item.type : loanType}
                                
                              >
                                <option>Personal loan</option>
                                <option>Mortgate loan</option>
                                <option>Auto loan</option>
                                <option>Other</option>
                              </Form.Select>
                            </Col>
                            <Col>
                              <Form.Label>Monthly amount</Form.Label>
                              <InputGroup inline>
                                <Form.Control 
                                  type='number'
                                  defaultValue={loan_list  ? item.monthlyamount : ''}
                                  //disabled={bcf_mortgageMonthlyAmount}
                                  onChange={e => {
                                    handleChange(e)
                                    loan_list[index]["monthlyamount"]=e.target.value
                                  }}
                                  />
                                <InputGroup.Text>AED</InputGroup.Text>
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row className='mt-3'>
                            <Col>
                            <Form.Label>Bank</Form.Label>
                              <Form.Select
                                name={"loanbank"+index}
                                id={"loanbank"+index}
                                key={"loanbank"+index}
                                value={loan_list ? loan_list[index]['name']  : item.value}                                
                                onChange={e => {
                                  loan_list[index]["name"]=e.target.value
                                  handleChange(e)                                  
                                }}
                                //disabled={bcf_mortgageBank}
                              >
                                <option value='United Arab Bank'>United Arab Bank</option>
                                <option value='Commercial Bank of Dubai'>Commercial Bank of Dubai</option>
                                <option value='National Bank of Fujairah'>National Bank of Fujairah</option>
                                <option value='Al Hilal Bank'>Al Hilal Bank</option>
                                <option value='Emirates NBD'>Emirates NBD</option>
                                <option value='FAB (First Abu Dhabi Bank)'>FAB (First Abu Dhabi Bank)</option>
                                <option value='Mashreq'>Mashreq</option>
                                <option value='RAK Bank'>RAK Bank</option>
                                <option value='Bank of Baroda'>Bank of Baroda</option>
                                <option value='HSBC'>HSBC</option>
                                <option value='Sharjah Islamic Bank'>Sharjah Islamic Bank</option>
                                <option value='Standard Charted Bank'>Standard Charted Bank</option>
                                <option value='Emirates Islamic Bank'>Emirates Islamic Bank</option>
                                <option value='Dubai Islamic Bank'>Dubai Islamic Bank</option>
                              </Form.Select>
                            </Col>
                            <Col>
                              <Form.Label style={{fontSize: 'calc(12px + 0.5vw)'}}>Outstanding amount</Form.Label>
                              <InputGroup inline>
                                <Form.Control 
                                  type='number'
                                  defaultValue={loan_list  ? item.outstandingamount : ' '}
                                  onChange={e => {
                                    handleChange(e)
                                    loan_list[index]["outstandingamount"]=e.target.value
                                  }}
                                  //disabled={bcf_outStandingAmount}
                                />
                                <InputGroup.Text>AED</InputGroup.Text>
                              </InputGroup>
                            </Col>
                          </Row>
                        </Col>
                        { item.id == 0 &&
                            <Col lg={1} style={{display:'flex', alignItems:'center',justifyContent:'center'}}>
                              <Button variant='outline-dark' style={{borderRadius:'20px', width:'37px'}} onClick={() => addLoan(item.id)}>
                                +
                              </Button>
                            </Col>
                          }
                          { item.id !== 0 &&
                            <Col lg={1} style={{display:'flex', alignItems:'center',justifyContent:'center'}}>
                              <Button variant='outline-dark' style={{borderRadius:'20px', width:'37px'}} onClick={() => removeLoan(item.id)}>
                                -
                              </Button>
                            </Col>
                          }
                      </Row>
                      </>
                      )}
                  </div>  
              </Form.Group>
              <hr></hr>
              <h5 className='mt-3' style={{
                fontFamily: 'AGaramond-regular',
                fontSize: '24px',
                letterSpacing: '-2px',
              }}
              >Anything else we should know?</h5>
              <p>
                Please share any additional info or plans regarding your financial situation. 
                The more details we have upfront, the faster we can get your application processed successfully.
              </p>
              <InputGroup>
              <Form.Control
                type='text'
                name='description'
                placeholder='Tell us here anything that can affect your case'
                defaultValue={bcf_description? bcf_description: description}
                //disabled={bcf_description}
                onChange={e => {
                  handleChange(e)
                  setDescription(e.target.value)
                }}
                onBlur={handleBlur}
              />
              </InputGroup>
          </Card>
          <br/>
          {bcf_check ?
          <Form.Check
                    type='checkbox'
                    name='confirm'
                    id='income'
                    checked={bcf_check===true}
                    onChange={(e)=>handleCheck(e)}
                    required="required" 
                    label='Through this form I declare that the information above is correct and true, to the best of my knowledge'                    
                  />
                  :
                  <Form.Check
                    type='checkbox'
                    name='confirm'
                    id='income'
                    onChange={(e)=>handleCheck(e)}
                    required="required" 
                    label='Through this form I declare that the information above is correct and true, to the best of my knowledge'                    
                  />
          }

          <div className='w-100 d-flex justify-content-end mt-5'>
            { check ? 
              <Link to='/Property'
              style={{
                textDecoration: 'none',
              }}
            >
              <Button
                className="w-100 d-flex justify-content-center align-items-center rounded-4"
                variant="outline-dark"
                type="submit"
                style={{minWidth:'150px', whiteSpace: 'nowrap',}}
                onClick={()=> {
                  // dispatch(sidebar_check('income'));
                  // dispatch(progress_value(35));
                  handleData()
                }}
              >
                Next step
              </Button>
            </Link>
              : 
              <Link to='#'
              style={{
                textDecoration: 'none',
              }}
            >
                <Button
                  className="w-100 d-flex justify-content-center align-items-center rounded-4"
                  variant="outline-dark"
                  type="submit"
                  style={{minWidth:'150px', whiteSpace: 'nowrap',}}
                  onClick={()=> {
                    handleData()
                  }}
                >
                  Next step
                </Button>
             </Link>
            }
          </div>
        </Form>
      )}
        </Formik>
      </div>
    </Container>
    </div>
    </body>
  )
  
}


export default Profile
