import {
  INCOME 
} from '../constants/actionTypes/income'

const INITIAL_STATE = {
  currentUser: {
    employment_status:'',
    job_title_c:'',
    date_of_joining_c:'',
    company_name:'',
    industry:'',
    date_of_establishing_company_c:'',
    years_working_c:'',
    company_share_c:'',
    fixed_monthly_salary_c:'',
    expenditure:'',
    bcf_addIncomeType:'',
    additional_income_type:'',
    additional_income_amount:'',
    credit_card_limits:'',
    requesting_loan_amount:'',
    bank:'',
    description:'',
    loan_type:'',
    mortgage_bank:'',
    outStanding_amount:'',
    add_Income:'',
    add_Credit:'',
    creditList:'',
    loanList:'',
    checkbox:''
  }
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INCOME:
      return {
        ...state,
        currentUser:action.payload
      }
    default:
      return state
  }
}

export default reducer