import React, { useState } from 'react'
import axios from 'axios'
import md5 from 'md5'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import ProfileComponent from '../components/Profile'
import { sidebar_check } from '../actions/sidebar'
import { profile } from '../actions/profile'
import { Auth } from 'aws-amplify'
import { Navigate } from 'react-router-dom'

const Profile = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [lookingFor, setLookingFor] = useState('')
  const [country, setCountry] = useState('Afghanistan')
  const [residency, setResidency] = useState('UAE Resident')
  const [martial, setMartial] = useState('single')
  const [birthDate, setBirthDate] = useState(new Date())
  const [currentEmail, setCurrentEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const userData = {    
    first_name:firstName,
    last_name:lastName,
    email:currentEmail ? currentEmail : email,
    phone_number:phoneNumber,
    birth_date:birthDate,
    nationality:country?.name,
    residency_status:residency,
    marital_status:martial,
    looking_for:lookingFor,
  }
  const handleData = async () => {
    let session = await Auth.currentSession();    
    try {    
      dispatch(profile(userData));      
      const response = axios({
        method: 'post',
        headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
        url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
        data: userData,
        crossDomain: true
      });
      dispatch(sidebar_check('Profile')) 
      Navigate('/Income')     
    }
    catch (errors) {
      console.log('errors', errors)
    }
  }
  const handleAlert = () => {
    addToast('An error happened. Please try again.', {
      appearance:'error',
      autoDismiss:true
    })
  }

  return (
    <ProfileComponent 
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      email={email}
      setEmail={setEmail}
      tel={tel}
      setTel={setTel}
      country={country}
      setCountry={setCountry}
      residency={residency}
      setResidency={setResidency}
      martial={martial}
      setMartial={setMartial}
      birthDate={birthDate}
      setBirthDate={setBirthDate}
      handleData={handleData}
      userData={userData}
      currentEmail={currentEmail}
      setCurrentEmail={setCurrentEmail}
      setPhoneNumber={setPhoneNumber}
      phoneNumber={phoneNumber}
      handleAlert={handleAlert}
      lookingFor={lookingFor}
      setLookingFor={setLookingFor}
    />
  )
}

export default Profile