import React , {useState, useEffect} from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,
    
  } from 'react-bootstrap'
  import Footer from './Footer';
  //   import {GiHamburgerMenu } from "react-icons/gi";
  import { Link , useNavigate } from 'react-router-dom'
  import Calculator from './Calculator'
  import Header from './Header'
  import $ from 'jquery'
  import Carousel from 'react-multi-carousel';
  import 'react-multi-carousel/lib/styles.css';
  import '../assets/css/responsive.scss'
  // import { Helmet } from 'react-helmet'
  import Findloan from './Findloan';

const Compare = () =>{
    const[sidebar, setSidebar]=useState(false)
    const navigate=useNavigate();
    const [navClass, setNavClass] = useState('');

    const handleScroll = () => {
      if (window.scrollY > 120) {
        setNavClass('is-sticky');
      } else {
        setNavClass('');
      }
    };
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
 const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1100 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1100, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2
    },
    smallMobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1
      }
  };
 useEffect(() => {
    $('.loader').fadeOut(1500);
    window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }); 
    return(
        <body data-spy="scroll" data-offset="70"  class={`${sidebar ? 'overlay-body' : ''}`}>
             {/* <!-- Preloader --> */}
        <div class="loader">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
        {/* <!-- End Preloader --> */}
         {/* <!-- Start Main Navbar Area --> */}
        
         <Header/>
        {/* <!-- End Main Navbar Area --> */}

        {/* <!-- Start Confidence Home Area --> */}
        <div id="home" class="confidence-home-area" style={{marginTop:'100px'}}>
            <div class="container-fluid">
                <div class="row align-items-center">
                        <div class="col-lg-12 col-md-12" style={{textAlign: 'center'}}>
                        <div class="confidence-home-content">
                        <span class="sub-title" style={{fontSize: '25px'}}>Reliable Sustainable Provider</span>
                        <h1>Compare the Mortgage</h1>
                        <p style={{textAlign:'center',margin:'auto'}}>
                            Experinces started sit amet consectetur adipiscing elit sed do eiusmod inciddunt ut labore et dolore adipiscing elit sed do eiusr adipiscing</p>
                        
                        <a href="#" class="main-default-btn2" >Islamic Finance</a>
                        <a href="#" class="main-default-btn2" >Whole of Market</a>
                        <a href="#" class="main-default-btn2" >Instant Quotation</a>
                        <a href="#" class="main-default-btn2" >Islamic Banking</a>
                        <a href="#" class="main-default-btn2" >Nill life insurance</a>
                        <a href="#" class="main-default-btn2" >Sharia Compliant</a>
                        <a href="#" class="main-default-btn2" >No Salary Transfer</a>
                        <a href="#" class="main-default-btn2" >Online Application</a>
                        <a href="#" class="main-default-btn2" >Free processing fee</a>
                        <a href="#" class="main-default-btn2" >Free-preapproval</a>
                        <a href="#" class="main-default-btn2" >Free evaluation</a>
                        <a href="#" class="main-default-btn2" >Free building insurance</a>
                        </div>
                        </div>

                </div>
            </div>
        </div>
        
        {/* <!-- End Confidence Home Area --> */}
        <div class="why-choose-us-area-without-bg">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="why-choose-us-item">
                       <div style={{paddingBottom:'10px',alignItems:'center', borderBottom:'1px solid lightgrey' , display:'flex'}}>
                        <img src="/assets/images/cropped-logo.png" alt="image" style={{height:'70px'}}/>
                        <h3 style={{textAlign:'center',marginTop:'30px', marginLeft:'20px'}}>Amlak Istithmari </h3>
                        </div> 
                          <div class='row' style={{marginTop:'20px' , display:'flex' , textAlign:'center'}}>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 10,000</h4>
                             <p >Minimum Salary</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 0</h4>
                             <p >Monthly Payment</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}> - </h4>
                             <p >Flat Rate</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>35%</h4>
                             <p >DownPayment</p>
                           </div>
                           </div>
                           <div class='col-lg-12' style={{textAlign:'center'}}>
                          <a href="https://www.user.bcf-mortgage.com/" class="main-default-btn2" style={{width:'200px'}} >Apply Now &nbsp; <img src="/assets/images/keyboard-arrow-right.svg" alt="arrow" /></a>
                           <a href="/mortgage" class="main-default-btn2">Calculate Monthly Mortgage &nbsp; </a>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="why-choose-us-area-without-bg">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="why-choose-us-item">
                       <div style={{paddingBottom:'10px',alignItems:'center', borderBottom:'1px solid lightgrey' , display:'flex'}}>
                        <img src="/assets/images/cropped-logo.png" alt="image" style={{height:'70px'}}/>
                        <h3 style={{textAlign:'center',marginTop:'30px', marginLeft:'20px'}}>Amlak Istithmari </h3>
                        </div> 
                          <div class='row' style={{marginTop:'20px' , display:'flex' , textAlign:'center'}}>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 10,000</h4>
                             <p >Minimum Salary</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 0</h4>
                             <p >Monthly Payment</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}> - </h4>
                             <p >Flat Rate</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>35%</h4>
                             <p >DownPayment</p>
                           </div>
                           </div>
                           <div class='col-lg-12' style={{textAlign:'center'}}>
                          <a href="https://www.user.bcf-mortgage.com/" class="main-default-btn2" style={{width:'200px'}} >Apply Now &nbsp; <img src="/assets/images/keyboard-arrow-right.svg" alt="arrow" /></a>
                           <a href="/mortgage" class="main-default-btn2">Calculate Monthly Mortgage &nbsp; </a>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="why-choose-us-area-without-bg">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="why-choose-us-item">
                       <div style={{paddingBottom:'10px',alignItems:'center', borderBottom:'1px solid lightgrey' , display:'flex'}}>
                        <img src="/assets/images/cropped-logo.png" alt="image" style={{height:'70px'}}/>
                        <h3 style={{textAlign:'center',marginTop:'30px', marginLeft:'20px'}}>Amlak Istithmari </h3>
                        </div> 
                          <div class='row' style={{marginTop:'20px' , display:'flex' , textAlign:'center'}}>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 10,000</h4>
                             <p >Minimum Salary</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 0</h4>
                             <p >Monthly Payment</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}> - </h4>
                             <p >Flat Rate</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>35%</h4>
                             <p >DownPayment</p>
                           </div>
                           </div>
                           <div class='col-lg-12' style={{textAlign:'center'}}>
                          <a href="https://www.user.bcf-mortgage.com/" class="main-default-btn2" style={{width:'200px'}} >Apply Now &nbsp; <img src="/assets/images/keyboard-arrow-right.svg" alt="arrow" /></a>
                           <a href="/mortgage" class="main-default-btn2">Calculate Monthly Mortgage &nbsp; </a>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="why-choose-us-area-without-bg">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="why-choose-us-item">
                       <div style={{paddingBottom:'10px',alignItems:'center', borderBottom:'1px solid lightgrey' , display:'flex'}}>
                        <img src="/assets/images/cropped-logo.png" alt="image" style={{height:'70px'}}/>
                        <h3 style={{textAlign:'center',marginTop:'30px', marginLeft:'20px'}}>Amlak Istithmari </h3>
                        </div> 
                          <div class="row" style={{marginTop:'20px' , display:'flex' , textAlign:'center'}}>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 10,000</h4>
                             <p >Minimum Salary</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 0</h4>
                             <p >Monthly Payment</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}> - </h4>
                             <p >Flat Rate</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>35%</h4>
                             <p >DownPayment</p>
                           </div>
                           </div>
                           <div class='col-lg-12' style={{textAlign:'center'}}>
                          <a href="https://www.user.bcf-mortgage.com/" class="main-default-btn2" style={{width:'200px'}} >Apply Now &nbsp; <img src="/assets/images/keyboard-arrow-right.svg" alt="arrow" /></a>
                           <a href="/mortgage" class="main-default-btn2">Calculate Monthly Mortgage &nbsp; </a>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="why-choose-us-area-without-bg">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="why-choose-us-item">
                       <div style={{paddingBottom:'10px',alignItems:'center', borderBottom:'1px solid lightgrey' , display:'flex'}}>
                        <img src="/assets/images/cropped-logo.png" alt="image" style={{height:'70px'}}/>
                        <h3 style={{textAlign:'center',marginTop:'30px', marginLeft:'20px'}}>Amlak Istithmari </h3>
                        </div> 
                          <div class="row" style={{marginTop:'20px' , display:'flex' , textAlign:'center'}}>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 10,000</h4>
                             <p >Minimum Salary</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 0</h4>
                             <p >Monthly Payment</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}> - </h4>
                             <p >Flat Rate</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>35%</h4>
                             <p >DownPayment</p>
                           </div>
                           </div>
                           <div class='col-lg-12' style={{textAlign:'center'}}>
                          <a href="https://www.user.bcf-mortgage.com/" class="main-default-btn2" style={{width:'200px'}} >Apply Now &nbsp; <img src="/assets/images/keyboard-arrow-right.svg" alt="arrow" /></a>
                           <a href="/mortgage" class="main-default-btn2">Calculate Monthly Mortgage &nbsp; </a>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="why-choose-us-area-without-bg">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="why-choose-us-item">
                       <div style={{paddingBottom:'10px',alignItems:'center', borderBottom:'1px solid lightgrey' , display:'flex'}}>
                        <img src="/assets/images/cropped-logo.png" alt="image" style={{height:'70px'}}/>
                        <h3 style={{textAlign:'center',marginTop:'30px', marginLeft:'20px'}}>Amlak Istithmari </h3>
                        </div> 
                          <div class="row" style={{marginTop:'20px' , display:'flex' , textAlign:'center'}}>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 10,000</h4>
                             <p >Minimum Salary</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 0</h4>
                             <p >Monthly Payment</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}> - </h4>
                             <p >Flat Rate</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>35%</h4>
                             <p >DownPayment</p>
                           </div>
                           </div>
                           <div class='col-lg-12' style={{textAlign:'center'}}>
                          <a href="https://www.user.bcf-mortgage.com/" class="main-default-btn2" style={{width:'200px'}} >Apply Now &nbsp; <img src="/assets/images/keyboard-arrow-right.svg" alt="arrow" /></a>
                           <a href="/mortgage" class="main-default-btn2">Calculate Monthly Mortgage &nbsp; </a>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="why-choose-us-area-without-bg">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="why-choose-us-item">
                       <div style={{paddingBottom:'10px',alignItems:'center', borderBottom:'1px solid lightgrey' , display:'flex'}}>
                        <img src="/assets/images/cropped-logo.png" alt="image" style={{height:'70px'}}/>
                        <h3 style={{textAlign:'center',marginTop:'30px', marginLeft:'20px'}}>Amlak Istithmari </h3>
                        </div> 
                          <div class="row" style={{marginTop:'20px' , display:'flex' , textAlign:'center'}}>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 10,000</h4>
                             <p >Minimum Salary</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 0</h4>
                             <p >Monthly Payment</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}> - </h4>
                             <p >Flat Rate</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>35%</h4>
                             <p >DownPayment</p>
                           </div>
                           </div>
                           <div class='col-lg-12' style={{textAlign:'center'}}>
                          <a href="https://www.user.bcf-mortgage.com/" class="main-default-btn2" style={{width:'200px'}} >Apply Now &nbsp; <img src="/assets/images/keyboard-arrow-right.svg" alt="arrow" /></a>
                           <a href="/mortgage" class="main-default-btn2">Calculate Monthly Mortgage &nbsp; </a>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="why-choose-us-area-without-bg">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="why-choose-us-item">
                       <div style={{paddingBottom:'10px',alignItems:'center', borderBottom:'1px solid lightgrey' , display:'flex'}}>
                        <img src="/assets/images/cropped-logo.png" alt="image" style={{height:'70px'}}/>
                        <h3 style={{textAlign:'center',marginTop:'30px', marginLeft:'20px'}}>Amlak Istithmari </h3>
                        </div> 
                          <div class="row" style={{marginTop:'20px' , display:'flex' , textAlign:'center'}}>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 10,000</h4>
                             <p >Minimum Salary</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 0</h4>
                             <p >Monthly Payment</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}> - </h4>
                             <p >Flat Rate</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>35%</h4>
                             <p >DownPayment</p>
                           </div>
                           </div>
                           <div class='col-lg-12' style={{textAlign:'center'}}>
                          <a href="https://www.user.bcf-mortgage.com/" class="main-default-btn2" style={{width:'200px'}} >Apply Now &nbsp; <img src="/assets/images/keyboard-arrow-right.svg" alt="arrow" /></a>
                           <a href="/mortgage" class="main-default-btn2">Calculate Monthly Mortgage &nbsp; </a>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="why-choose-us-area-without-bg">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="why-choose-us-item">
                       <div style={{paddingBottom:'10px',alignItems:'center', borderBottom:'1px solid lightgrey' , display:'flex'}}>
                        <img src="/assets/images/cropped-logo.png" alt="image" style={{height:'70px'}}/>
                        <h3 style={{textAlign:'center',marginTop:'30px', marginLeft:'20px'}}>Amlak Istithmari </h3>
                        </div> 
                          <div class="row" style={{marginTop:'20px' , display:'flex' , textAlign:'center'}}>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 10,000</h4>
                             <p >Minimum Salary</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 0</h4>
                             <p >Monthly Payment</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}> - </h4>
                             <p >Flat Rate</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>35%</h4>
                             <p >DownPayment</p>
                           </div>
                           </div>
                           <div class='col-lg-12' style={{textAlign:'center'}}>
                          <a href="https://www.user.bcf-mortgage.com/" class="main-default-btn2" style={{width:'200px'}} >Apply Now &nbsp; <img src="/assets/images/keyboard-arrow-right.svg" alt="arrow" /></a>
                           <a href="/mortgage" class="main-default-btn2">Calculate Monthly Mortgage &nbsp; </a>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="why-choose-us-area-without-bg">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="why-choose-us-item">
                       <div style={{paddingBottom:'10px',alignItems:'center', borderBottom:'1px solid lightgrey' , display:'flex'}}>
                        <img src="/assets/images/cropped-logo.png" alt="image" style={{height:'70px'}}/>
                        <h3 style={{textAlign:'center',marginTop:'30px', marginLeft:'20px'}}>Amlak Istithmari </h3>
                        </div> 
                          <div class="row" style={{marginTop:'20px' , display:'flex' , textAlign:'center'}}>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 10,000</h4>
                             <p >Minimum Salary</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>AED 0</h4>
                             <p >Monthly Payment</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}> - </h4>
                             <p >Flat Rate</p>
                           </div>
                           <div class="col-lg-3 col-md-4 col-sm-6" >
                           <h4 style={{fontWeight:'bold'}}>35%</h4>
                             <p >DownPayment</p>
                           </div>
                           </div>
                           <div class='col-lg-12' style={{textAlign:'center'}}>
                          <a href="https://www.user.bcf-mortgage.com/" class="main-default-btn2" style={{width:'200px'}} >Apply Now &nbsp; <img src="/assets/images/keyboard-arrow-right.svg" alt="arrow" /></a>
                           <a href="/mortgage" class="main-default-btn2">Calculate Monthly Mortgage &nbsp; </a>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<Footer/>
        </body>
    )
}
export default Compare