import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Button,
  Spinner
} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import {
  Link
} from 'react-router-dom'
import * as yup from 'yup'
import { Formik } from 'formik'
import '../../assets/css/style.css'
import Sidebar from '../Sidebar'
const schema = yup.object().shape({
  code: yup.string().required('Please input verification code'),
});

const Confirmation = ({ 
  code, 
  setCode, 
  onConfirmation, 
  handleResend, 
  loading,
  load
}) => {
  const email = useSelector(state => state.auth.currentUser.email )
  const phone = useSelector(state => state.auth.currentUser.phone )
  return  (
    <body style={{width:'100%'}}>
    <Sidebar/>

        <Formik
          validationSchema={schema}
          onSubmit={e => 
            onConfirmation()
          }
          initialValues={{
            code: code
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <div id="home" class="confidence-home-area" style={{marginTop:'100px' , height:'100%'}}>
            <div class="container-fluid">
                <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12" style={{textAlign: 'center'}}>
                        <div class="confidence-home-content more-neg">
                        <span class="sub-title" style={{fontSize: '25px'}}>Leading mortgage broker</span>
                        <h2 style={{fontSize:'35px' , fontWeight:'bold'}}>Your Personalised Mortgage Broker</h2>
                     </div>
                        </div>
                        <div class="col-lg-6 col-md-12" style={{textAlign: 'center'  , paddingTop:'30px'}}>
                        <div class="confidence-home-content">
                        {/* <span class="sub-title" style={{fontSize: '25px'}}>Reliable Sustainable Provider</span> */}
                        <h2>Verify to Sign in</h2>
                        <p style={{textAlign:'center',margin:'auto'}}>
                        We have sent an verification code to {email ? email : phone}. Check your spam folder and the spelling of your email address.</p>
                        <Form noValidate onSubmit={handleSubmit}>
                        <Form.Label htmlFor='basic-url'>Please Enter Verification Code</Form.Label>
                        <InputGroup className='mb-5'>
             <FormControl type='text' name='code' value={code} onChange={e => {
                  handleChange(e)
                  setCode(e.target.value)
                }} isInvalid={!!touched && !!errors.code} onBlur={handleBlur} />
                <Button type='submit' variant='primary'   style={{backgroundColor:'#00C195' , color:'white' , border:'none'}}>
                  {loading && <Spinner animation='border' />}
                    Verify
                </Button>
                <Form.Control.Feedback type='invalid'>
                  {errors.code}
                </Form.Control.Feedback>

              </InputGroup>
           
              <div className='d-flex justify-content-center align-items-center'>
                <Button variant='primary' onClick={handleResend} class='btn-verify main-default-btn2'  style={{backgroundColor:'#00C195' , border:'none' , padding:'10px'}}>
                  {load && <Spinner animation='border' />}
                    Resend code
                </Button>
              </div>
                        </Form>
                        {/* <a href="#" class="main-default-btn2" >Islamic Finance</a>
                        <a href="#" class="main-default-btn2" >Whole of Market</a>
                        <a href="#" class="main-default-btn2" >Instant Quotation</a> */}
                     
                        </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                        <div class="confidence-home-content more-neg">
                    <p style={{textAlign:'center',margin:'auto'}}>
                    Bandenia will save you a lot of time and stress; We will handle everything from searching for a deal to applying and communicating with the lender on your behalf.
                     Expert in the mortgage market and will be able to recommend deals that suit your situation.</p>
                    
                        </div>
                        <div class="px-4">
                      <h3 class="text-center">
                        Ramadan Offer
                      </h3>
                      <div className="row px-3">
              <div className="col-lg-6 col-md-12 col-sm-12">
              <div class="offer">
              <a href="#" class="main-default-btn2 ml-0" > Processing Fees Waived </a>
             <p>
             Apply for a mortgage loan through us and we will pay your Mortgage Processing Fees (0.5% of loan amount). Avail this offer now as it is valid for a limited period (up to Eid - 22 April 2023)
             </p>

              </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div class="offer">
                <a href="#" class="main-default-btn2" > Valuation Fees Waived </a>
             <p>
             Apply for a mortgage loan through us and we will pay your Mortgage Valuation Fees.
             </p>
             
                </div>
              </div>
            </div>
            <div class="justify">
                      <span className="bold">
                      You can choose any one of the above options
                      </span>
                      </div>
                    </div>
                    </div>
            </div>
            </div>
        </div>
            
          )}
        </Formik>
      
 
  </body>
  )
}

export default Confirmation