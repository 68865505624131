import {
  STATUS 
} from '../constants/actionTypes/status'

const INITIAL_STATE = {
  currentUser: {
    submitted_crm:false,
    submitted_to_bank:false,
    submitted_to_credit:false,
    awaiting_preapproval:false,
    preapproved:false,
    valuation_initiated:false,
    fol_requested:false,
    fol_signed:false,
    disbursed:false,
    assigned_user:null
  }
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STATUS:
      return {
        ...state,
        currentUser:action.payload
      }
    default:
      return state
  }
}

export default reducer