import { useEffect, useState } from 'react'

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,
    
  } from 'react-bootstrap'
  import Footer from './Footer';
  //   import {GiHamburgerMenu } from "react-icons/gi";
  import { Link , useNavigate } from 'react-router-dom'
  import Calculator from './Calculator'
  import Header from './Header'
  import $ from 'jquery'
  import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//   import OwlCarousel from 'react-owl-carousel';  

//   import 'owl.carousel/dist/assets/owl.carousel.css';  
 
//   import 'owl.carousel/dist/assets/owl.theme.default.css'; 
  //import $ from '../assets/js/bootstrap.bundle.min.js'
 
  
  import '../assets/css/responsive.scss'
//   import { Helmet } from 'react-helmet'
  const Findloan = () => {
   
    const[sidebar, setSidebar]=useState(false)
    const navigate=useNavigate();
    const [navClass, setNavClass] = useState('');

    const handleScroll = () => {
      if (window.scrollY > 120) {
        setNavClass('is-sticky');
      } else {
        setNavClass('');
      }
    };
  
   
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
 const responsive = {
  
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1100 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1100, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2
    },
    smallMobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1
      }
  };
 useEffect(() => {
    $('.loader').fadeOut(1500);
    window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };

      localStorage.getItem(sidebar)
      console.log(sidebar)
    
    });
    

   
  

    return (
<div class="find-own-loan-area pt-100 pb-70">
            <div class="container">
                <div class="main-section-title">
                    <span class="sub-title">Categories Of Loan</span>
                    <h2>Find A Own Loan</h2>
                </div>

                <div class="find-own-loan-list-tab">
                    {/* <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="find-own-loan-1-tab" data-bs-toggle="tab" href="#find-own-loan-1" role="tab" aria-controls="find-own-loan-1">Purchase</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" id="find-own-loan-2-tab" data-bs-toggle="tab" href="#find-own-loan-2" role="tab" aria-controls="find-own-loan-2">Homebuyer</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" id="find-own-loan-3-tab" data-bs-toggle="tab" href="#find-own-loan-3" role="tab" aria-controls="find-own-loan-3">Refinance</a>
                        </li>
                    </ul> */}
                  

                <Carousel  responsive={responsive} >
                    <div class="find-own-loan-card" style={{marginRight:'30px'}}>
                                    <div class="loan-image">
                                        <img src="/assets/images/image5.png" alt="image"/>
                                    </div>
                                    <div class="loan-content">
                                        <span class="tag">Home Loan</span>
                                        <h3> Home Loan <br/> Calculator </h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                    <div class="option-item" style={{marginTop:'10px', alignItems:'center', marginLeft:'40px'}}>
                                    <a href="/homeloan" class="calculate">Calculate and apply now </a>
                                    </div>
                                    </div>
                                    <div class="find-own-loan-card"  style={{marginRight:'30px'}}>
                                    <div class="loan-image">
                                        <img src="/assets/images/image6.png" alt="image"/>
                                    </div>
                                    <div class="loan-content">
                                    <span class="tag">Investment Loan</span>
                                        <h3>Investment Loan Calculator</h3>
                                        <p>Fyigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                    <div class="option-item" style={{marginTop:'10px', alignItems:'center', marginLeft:'40px'}}>
                                    <a href="/mortgage" class="calculate">Calculate and apply now </a>
                                    </div>
                                    </div>
                                    <div class="find-own-loan-card" style={{marginRight:'30px'}}>
                                    <div class="loan-image">
                                        <img src="/assets/images/image7.png" alt="image"/>
                                    </div>
                                    <div class="loan-content">
                                    <span class="tag">SME Loans</span>
                                        <h3>SME Loan <br/> Calculator</h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                    <div class="option-item" style={{marginTop:'10px', alignItems:'center', marginLeft:'40px'}}>
                                    <a href="/sme" class="calculate">Calculate and apply now </a>
                                    </div>
                                    </div>
                                    <div class="find-own-loan-card" style={{marginRight:'30px'}}>
                                    <div class="loan-image">
                                        <img src="/assets/images/image8.png" alt="image"/>
                                    </div>
                                    <div class="loan-content">
                                    <span class="tag">Personal Loans</span>
                                        <h3> Personal Loan Calculator</h3>
                                        <p>Wigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                    <div class="option-item" style={{marginTop:'10px', alignItems:'center', marginLeft:'40px'}}>
                                    <a href="/mortgage" class="calculate">Calculate and apply now </a>
                                    </div>
                                    </div>
                                    <div class="find-own-loan-card" style={{marginRight:'30px'}}>
                                    <div class="loan-image">
                                        <img src="/assets/images/image5.png" alt="image"/>
                                    </div>
                                    <div class="loan-content">
                                        <span class="tag">Home Loan</span>
                                        <h3>Home Loan <br/> Calculator</h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                    <div class="option-item" style={{marginTop:'10px', alignItems:'center', marginLeft:'40px'}}>
                                    <a href="/homeloan" class="calculate">Calculate and apply now </a>
                                    </div>
                                    </div>
                                    <div class="find-own-loan-card"  style={{marginRight:'30px'}}>
                                    <div class="loan-image">
                                        <img src="/assets/images/image6.png" alt="image"/>
                                    </div>
                                    <div class="loan-content">
                                    <span class="tag">Investment Loan</span>
                                        <h3>Investment Loan Calculator</h3>
                                        <p>Fyigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                    <div class="option-item" style={{marginTop:'10px', alignItems:'center', marginLeft:'40px'}}>
                                    <a href="/mortgage" class="calculate">Calculate and apply now </a>
                                    </div>
                                    </div>
                                    <div class="find-own-loan-card" style={{marginRight:'30px'}}>
                                    <div class="loan-image">
                                        <img src="/assets/images/image7.png" alt="image"/>
                                    </div>
                                    <div class="loan-content">
                                    <span class="tag">SME Loans</span>
                                        <h3>SME Loan <br/> Calculator</h3>
                                        <p>Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                    <div class="option-item" style={{marginTop:'10px', alignItems:'center', marginLeft:'40px'}}>
                                    <a href="/sme" class="calculate">Calculate and apply now </a>
                                    </div>
                                    </div>
                                    <div class="find-own-loan-card" style={{marginRight:'30px'}}>
                                    <div class="loan-image">
                                        <img src="/assets/images/image8.png" alt="image"/>
                                    </div>
                                    <div class="loan-content">
                                    <span class="tag">Personal Loans</span>
                                        <h3>Personal Loan Calculator</h3>
                                        <p>Wigration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                    <div class="option-item" style={{marginTop:'10px', alignItems:'center', marginLeft:'40px'}}>
                                    <a href="/sme" class="calculate">Calculate and apply now </a>
                                    </div>
                                    </div>
                                </Carousel>
                </div>
            </div>
        </div>
        
    )
}
    export default Findloan