import { useState,useRef , useEffect } from 'react'
import { sidebar_check } from '../actions/sidebar'
import { useSelector, useDispatch } from 'react-redux';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,
    Carousel
  } from 'react-bootstrap'
  import { Auth } from 'aws-amplify'
  import { Link , useNavigate, useLocation  } from 'react-router-dom'
  import fetch from '../helpers/methods';

import { useToasts } from 'react-toast-notifications';
  
  const Header = () => {
    const { addToast } = useToasts()
    const [currentEmail, setCurrentEmail] = useState('')
    const [firstCharacter, setFirstCharacter] = useState('')
    const logout = async() => {
      try {
        await Auth.signOut();
        addToast('You have logged out successfully', {
          appearance: 'success',
          autoDismiss: true
        })
      } catch (error) {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true
        })
      }
      navigate('/')
    }
    useEffect(() => {
      Auth.currentAuthenticatedUser()
      .then(data => {
        setCurrentEmail(data?.attributes?.email)
        console.log(currentEmail)
      })
      .catch(err => console.log(err))
     
        fetch ()
        .then (data => {
          data=data.data.data
          setCurrentEmail(data.email)
          
          console.log('fetchData',data)
          if (data.name === 'Invalid Session ID') {
            navigate('/login')
            return
          }
          const name = data?.first_name;
          const job = data?.job_title;
          const mortgage_type = data?.mortgage_type;
          const salary_slip_path = data?.salary1_path;
          const eid_path = data?.EID1_path;
          const pass_path = data?.passport1_path;
          const visa_path = data?.visa1_path;
          const firstCharacters = data?.email.charAt(0);
          setFirstCharacter(firstCharacters)
    
          console.log("printing job");
          console.log(job);
          if (name!=null && name!=="") {        
            dispatch(sidebar_check('Profile'))
          }
          if (job!=null && job!=="") {
            dispatch(sidebar_check('Income'))
          }
          if (mortgage_type!=null && mortgage_type!=="") {
            dispatch(sidebar_check('Property'))
           // dispatch(sidebar_check('Calculator'))        
          }
         
          if (eid_path || salary_slip_path || pass_path || visa_path) {
            dispatch(sidebar_check('Documents'))
          }
    
        })
        }, [])
    const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const sidebar = useRef()
    const[sidebars, setSidebar]=useState(false)
    const sideData = useSelector((state) => state.sidebar)
    const router = (idx, check = 2, url = '') => {
        // if(check) {
          navigate(`/${url}`)
        // }
      }
    const showSidebar=()=>{
       
           setSidebar(true)
    }
    const hideSidebar=()=>{
       
        setSidebar(false)
 }

    return (
      
    <div>
     <div ref={sidebar} className='sidebar-container'>
        <div className='sidebar-wrapper'>
       
        {/* <div style={{margin:'2 px', justifyContent:'center' , }} className={`item home-link ${location.pathname === '/' ? 'white-bg' : ''}`} >
              <img src='/assets/images/left.png'  style={{ width: '22px'}}/>
              <div>
             Timeline
            </div>
          </div> */}

          {/* <div style={{margin:'2 px',justifyContent:'center' , }} className={`item home-link ${location.pathname === '/overview' ? 'white-bg' : ''}`} onClick={()=>{router(1, true, 'overview')}}>
            
              <img src='/assets/images/home.svg'  style={{ width: '22px'}}/>
              <div>
              Home
            </div>
          </div>
          */}

          {
            sideData.map((item, idx) => {
              return (
                <div key={idx} style={{margin:'2 px', justifyContent:'center'}} className={`item ${`/${item.name}` === location.pathname ? 'white-bg' : ''}`} onClick={()=> {router(idx+2, item.check, item.name)}}>
                  <span class="bottombar-icons">
                  <img
                    src={item.imgurl}
                    //className={`${item.check ? 'imgOp' : ''}`}
                    style={{ width: '15px'}}/>
                    </span>
                  
                    {item.name}
                  <div style={{flexWrap:'wrap', justifyContent:'flex-end',display:'flex', width:'40px'}}>
                  {item.check ? 
                    <img
                      className='check-img'
                      src='/assets/images/check-circle-1.2f883aa2.svg'
                    /> 
                    : <></>
                  }
                  </div>
                </div>
              )
            })
          }  
           <div style={{margin:'2px' ,justifyContent:'center' , }} className={`item home-link ${location.pathname === '/' ? 'white-bg' : ''}`} onClick={()=>logout()} >
         
        
           <img src="/assets/images/log-out.svg"  style={{ width: '22px'}}/>
           <div>
           Logout 
         </div>
       </div>
          
           {/* <div style={{padding:'18px',alignItems:'center', fontSize:'11px' ,  display:'flex' ,textAlign:'center', justifyContent:'center', flexDirection:'column' }} onClick={()=>signout()}>
            <div>
            <img src="/assets/images/log-out.svg" alt="" style={{width:"22px"}} />
            <br/>
            <p>
           SignOut
           </p>
           </div>
          </div> */}
        {/* <div>
          <img className='sidebar-close' style={{
            width: '100%',
            borderRadius: '50%',
            }} src='/assets/images/back.png'
            onClick={handleClose}
          />
          Close
        </div> */}
        {/* <div className='sub-text'>
          <img src='/assets/images/persent.svg'/>
          <div className='text1'>{_progressValue}%</div>
          <div>closer to your home financing</div>
        </div> */}
       </div>
      </div>
    <div  className={`side-menu ${sidebars ? 'show-menu' : 'display'}`} id="sidebar-menu">
    <div ref={sidebar}>
    <img src="/assets/images/cropped-logo.png" alt="" style={{height:'80px'}}/> 
           <br />
    <ul>
    {/* <li>
                    <a class="no-line" href="/home">
                    	<span class="material-icons"><img src="/assets/images/dashboard.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Home
					</a>
                </li> */}
                <li>
                    <a class="no-line" href="/Overview">
                    	<span class="material-icons"><img src="/assets/images/dashboard.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Overview
					</a>
                </li>
                  {
            sideData.map((item, idx) => {
              return (
                <li class='item' style={{ cursor:'pointer',lineHeight:'40px',color:'black' , padding:'6px 0', fontSize:'14px', display:'flex'}}>
               <div style={{display:'flex'}} key={idx} className={`item ${`/${item.name}` === location.pathname ? 'white-bg' : ''}`} onClick={()=> {router(idx+2, item.check, item.name)}}>
                 <span class="material-icons">
                  <img
                    src={item.imgurl}
                    //className={`${item.check ? 'imgOp' : ''}`}
                    style={{ width: '15px'}}/>
                    </span>
                  
                    {item.name}
                  <div style={{marginTop:'20px' ,flexWrap:'wrap', justifyContent:'flex-end',display:'flex', width:'40px'}}>
                  {item.check ? 
                    <img
                      className='check-img'
                      src='/assets/images/check-circle-1.2f883aa2.svg'
                    /> 
                    
                    : <></>
                  }
                  </div>
                 
                </div>
                </li>
              )
            })
          }
              
    </ul>
    <div class="close-btn" onClick={hideSidebar}><span class="material-icons"><img src="/assets/images/close.svg" alt="" style={{height:'24px' , width:'24px'}} /></span></div>
    <div onClick={()=>logout()} class="sidebar-logout no-line main-default-btn2" style={{height:'60px' , cursor:'pointer'}}><span><img src="/assets/images/logout.svg" alt="" style={{width:'24px'}}/></span>&nbsp;&nbsp;Logout</div>
           
            </div>
</div>
    <div class="main-navbar" style={{backgroundColor:'white' , color:'black' , }} >

            <nav class="navbar navbar-style-two navbar-expand-lg navbar-light"  style={{backgroundColor:'white'}}>
                <div class="container-fluid">
               
                    <div class="logo">
                       
                        {/* <a href="#" class="link icon-only" onClick={showSidebar}>
                <i class="custom-hamburger">
                <GiHamburgerMenu/>
                    <span><b></b></span>
                </i>
            </a> */}
             <button onClick={showSidebar} class="navbar-toggler2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
            <a href="/Overview"> 
                            <img src="/assets/images/cropped-logo.png" className="white-logo" alt="Logo" style={{height:'70px'}}/>
                        </a>
                    </div>
                  
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav">
                          
                        </ul>
    
                        <div class="others-option">
                            <div class="d-flex align-items-center">
                                <div class="option-item">
                                    <a href="tel:+971 42014000" class="call-btn" style={{color:'black'}}>Call Us: +971 42014000 </a>
                                </div>
                                {currentEmail ? 
                                
                        //      <div class="option-item">
                        //      <a class="sign-up">{firstCharacter}</a>
                        //  </div>
                        <Dropdown  class="option-item">
                        <Dropdown.Toggle variant="success" class="sign-up" id="dropdown-basic">
                        {firstCharacter}
                        </Dropdown.Toggle>
                  
                        <Dropdown.Menu>
                          <Dropdown.Item href="/Profile">Profile</Dropdown.Item>
                          <Dropdown.Item href="/Status">Status</Dropdown.Item>
                          <Dropdown.Item  onClick={()=>logout()}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                                :
                                <div class="option-item">
                        <a href="/" class="sign-up">Log in</a>
                    </div>
  }
                              
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
        </div>
 
      //  <div  ref={sidebar}>
      //      <img src="assets/images/cropped-logo.png" alt="" style={{height:'80px'}}/> 
      //      <br />
      //        <ul>
      //           <Link to='/home' class='no-line'>
      //           <li class='item' style={{ cursor:'pointer',lineHeight:'40px',color:'black' , padding:'6px 0', fontSize:'14px', display:'flex'}}>
      //          <div style={{display:'flex'}} >
      //            <span class='icon'>
      //             <img
      //               src='/assets/images/dashboard.svg'
      //               //className={`${item.check ? 'imgOp' : ''}`}
      //               style={{ width: '15px'}}/>
      //               </span>
                  
      //               Home
      //             <div style={{marginTop:'20px' ,flexWrap:'wrap', justifyContent:'flex-end',display:'flex', width:'40px'}}>
                  
      //             </div>
                 
      //           </div>
      //           </li>
      //           </Link>
      //            {
      //       sideData.map((item, idx) => {
      //         return (
      //           <li class='item' style={{ cursor:'pointer',lineHeight:'40px',color:'black' , padding:'6px 0', fontSize:'14px', display:'flex'}}>
      //          <div style={{display:'flex'}} key={idx} className={`item ${`/${item.name}` === location.pathname ? 'white-bg' : ''}`} onClick={()=> {router(idx+2, item.check, item.name)}}>
      //            <span class='icon'>
      //             <img
      //               src={item.imgurl}
      //               //className={`${item.check ? 'imgOp' : ''}`}
      //               style={{ width: '15px'}}/>
      //               </span>
                  
      //               {item.name}
      //             <div style={{marginTop:'20px' ,flexWrap:'wrap', justifyContent:'flex-end',display:'flex', width:'40px'}}>
      //             {item.check ? 
      //               <img
      //                 className='check-img'
      //                 src='/assets/images/check-circle-1.2f883aa2.svg'
      //               /> 
                    
      //               : <></>
      //             }
      //             </div>
                 
      //           </div>
      //           </li>
      //         )
      //       })
      //     }
                
      //       </ul>
           
      //       <a class="sidebar-logout no-line"  style={{marginRight:'50px'}} href="/"><span><img src="assets/images/logout.svg" alt="" style={{height:'24px' , width:'24px'}}/></span>&nbsp; &nbsp;Logout</a>
            
      //       </div>
    )
  }
  
  export default Header