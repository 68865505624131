import React ,{ useEffect,useCallback , useState } from 'react';
import {
	Container,
	Card,
	Button,
	Form,
	Badge,
	Col,
	Modal,
	Grid,
	Row,
	Dropdown,
    Carousel

} from 'react-bootstrap'
import { Auth } from 'aws-amplify'
import { sidebar_check } from '../actions/sidebar'
import { progress_value } from '../actions/progress'
import fetch from '../helpers/methods'
import { statusAction } from '../actions/status'
import { profile } from '../actions/profile'
import { useDispatch, useSelector } from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import Header from './Header'
const Status = ({
	
}) => {
	const dispatch = useDispatch()
	const[sidebar, setSidebar]=useState(false)
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
	const updateState = useCallback(async () => {
		fetch()
		.then(data => {
		  data=data.data.data;		  
		  const payload = {
			submitted_crm:data.submitted_crm,
			submitted_to_bank:data.submitted_to_bank,
			submitted_to_credit:data.submitted_to_credit,
			awaiting_preapproval:data.awaiting_preapproval,
			preapproved:data.preapproved,
			valuation_initiated:data.valuation_initiated,
			fol_requested:data.fol_requested,
			fol_signed:data.fol_signed,
			disbursed:data.disbursed,
			assigned_user:data.assigned_user,
		  }     
		  dispatch(statusAction(payload));	
		  dispatch(profile(payload));	  
		}
		)	
	  }, []);
	
	useEffect(() => {		
		setInterval(updateState, 3000);
		
		}, [updateState]) 
	const reduxData = useSelector(state => state.status)
	const reduxData2 = useSelector(state => state.profile)
	console.log(reduxData2);
	const bcf_submitted_crm = reduxData.currentUser.submitted_crm;
	const bcf_submitted_to_bank = reduxData.currentUser.submitted_to_bank;
	const bcf_submitted_to_credit = reduxData.currentUser.submitted_to_credit;
	const bcf_awaiting_preapproval = reduxData.currentUser.awaiting_preapproval;
	const bcf_preapproved = reduxData.currentUser.preapproved;
	const bcf_valuation_initiated = reduxData.currentUser.valuation_initiated;
	const bcf_fol_requested = reduxData.currentUser.fol_requested;
	const bcf_fol_signed = reduxData.currentUser.fol_signed;
	const bcf_disbursed = reduxData.currentUser.disbursed;
	const bcf_first_name = reduxData2.currentUser.firstName;
	const bcf_last_name = reduxData2.currentUser.lastName;
	// const bcf_email = reduxData2.currentUser.phoneNumber;
	const bcf_dob = reduxData2.currentUser.birthDate;
	const bcf_phone = reduxData2.currentUser.phoneNumber;
	const bcf_nationality = reduxData2.currentUser.nationality;
	const assignedUser = reduxData.currentUser.assigned_user;
	
	return (
		<body class={`${sidebar ? 'overlay-body' : ''}`} style={{width:'100%'}}>
        <Header/>

       <div class="home" >

    <Container fluid className='app-container'>
			<div style={{paddingTop:'120px'}}>
				<h1 
					style={{
					fontFamily: 'AGaramond-regular',
					fontSize: '64px',
					letterSpacing: '-5px',
					}}
				>
					Status
				</h1>
				<Row lg={12}>
					
					<Col lg={5} style={{backgroundColor:'#fafafa' , paddingLeft:'20px' , paddingTop:'10px' , marginBottom:'10px' }}>
				<div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
				<div style={{display:'flex', flexDirection:'column'}}>
				
                  <Form.Check
                    type='checkbox'
                    name='submitted_to_crm'
                    id='income'
					checked={bcf_submitted_crm}
					disabled
                    label='Submitted To Crm'                    
                  />
				   <Form.Check
                    type='checkbox'
                    name='submitted_to_bank'
                    id='income'
					checked={bcf_submitted_to_bank}
					disabled
                    label='Submitted To Bank'                    
                  />
				   <Form.Check
                    type='checkbox'
                    name='submitted_to_credit'
                    id='income'
					checked={bcf_submitted_to_credit}
					disabled
                    label='Submitted To Credit'                    
                  />
				   <Form.Check
                    type='checkbox'
                    name='bcf_awaiting_preapproval'
                    id='income'
					checked={bcf_awaiting_preapproval}
					disabled
                    label='Awaiting Preapproval'
                  />				   
				   <Form.Check
                    type='checkbox'
                    name='bcf_preapproved'
                    id='income'
					checked={bcf_preapproved}
					disabled
                    label='Preapproved'
                  />
				   <Form.Check
                    type='checkbox'
                    name='bcf_valuation_initiated'
                    id='income'
					checked={bcf_valuation_initiated}
					disabled
                    label='Valuation Initiated'
                  />
				  <Form.Check
                    type='checkbox'
                    name='bcf_fol_requested'
                    id='income'
					checked={bcf_fol_requested}
					disabled
                    label='Fol Requested'
                  />
				  <Form.Check
                    type='checkbox'
                    name='bcf_fol_signed'
                    id='income'
					checked={bcf_fol_signed}
					disabled
                    label='Fol Signed'
                  />
				  <Form.Check
                    type='checkbox'
                    name='bcf_disbursed'
                    id='income'
					checked={bcf_disbursed}
					disabled
                    label='Fol Disbursed'
                  />
				</div>
				</div>
				</Col>
				<Col lg={2}>
				</Col>
				<Col lg={4} style={{backgroundColor:'#fafafa' , paddingLeft:'20px' , paddingTop:'10px' , height:'150px'}}>
				
				<Row>
					<Col lg={12}>
					<p style={{fontSize:'15px'}}> Your Bandenia Contact</p>
					</Col>
				</Row>
				<Row>
					<Col xs={3} sm={3} lg={3} md={3}>
						<img src="/assets/images/logo_bandenia.jpg" alt="image"/>
					</Col>
					{
					assignedUser?<Col xs={6} sm={8} lg={6} md={6}>
					<p> 
                              <span style={{fontWeight:'bold'}}>
                            &nbsp;&nbsp; {assignedUser['name']['value']}
                            </span>
                            <br/>
                            <span style={{fontSize:'12px'}}>
                            &nbsp;&nbsp; Mortgage Specialist
                            </span>
                            <br/>
                            <span style={{fontSize:'12px' , color:'blue'}}>
                            &nbsp;&nbsp; {assignedUser['email1']['value']}
                            </span>
                            </p>
					</Col>:
					<Col xs={6} sm={8} lg={6} md={6}>
					<p> 
                              <span style={{fontWeight:'bold'}}>
                            &nbsp;&nbsp; Shahid Khan
                            </span>
                            <br/>
                            <span style={{fontSize:'12px'}}>
                            &nbsp;&nbsp; Mortgage Specialist
                            </span>
                            <br/>
                            <span style={{fontSize:'12px' , color:'blue'}}>
                            &nbsp;&nbsp; shahid@bcf.ae
                            </span>
                            </p>
					</Col>
					}
				</Row>
				</Col>
				</Row>
			</div>
		</Container>
		</div>
		</body>
)
}

export default Status