import {
  PROPERTY 
} from '../constants/actionTypes/property'

const INITIAL_STATE = {
  currentUser: {
    property_price:'',
    property_type:'',
    mortgage_type:'',
    lending_type:'',
    mind_property:'',
    fee_financing:''
  }
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROPERTY:
      return {
        ...state,
        currentUser:action.payload
      }
    default:
      return state
  }
}

export default reducer