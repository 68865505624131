import React, { useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import IncomeComponent from '../components/Income'
import { sidebar_check } from '../actions/sidebar'
import fetch from '../helpers/methods'
import { Auth } from 'aws-amplify'
import { income } from '../actions/income'

const Income = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [job, setJob] = useState('')
  const [joinDate, setJoinDate] = useState(new Date())
  const [establishDate, setEstablishDate] = useState(new Date())
  const [companyName, setCompanyName] = useState('')
  const [industry, setIndustry] = useState('Finance')
  const [employmentStatus, setEmploymentStatus] = useState('Employee at a company')
  const [duration, setDuration] = useState('0 to 5 years')
  const [share, setShare] = useState('')
  const [salary, setSalary] = useState('');
  const [expenditure, setExpenditure] = useState('');
  const [addIncomeType, setAddIncomeType] = useState('Bonus')
  const [addIncomeMonthly, setAddIncomeMonthly] = useState('')
  const [bank, setBank] = useState('United Arab Bank')
  const [mortgageMonthlyAmount, setMortgageMonthlyAmount] = useState('')
  const [outStandingAmount, setOutStandingAmount] = useState('')
  const [description, setDescription] = useState('')
  const [cardLimit, setCardLimit] = useState('')
  const [loanType, setLoanType] = useState('')
  const [mortgageBank, setMortgageBank] = useState('')
  const [income_list, set_income_list] = useState([{id:0}])
  const [credit_list, set_credit_list] = useState([{id:0}])
  const [loan_list, set_loan_list] = useState([{id:0}])
  const [add_Income, setAddIncome] = useState(false)
  const [add_Credit, setAddCredit] = useState(false)
  const [add_loan, setAddLoan] = useState(false)
  const [check, setCheck] = useState(false)
  
 const handleCheck =(e)=>{
  if (e.target.checked) {
    setCheck(true)
    console.log(check)
  }
 }
  const incomeData={
            "employment_status":employmentStatus,
            "job_title":job,
            "date_of_joining":  joinDate,
            "date_of_establishment":  establishDate,
            "industry":industry,
            "date_of_establishing_company":establishDate,
            "years_working":duration,
            "company_share":share,
            "fixed_monthly_salary":salary,
            "expenditure":expenditure,
            "additional_income":addIncomeMonthly,
            "credit_card_limits":cardLimit,
            "requesting_loan_amount":mortgageMonthlyAmount,
            "description":description,
            "bank":bank,
            "loan_type":loanType,
            "mortgage_bank":mortgageBank,
            "outStanding_amount":outStandingAmount,
            "add_income":add_Income,
            "add_credit":add_Credit,
            "credit_list":credit_list,
            "loan_list":loan_list,
            "checkbox":check,
            "company_name":companyName,
          }


  const handleData = async () => {
    let session = await Auth.currentSession(); 
    console.log(credit_list) 
    if(check=== true)  {
    try {    
      dispatch(income(incomeData));      
      const response = axios({
        method: 'post',
        headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
        url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
        data: incomeData,
        crossDomain: true
      })
      dispatch(sidebar_check('Income'));
     
    }
    catch (errors) {
      console.log('errors', errors)
    }
  }
  else {
    addToast('Please Select Checkbox', {
      appearance:'error',
      autoDismiss:true
    })
  }
  }
  const handleAlert = () => {
    addToast('An error happened. Please try again.', {
      appearance:'error',
      autoDismiss:true
    })
  }

  const addIncome = () => {
    set_income_list([...income_list, {id:income_list.length}])
  }

  const removeIncome = (idx) => {
    set_income_list(income_list.filter((item) => item.id !== idx))
  }

  const addCredit = () => [
    set_credit_list([...credit_list, {id: credit_list.length}])
  ]

  const removeCredit = (idx) => {
    set_credit_list(credit_list.filter((item) => item.id !== idx))
  }

  const addLoan = () => {
    set_loan_list([...loan_list, {id: loan_list.length}])
  }

  const removeLoan = (idx) => {
    set_loan_list(loan_list.filter((item) => item.id !== idx))
  }

  return (
    <IncomeComponent 
      job={job}
      setJob={setJob}
      joinDate={joinDate}
      setJoinDate={setJoinDate}
      companyName={companyName}
      setCompanyName={setCompanyName}
      industry={industry}
      setIndustry={setIndustry}
      employmentStatus={employmentStatus}
      setEmploymentStatus={setEmploymentStatus}
      duration={duration}
      setDuration={setDuration}
      share={share}
      setShare={setShare}
      salary={salary}
      setSalary={setSalary}
      expenditure={expenditure}
      setExpenditure={setExpenditure}
      addIncomeType={addIncomeType}
      setAddIncomeType={setAddIncomeType}
      addIncomeMonthly={addIncomeMonthly}
      setAddIncomeMonthly={setAddIncomeMonthly}
      bank={bank}
      setBank={setBank}
      mortgageMonthlyAmount={mortgageMonthlyAmount}
      setMortgageMonthlyAmount={setMortgageMonthlyAmount}
      outstandingAmount={outStandingAmount}
      setOutStandingAmount={setOutStandingAmount}
      description={description}
      setDescription={setDescription}
      cardLimit={cardLimit}
      setCardLimit={setCardLimit}
      handleData={handleData}
      loanType={loanType}
      setLoanType={setLoanType}
      setMortgageBank={setMortgageBank}
      mortgageBank={mortgageBank}
      establishDate={establishDate}
      setEstablishDate={setEstablishDate}
      handleAlert={handleAlert}
      income_list={income_list}
      set_income_list={set_income_list}
      addIncome={addIncome}
      removeIncome={removeIncome}
      credit_list={credit_list}
      set_credit_list={set_credit_list}
      addCredit={addCredit}
      removeCredit={removeCredit}
      add_Income={add_Income}
      setAddIncome={setAddIncome}
      add_Credit={add_Credit}
      setAddCredit={setAddCredit}
      add_loan={add_loan}
      setAddLoan={setAddLoan}
      addLoan={addLoan}
      loan_list={loan_list}
      removeLoan={removeLoan}
      set_loan_list={set_loan_list}
      handleCheck={handleCheck}
      check={check}
      setCheck={setCheck}
    />
  )
}

export default Income