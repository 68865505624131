import {
  DOCS 
} from '../constants/actionTypes/docs'

const INITIAL_STATE = {
  currentUser: {
    passportPath:'',
    visaPath:'',
    EIDPath:'',
    salaryPath:'',
    passport1Path:'',
    visa1Path:'',
    EID1Path:'',
    salary1Path:'',
    passport2Path:'',
    visa2Path:'',
    EID2Path:'',
    salary2Path:'',
    passport3Path:'',
    visa3Path:'',
    EID3Path:'',
    salary3Path:'',
    submittedCrm:false
  }
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DOCS:
      return {
        ...state,
        currentUser:action.payload
      }
    default:
      return state
  }
}

export default reducer