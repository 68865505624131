import React from 'react';

import FirstViewCompontent from '../components/firstview'

const FirstView = () => {

  return (
    <div>
      <FirstViewCompontent />
    </div>
  )
}

export default FirstView;