

import DashboardComponent from '../components/Dashboard'


const Dashboard = () => {

  return <DashboardComponent />
}

export default Dashboard