import React, { useState, useEffect, Fragment }  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Card, 
   Dropdown,
    Carousel
} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import CountrySelect from 'react-bootstrap-country-select'
import PhoneInput from 'react-phone-number-input'
import DatePicker from "react-datepicker"
import { Formik } from 'formik'
import * as yup from 'yup'
import { Auth } from 'aws-amplify'
import 'react-phone-number-input/style.css'
import "react-datepicker/dist/react-datepicker.css"
import './style/profile.css';
import { sidebar_check } from '../actions/sidebar'
import { progress_value } from '../actions/progress'
import fetch from '../helpers/methods'
import { profile } from '../actions/profile'
import Header from './Header'
const schema = yup.object().shape({
  firstName: yup.string().required('Please input firstName'),
  lastName: yup.string().required('Please input lastName'),
  email: yup.string().email('Invalid email format').required('Please input your email'),
  tel: yup.string().required('Please input your telephone number'),
  country: yup.string().required('Please select your country'),
  residency: yup.string().required('Please select your residency')
});

const Profile = ({
  firstName, 
  setFirstName, 
  lastName, 
  setLastName, 
  email, 
  setEmail, 
  tel, 
  setTel, 
  birthDate,
  setBirthDate,
  country, 
  setCountry, 
  residency, 
  setResidency,
  martial,
  setMartial,
  lookingFor,
  setLookingFor,
  handleData,
  currentEmail,
  setCurrentEmail,
  phoneNumber,
  setPhoneNumber,
  handleAlert
}) => {
  const [loading, setLoading]=useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [seed, setSeed] = useState(1);
   const[sidebar, setSidebar]=useState(false)
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
  const reset = () => {
      setSeed(Math.random());
  }
  useEffect(() => {
    setLoading(true)
    Auth.currentAuthenticatedUser()
    .then(data => {
      setCurrentEmail(data?.attributes?.email)
    })
    .catch(err => console.log(err))
    fetch()
    .then(data => {
      console.log('data',data)
      data=data.data.data;
      if (data.name === 'Invalid Session ID') {
        Auth.signOut();
        navigate('/login')
      }
      const first_name_c = data.first_name;
      const last_name_c = data.last_name;
      const phone_number_c = data.phone_number==="na"?null:data.phone_number;
      const data_of_birth_c = data.birth_date;
      const nationality_c = data.nationality;
      const resident_type = data.residency_status;
      const marital_status_c = data.marital_status;
      const looking_for = data.looking_for;
      const payload = {
        firstName:first_name_c,
        lastName:last_name_c,
        phoneNumber:phone_number_c,
        birthDate:data_of_birth_c,
        nationality:nationality_c,
        residency:resident_type,
        martial:marital_status_c,
        lookingFor:looking_for,
      }      
      dispatch(profile(payload));
      setFirstName(first_name_c);
      setLastName(last_name_c);
      setCountry(nationality_c);
      setResidency(resident_type);
      setMartial(marital_status_c);
      setPhoneNumber(phone_number_c);
      setLookingFor(looking_for);
      setBirthDate(data_of_birth_c);
    }
    )
    setLoading(false)
    }, [])  
  let reduxData = useSelector(state => state.profile)  
  const bcf_first_name = reduxData.currentUser.firstName;
  const bcf_last_name = reduxData.currentUser.lastName;
  const bcf_phone_number = reduxData.currentUser.phoneNumber;
  const bcf_birth = reduxData.currentUser.birthDate;
  const bcf_nationality = reduxData.currentUser.nationality;
  const bcf_resident = reduxData.currentUser.residency;
  const bcf_martial = reduxData.currentUser.martial;
  const bcf_looking_for = reduxData.currentUser.lookingFor;

  return (
    <>
    {
      loading ?
      <div id="preloader"> </div> :
      <div id="preloader" style={{display: 'none'}}></div>
    }
     <body class={`${sidebar ? 'overlay-body' : ''}`} style={{width:'100%'}}>
     <Header/>
       <div class="home" style={{backgroundColor:'#f4f4f6' , paddingTop:'120px'}}>

    <Container fluid className='app-container'>
      
      <div className='pb-5' style={{paddingTop:'20px' , marginBottom:'50px'}}>
        <h1 style={{
          fontFamily: 'AGaramond-regular',
          // fontSize: '64px',
          //wordSpacing: '1px',
          letterSpacing: '-2px',
          paddingTop:'5px'
        }}
        >Start your Journey with Bandenia (800-BANDENIA)</h1>
        <p>
        In order for our Relationship Manager to contact you.
        </p>
      <Card className='striped-tabled-with-hover mt-5 py-5 px-3' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px'}}>
        <h4 style={{
          fontFamily: 'AGaramond-regular',
          fontSize: '32px',
          letterSpacing: '-2px',
        }}
        >Your Details</h4>
        <p>Please fill in all the fields in order to ensure a complete application.</p>
        <Formik
          validationSchema={schema}
          // onSubmit={console.log('')}
          initialValues={{
            firstName: firstName,
            lastName: lastName,
            email: currentEmail ? currentEmail : email,            
            residency:residency,
            martial:martial,
           

          }}
        >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <div style={{width:'100%'}}>
            <Form.Group className="d-flex justify-content-between mb-3 gap-2" controlId="formBasicEmail">
              <div style={{width:'100%'}}>
                <Form.Label>First Name</Form.Label>
                <Form.Control 
                  type="text" 
                  name='firstName' 
                  defaultValue={bcf_first_name ? bcf_first_name : firstName}
                  //disabled={bcf_first_name}
                  onChange={
                    e => {handleChange(e)
                    setFirstName(e.target.value)
                    }}
                  isInvalid={!!touched && !!errors.firstName}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.firstName}
                </Form.Control.Feedback>
              </div>
              <div style={{width:'100%'}}> 
                <Form.Label>Last Name</Form.Label>
                <Form.Control 
                  type="text" 
                  name='lastName'  
                  defaultValue={bcf_last_name ? bcf_last_name : lastName}
                  //disabled={bcf_last_name}
                  onChange={e => {handleChange(e);
                  setLastName(e.target.value)
                  }}
                  isInvalid={!!touched && !!errors.lastName}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.lastName}
                </Form.Control.Feedback>
              </div>
            </Form.Group>

            <Form.Group className="d-flex justify-content-between mb-3 gap-2 mobile-class" controlId="formBasicEmail">
              <div style={{width:'100%'}}>
                <Form.Label>Email</Form.Label>
                  <Form.Control 
                    //disabled={currentEmail ? true : false}
                    type="email" 
                    name='email' 
                    defaultValue={currentEmail ? currentEmail : email}
                    onChange={e => {
                      handleChange(e);
                      setEmail(e.target.value)
                    }}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.email}
                  </Form.Control.Feedback>
              </div>
              <div style={{width:'100%'}}> 
                <Form.Label>Phone Number</Form.Label>
                <PhoneInput
                  className='phone-number-input'
                  //disabled={bcf_phone_number}
                  type='tel'
                  placeholder="Enter phone number"
                  international
                  defaultCountry="AE"
                  value={phoneNumber ? phoneNumber : bcf_phone_number }
                  onChange={setPhoneNumber}
                />
              </div>
            </Form.Group>

            <Form.Group className="d-flex justify-content-between mb-3 gap-2 mobile-class" controlId="formBasicEmail">
              <div style={{width:'100%'}}>
                <Form.Label>Date of birth</Form.Label>
                
                <DatePicker 
                  defaultValue={bcf_birth ? bcf_birth : new Date()}
                  //disabled={bcf_birth}                  
                  selected={birthDate?new Date(birthDate):new Date()}
                  onChange={(date) => setBirthDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat={"dd-MM-yyyy"}
                />
                
              </div>
              <div style={{width:'100%'}}> 
                <Form.Label>Nationality</Form.Label>
                  {
                  bcf_nationality ? 
                  <Form.Control 
                  //disabled={bcf_nationality}
                  value={bcf_nationality}
                  flags='flase'
                />
                  :
                  <CountrySelect
                    name='country'
                    //disabled={bcf_nationality}
                    value={bcf_nationality ? bcf_nationality : country}
                    onChange={setCountry}
                    flags='flase'
                    throwInvalidValueError={false}
                  />
                }

                <Form.Control.Feedback type='invalid'>
                  {errors.country}
                </Form.Control.Feedback>
              </div>
            </Form.Group>

            <Form.Group className="d-flex justify-content-between mb-3 gap-2 mobile-class" controlId="formBasicEmail">
              <div style={{width:'100%'}}>
                <Form.Label>Residency status</Form.Label>
                <Form.Select
                  //disabled={bcf_resident}
                  defaultValue={bcf_resident ? bcf_resident : residency} 
                  onChange={e => {setResidency(e.target.value)}} 
                  aria-label="Default select example" 
                  placeholder='Select an option'
                >
                  <option residency='UAE Resident'>UAE Resident</option>
                  <option residency='UAE National'>UAE National</option>
                  <option residency='Non-Resident'>Non-Resident</option>
                </Form.Select>
              </div>
              <div style={{width:'100%'}}> 
                <Form.Label>Marital status</Form.Label>
                <Form.Select 
                 // disabled={bcf_martial}
                  aria-label="Default select example" 
                  placeholder='Select an opton'
                  defaultValue={bcf_martial ? bcf_martial : martial}
                  onChange={e => {setMartial(e.target.value)}}
                >
                  <option martial='Single'>Single</option>
                  <option martial='Married'>Married</option>
                  <option martial='Divorced'>Divorced</option>
                  <option martial='Widowed'>Widowed</option>
                </Form.Select>
              </div>
            </Form.Group>
            <Form.Group className="d-flex justify-content-between mb-3 gap-2 mobile-class" controlId="formBasicEmail">
              <div style={{width:'100%'}}> 
                <Form.Label>What are you Looking for ?</Form.Label>
                <Form.Select 
                  //disabled={bcf_looking_for}
                  aria-label="Default select example" 
                  placeholder='Select an opton'
                  defaultValue={bcf_looking_for ? bcf_looking_for : lookingFor}
                  onChange={e => {setLookingFor(e.target.value)}}
                >
                  <option value='Mortgage'>Mortgage</option>
                  <option value='Trade finance'>Trade Finance</option>
                  <option value='Investment'>Investment</option>
                 
                </Form.Select>
              </div>
            </Form.Group>
          </div>
       
        <div className='d-flex justify-content-end mt-4'>
          { bcf_first_name ? 
           <Link to='/Income'
           style={{
             textDecoration: 'none'
           }}
         >
            <Button
              className="d-flex justify-content-center align-items-center rounded-4"
              style={{minWidth:'150px'}}
              variant="outline-dark"
              type="submit"
              onClick={() => {
                handleData()
              }}
            >
              Next step
            </Button>
            </Link>
            :
            <Link to='/Income'
              style={{
                textDecoration: 'none'
              }}
            >
              <Button
                className="d-flex justify-content-center align-items-center rounded-4"
                style={{minWidth:'150px'}}
                variant="outline-dark"
                type="submit"
                onClick={() => {
                  // dispatch(progress_value(18));
                  handleData()
                }}
              >
                Next step
              </Button>
            </Link>
          }
        </div>
        </Form>
         )}
        </Formik>
      </Card>
      </div>
    </Container>
    </div>
    </body>
    </>
  )
}

export default Profile
