import { Auth } from "aws-amplify";
import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import GoogleLogin from "react-google-login";
import PhoneInput from "react-phone-number-input";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as yup from "yup";
import Sidebar from "../Sidebar";
const schema = yup.object().shape({
  email: yup.string().email("Invalid email address"),
});

const Login = ({ email, phone, setPhone, setEmail, onLogin, loading }) => {
  const [flag, setFlag] = useState(true);
  const user_email = useSelector((state) => state.auth.currentUser.email);
  const user_phone = useSelector((state) => state.auth.currentUser.phone);
  const [loginState, setLoginState] = useState(false);
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const responseGoogle = async (response) => {
    if (!response.profileObj) {
      return;
    }
    let tempEmail = response.profileObj.email;
    try {
      let result = await Auth.signIn(tempEmail);
      window.cognitoUser = result;
      navigate("/confirmation");
    } catch (error) {
      console.log("error");
      console.log(error);
      await Auth.signUp({
        username: tempEmail,
        password: tempEmail,
        attributes: {
          email: tempEmail,
          phone_number: phone,
        },
      });
      let result = await Auth.signIn(tempEmail);
      window.cognitoUser = result;
      navigate("/confirmation");
    }
  };

  return (
    <body style={{ width: "100%" }}>
      <Sidebar />
      <Formik
        validationSchema={schema}
        onSubmit={(e) => onLogin()}
        initialValues={{
          email: email,
          phone: phone,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
          <div id="home" class="confidence-home-area">
            <div class="container-fluid">
              <div class="row align-items-center mt-5">
                <div class="col-lg-6 col-md-12" style={{ textAlign: "center" }}>
                  <div class="confidence-home-content more-neg">
                    <span class="sub-title" style={{ fontSize: "25px" }}>
                      Leading mortgage broker
                    </span>
                    <h2 style={{ fontSize: "35px", fontWeight: "bold" }}>
                      Your Personalised Mortgage Broker
                    </h2>
                  </div>
                </div>
                <div
                  class="col-lg-6 col-md-12 pt-5"
                  style={{ textAlign: "center" }}
                >
                  <div class="confidence-home-content">
                    {/* <span class="sub-title" style={{fontSize: '25px'}}>Reliable Sustainable Provider</span> */}
                    <h2>Sign In</h2>
                    <p style={{ textAlign: "center", margin: "auto" }}>
                      Find the home finance that matches your needs, in best
                      possible rates, without any hidden fees
                    </p>
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row
                        className="rounded-2 d-flex justify-content-between align-items-center"
                        style={{
                          width: "80%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginBottom: "16px",
                          // margin: '40px 0px 16px 0px',
                          background: "#DEE2E9",
                          fontSize: "12px",
                        }}
                      >
                        <Col
                          className={`text-nowrap text-center ${
                            flag ? "sign-focus bgwhite" : "sign-focus "
                          }`}
                          style={{ cursor: "pointer" }}
                          onClick={() => setFlag(true)}
                        >
                          Sign in with email
                        </Col>
                      </Row>
                      <GoogleLogin
                        clientId="1055718116320-p14lqgsrn2chlo6ev55ov8usesuihm9h.apps.googleusercontent.com"
                        redirectUri="https://www.user.bcf-mortgage.com"
                        render={(renderProps) => (
                          <Button
                            className=" d-flex justify-content-center align-items-center sign-google"
                            style={{
                              width: "80%",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            onClick={renderProps.onClick}
                          >
                            <img
                              style={{ position: "absolute", left: "30px" }}
                              src="/assets/images/google.a0cf285d.svg"
                            />
                            Sign in with Google
                          </Button>
                        )}
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={"single_host_origin"}
                      />
                      <div className="d-flex align-items-center justify-content-center">
                        {/* <h1 style={{position: 'absolute', width: '100%', border: '0.5px solid rgb(222, 226, 233)', margin: '8px 0px'}}></h1> */}
                        <p
                          style={{
                            color: "rgb(127, 133, 148)",
                            padding: "0px 2rem",
                            margin: "10px 0px 15px",

                            lineHeight: "20px",
                            fontSize: "16px",
                            fontWeight: 500,
                            zIndex: 1,
                          }}
                        >
                          OR
                        </p>
                      </div>
                      {flag ? (
                        <>
                          <h6>Email</h6>
                          <div
                            style={{
                              width: "80%",
                              height: "64px",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          >
                            <Form.Control
                              className="px-3 w-100 h-100 rounded-3"
                              placeholder="E.g.name@example.com"
                              type="text"
                              style={{
                                fontSize: "15px",
                              }}
                              name="email"
                              value={email}
                              onChange={(e) => {
                                handleChange(e);
                                setEmail(e.target.value);
                              }}
                              isInvalid={!!touched && !!errors.email}
                              onBlur={handleBlur}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <h6>Phone Number</h6>
                          <div className="phone-wrapper">
                            <PhoneInput
                              className="px-3 w-100 h-100 phone-input"
                              type="tel"
                              placeholder="Enter phone number"
                              international
                              name="phone"
                              defaultCountry="US"
                              value={phone}
                              onChange={setPhone}
                              style={{
                                fontSize: "15px",
                              }}
                            />
                          </div>
                        </>
                      )}
                      <Button
                        className=" d-flex justify-content-center align-items-center main-default-btn2"
                        style={{
                          height: "56px",
                          width: "280px",
                          fontSize: "1.2rem",
                          borderRadius: "10rem",
                          margin: "10px 0px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          padding: "0px 2rem",
                          border: "none",
                          backgroundColor: `${
                            phone || email ? "black" : "rgb(222, 226, 233)"
                          }`,
                          color: `${
                            phone || email ? "white" : "rgb(82, 87, 99)"
                          }`,
                        }}
                        type="submit"
                        disabled={phone || email ? false : true}
                      >
                        {loading && <Spinner animation="border" />}
                        &nbsp;&nbsp;&nbsp;Sign In
                      </Button>
                    </Form>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="confidence-home-content more-neg-mar text-center">
                    <p style={{ textAlign: "center", margin: "auto" }}>
                      Bandenia will save you a lot of time and stress; We will
                      handle everything from searching for a deal to applying
                      and communicating with the lender on your behalf. Expert
                      in the mortgage market and will be able to recommend deals
                      that suit your situation.
                    </p>
                    {/* <a href="#" class="main-default-btn2" >Islamic Finance</a>
                    
                    <a href="#" class="main-default-btn2" >Instant Quotation</a> */}
                  </div>
                  <div class="px-3">
                    <h3 class="text-center">
                      {/* Ramadan Offer */}
                      Special summer offer
                    </h3>
                    <div className="row px-5">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div class="offer">
                          <a href="#" class="main-default-btn2 ml-0">
                            {" "}
                            Processing Fees Waived{" "}
                          </a>
                          <p>
                            Apply for a mortgage loan through us and we will pay
                            your Mortgage Processing Fees (0.5% of loan amount).
                            Avail this offer now as it is valid for a limited
                            period (up to 30 June)
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div class="offer">
                          <a href="#" class="main-default-btn2">
                            {" "}
                            Valuation Fees Waived{" "}
                          </a>
                          <p>
                            Apply for a mortgage loan through us and we will pay
                            your Mortgage Valuation Fees.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="justify">
                      <span className="bold">
                        You can choose any one of the above options
                      </span>
                    </div>
                  </div>
                  {/* <div className="row px-5">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div>
              <a href="#" class="main-default-btn2 ml-0" > For mortgages &gt; AED 1.5M </a>
              <h6>
              Finance your mortgage with Bandenia and
              get up to 3,000 AED cash back !
              </h6>
              </div>
              <ul>
                
                <li>Refund of valuation fee up to 3,000 AED</li>
                <li>
                Zero advisory fee - we get paid by our banking partners!
                </li>
                <li>
                Valid for UAE residents and non-residents
                </li>
                
              </ul>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div>
                <a href="#" class="main-default-btn2" >For mortgages &lt; AED 3M</a>
              <h6>
              Finance your mortgage with Bandenia and
            get up to 5,000 AED cash back !
              </h6>
                </div>
                <ul>
                
                <li>Refund of full registration fee 4,000 AED</li>
                <li>Refund of valuation fee up to 1,000 AED</li>
                <li>
                Zero advisory fee - we get paid by our banking partners!
                </li>
                <li>
                Valid for UAE residents and non-residents
                </li>
                
              </ul>
              </div>
            </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </body>
  );
};

export default Login;
