import { useState } from 'react'
import DocsComponent from '../components/Docs';
import { Auth } from 'aws-amplify'
import axios from 'axios';
import { Storage } from "aws-amplify";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { sidebar_check } from '../actions/sidebar'
import { useToasts } from 'react-toast-notifications';
const Docs = () => {
  const [Passport_Modal, set_Passport_Modal] = useState(false);
  const [Visa_Modal,set_Visa_Modal] = useState(false);
  const [EID_Modal, set_EID_Modal] = useState(false);
  const [passport, setPassport] = useState('');
  const [passportName, setPassportName] = useState('');
  const [visaName, setVisaName] = useState('');
  const [EIDName, setEIDName] = useState('');
  const [salaryName, setSalaryName] = useState('');
  const [visa, setVisa] = useState('');
  const [EID, setEID] = useState('');
  const [pass_list, set_pass_list] = useState([{id:0}]);
  const [salary_list, set_salary_list] = useState([{id:0}]);
  const [EID_list, set_EID_list] = useState([{id:0}]);
  const [visa_list, set_visa_list] = useState([{id:0}]);
  const [submittedCrm, setSubmittedCrm] = useState(false);
  const handle_Close_Passport = () => {set_Passport_Modal(false)};
  const handle_Passport_Modal = () => {set_Passport_Modal(true)};
  const handle_Visa_Modal = () => {set_Visa_Modal(true)}
  const handle_Close_Visa = () => {set_Visa_Modal(false)}
  const handle_EID_Modal = () => {set_EID_Modal(true)}
  const handle_Close_EID = () => {set_EID_Modal(false)}
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false)
  const [uploadPassFiles, setUploadPassFiles] = useState([]);
  const [localPass, setLocalPass] = useState('');
  const [passBlock, setPassBlock] = useState(false);
  const [uploadTradeFiles, setUploadTradeFiles] = useState([]);
  const [localTrade, setLocalTrade] = useState('');
  const [tradeBlock, setTradeBlock] = useState(false);
  const [uploadCreditFiles, setUploadCreditFiles] = useState([]);
  const [localCredit, setLocalCredit] = useState('');
  const [creditBlock, setCreditBlock] = useState(false);
  const [uploadAuditFiles, setUploadAuditFiles] = useState([]);
  const [localAudit, setLocalAudit] = useState('');
  const [auditBlock, setAuditBlock] = useState(false);
  const [uploadShareFiles, setUploadShareFiles] = useState([]);
  const [localShare, setLocalShare] = useState('');
  const [shareBlock, setShareBlock] = useState(false);
  const [uploadIncomeFiles, setUploadIncomeFiles] = useState([]);
  const [localIncome, setLocalIncome] = useState('');
  const [incomeBlock, setIncomeBlock] = useState(false);
  const [uploadOfferLetterFiles, setUploadOfferLetterFiles] = useState([]);
  const [localOfferLetter, setLocalOfferLetter] = useState('');
  const [offerLetterBlock, setOfferLetterBlock] = useState(false);
  const [uploadMOAFiles, setUploadMOAFiles] = useState([]);
  const [localMOA, setLocalMOA] = useState('');
  const [MOABlock, setMOABlock] = useState(false);

  const [uploadBankFiles, setUploadBankFiles] = useState([]);
  const [localBank, setLocalBank] = useState('');
  const [bankBlock, setBankBlock] = useState(false);
  const [uploadVisaFiles, setUploadVisaFiles] = useState([]);
  const [localVisa, setLocalVisa] = useState('');
  const [VisaBlock, setVisaBlock] = useState(false);
  const [uploadSalaryFiles, setUploadSalaryFiles] = useState([]);
  const [localSalary, setLocalSalary] = useState('');
  const [SalaryBlock, setSalaryBlock] = useState(false);
  const [uploadEIDFiles, setUploadEIDFiles] = useState([]);
  const [localEID, setLocalEID] = useState('');
  const [EIDBlock, setEIDBlock] = useState(false);
 console.log('uploadVisaFiles',uploadVisaFiles)
  const dispatch = useDispatch();
	const navigate = useNavigate();
  const handlePassFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadPassFiles([...uploadPassFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };
  const handlePassRemove = (f) => {
    setUploadPassFiles(uploadPassFiles.filter(x => x.file.name !== f.name));
    // setPassBlock(false);
  };
  const handlePassDropFiles = (files) => {
    files.forEach((f, i) => handlePassFile(f, `${new Date().getTime()}-${i}`))
    setPassBlock(true)
  };

  const handleTradeFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadTradeFiles([...uploadTradeFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };
  const handleTradeRemove = (f) => {
    setUploadTradeFiles(uploadTradeFiles.filter(x => x.file.name !== f.name));
    // setTradeBlock(false);
  };
  const handleTradeDropFiles = (files) => {
    files.forEach((f, i) => handleTradeFile(f, `${new Date().getTime()}-${i}`))
    setTradeBlock(true)
  };

  const handleIncomeFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadIncomeFiles([...uploadIncomeFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };
  const handleIncomeRemove = (f) => {
    setUploadIncomeFiles(uploadIncomeFiles.filter(x => x.file.name !== f.name));
    // setIncomeBlock(false);
  };
  const handleIncomeDropFiles = (files) => {
    files.forEach((f, i) => handleIncomeFile(f, `${new Date().getTime()}-${i}`))
    setIncomeBlock(true)
  };

  const handleMOAFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadMOAFiles([...uploadMOAFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };
  const handleMOARemove = (f) => {
    setUploadMOAFiles(uploadMOAFiles.filter(x => x.file.name !== f.name));
    // setMOABlock(false);
  };
  const handleMOADropFiles = (files) => {
    files.forEach((f, i) => handleMOAFile(f, `${new Date().getTime()}-${i}`))
    setMOABlock(true)
  };

  const handleShareFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadShareFiles([...uploadShareFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };
  const handleShareRemove = (f) => {
    setUploadShareFiles(uploadShareFiles.filter(x => x.file.name !== f.name));
    // setShareBlock(false);
  };
  const handleShareDropFiles = (files) => {
    files.forEach((f, i) => handleShareFile(f, `${new Date().getTime()}-${i}`))
    setShareBlock(true)
  };

  const handleOfferLetterFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadOfferLetterFiles([...uploadOfferLetterFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };
  const handleOfferLetterRemove = (f) => {
    setUploadOfferLetterFiles(uploadOfferLetterFiles.filter(x => x.file.name !== f.name));
    // setOfferLetterBlock(false);
  };
  const handleOfferLetterDropFiles = (files) => {
    files.forEach((f, i) => handleOfferLetterFile(f, `${new Date().getTime()}-${i}`))
    setOfferLetterBlock(true)
  };

  const handleCreditFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadCreditFiles([...uploadCreditFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };
  const handleCreditRemove = (f) => {
    setUploadCreditFiles(uploadCreditFiles.filter(x => x.file.name !== f.name));
    // setCreditBlock(false);
  };
  const handleCreditDropFiles = (files) => {
    files.forEach((f, i) => handleCreditFile(f, `${new Date().getTime()}-${i}`))
    setCreditBlock(true)
  };


  const handleAuditFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadAuditFiles([...uploadAuditFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };
  const handleAuditRemove = (f) => {
    setUploadAuditFiles(uploadAuditFiles.filter(x => x.file.name !== f.name));
    // setAuditBlock(false);
  };
  const handleAuditDropFiles = (files) => {
    files.forEach((f, i) => handleAuditFile(f, `${new Date().getTime()}-${i}`))
    setAuditBlock(true)
  };

  const handleBankFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadBankFiles([...uploadBankFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };
  const handleBankRemove = (f) => {
    setUploadBankFiles(uploadBankFiles.filter(x => x.file.name !== f.name));
    // setBankBlock(false);
  };
  const handleBankDropFiles = (files) => {
    files.forEach((f, i) => handleBankFile(f, `${new Date().getTime()}-${i}`))
    setBankBlock(true)
  };


  const handleVisaFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadVisaFiles([...uploadVisaFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };
  const handleVisaRemove = (f) => {
    setUploadVisaFiles(uploadVisaFiles.filter(x => x.file.name !== f.name));
    // setVisaBlock(false);
  };
  const handleVisaDropFiles = (files) => {
    files.forEach((f, i) => handleVisaFile(f, `${new Date().getTime()}-${i}`))
    setVisaBlock(true)
  };

  const handleEIDFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadEIDFiles([...uploadEIDFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };
  const handleEIDRemove = (f) => {
    setUploadEIDFiles(uploadEIDFiles.filter(x => x.file.name !== f.name));
    // setEIDBlock(false);
  };
  const handleEIDDropFiles = (files) => {
    files.forEach((f, i) => handleEIDFile(f, `${new Date().getTime()}-${i}`))
    setEIDBlock(true)
  };

  const handleSalaryFile = async (file, id) => {
    console.log('file', file)
    if(file.size <= 26214400) {
      setUploadSalaryFiles([...uploadSalaryFiles,
      {
        file: file
      }])
    }
    else {
      addToast('You can not select file larger than 25MB', {
        appearance:'error',
        autoDismiss:true
      })
    }
  };
  const handleSalaryRemove = (f) => {
    setUploadSalaryFiles(uploadSalaryFiles.filter(x => x.file.name !== f.name));
    // setSalaryBlock(false);
  };
  const handleSalaryDropFiles = (files) => {
    files.forEach((f, i) => handleSalaryFile(f, `${new Date().getTime()}-${i}`))
    setSalaryBlock(true)
  };
  const handlePassUpload = async (e) => {
    if (uploadPassFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = await session.getIdToken().payload.sub;
        let index;
        console.log('uploadFiles',uploadPassFiles)
        for (index = 0; index <= uploadPassFiles.length - 1; index++ ) {
          let file=uploadPassFiles[index].file;
          let fileExt = file.name.split('.').pop()
          let path=username + "/" + `passport${index + 1}.` + fileExt
          let passName = uploadPassFiles[index].name
          const result = await Storage.put(path, file, {
            contentType: uploadPassFiles[index].type,
          });
        
 
        let incomeData = {}
        incomeData[`passport${index + 1}_path`] = path
        incomeData[`passport${index + 1}_name`] = passName
      const response = axios({
        method: 'post',
        headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
        url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
        data:{
          ...incomeData,
          client_id: await session.getIdToken().payload.sub,
        },
        crossDomain: true
      });
      setPassportName(path);
      console.log("upload done")
      }
    }
      catch(err){
        console.log('err',err)
      }
    }   

  
  }

  const handlePassport = (e) => {    
    setPassport(e.target.files[0])
    set_Passport_Modal(false);
  }
  const handleVisaUpload = async (e) => {
    if (uploadVisaFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = await session.getIdToken().payload.sub;
        let fileExt = uploadVisaFiles[0].file.name.split('.').pop()
        let path=username + "/" + `visa.` + fileExt
        const result = await Storage.put(path, uploadVisaFiles[0].file, {
          contentType: uploadVisaFiles[0].type,
        });
        let incomeData = {visa_path:path,client_id: await session.getIdToken().payload.sub,}
      console.log(incomeData)
      const response = axios({
        method: 'post',
        headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
        url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
        data: incomeData,
        crossDomain: true
      });
      setVisaName(path);
      console.log("upload done")
      }
      catch(err){
        console.log('err',err)
      }
    } 
  }

  const handleVisa = (e) => {    
    setVisa(e.target.files[0]) 
    set_Visa_Modal(false)
  }
 
 
  const handleEIDUpload = async (e) => {
    if (uploadEIDFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = await session.getIdToken().payload.sub;
        let fileExt = uploadEIDFiles[0].file.name.split('.').pop()
        let path=username + "/" + `EID.` + fileExt
        const result = await Storage.put(path, uploadEIDFiles[0].file, {
          contentType: uploadEIDFiles[0].type,
        });
        let incomeData = {EID_path:path,client_id: await session.getIdToken().payload.sub,}
      console.log(incomeData)
      const response = axios({
        method: 'post',
        headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
        url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
        data: incomeData,
        crossDomain: true
      });
      setEIDName(path);
      console.log("upload done")
      }
      catch(err){
        console.log('err',err)
      }
    } 
  }

  const handleSalaryUpload = async (e) => {
    if (uploadSalaryFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = await session.getIdToken().payload.sub;
        let fileExt = uploadSalaryFiles[0].file.name.split('.').pop()
        let path=username + "/" + `salary.` + fileExt
        const result = await Storage.put(path, uploadSalaryFiles[0].file, {
          contentType: uploadSalaryFiles[0].type,
        });
        let incomeData = {salary_path:path,client_id: await session.getIdToken().payload.sub,}
      console.log(incomeData)
      const response = axios({
        method: 'post',
        headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
        url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
        data: incomeData,
        crossDomain: true
      });
      setSalaryName(path);
      console.log("upload done")
      }
      catch(err){
        console.log('err',err)
      }
    } 
  }

  const handleSubmit = async (e) => { 
    setLoading(true)
    if (uploadPassFiles[0]) {
      try {      
        let session = await Auth.currentSession();
        let username = await session.getIdToken().payload.sub;
        let index;
        console.log('uploadFiles',uploadPassFiles)
        for (index = 0; index <= uploadPassFiles.length - 1; index++ ) {
          let file=uploadPassFiles[index].file;
          let fileExt = file.name.split('.').pop()
          let path=username + "/" + `passport${index + 1}.` + fileExt
          let passName = uploadPassFiles[index].name
          const result = await Storage.put(path, file, {
            contentType: uploadPassFiles[index].type,
          });
        let incomeData = {}
        incomeData[`passport${index + 1}_path`] = path
        incomeData[`passport${index + 1}_name`] = passName
      const response = axios({
        method: 'post',
        headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
        url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
        data:{
          ...incomeData,
          client_id: await session.getIdToken().payload.sub,
        },
        crossDomain: true
      });
      setPassportName(path);
      console.log("upload done")
      }
    }
      catch(err){
        console.log('err',err)
      }
  }
  if (uploadTradeFiles[0]) {
    try {      
      let session = await Auth.currentSession();
      let username = await session.getIdToken().payload.sub;
      let index;
      console.log('uploadFiles',uploadTradeFiles)
      for (index = 0; index <= uploadTradeFiles.length - 1; index++ ) {
        let file=uploadTradeFiles[index].file;
        let fileExt = file.name.split('.').pop()
        let path=username + "/" + `trade${index + 1}.` + fileExt
        let trade = uploadTradeFiles[index].name
        const result = await Storage.put(path, file, {
          contentType: uploadTradeFiles[index].type,
        });
      let incomeData = {}
      incomeData[`trade${index + 1}_path`] = path
      incomeData[`trade${index + 1}_name`] = trade
    const response = axios({
      method: 'post',
      headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
      url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
      data:{
        ...incomeData,
        client_id: await session.getIdToken().payload.sub,
      },
      crossDomain: true
    });
    
    console.log("upload done")
    }
  }
    catch(err){
      console.log('err',err)
    }
}
if (uploadShareFiles[0]) {
  try {      
    let session = await Auth.currentSession();
    let username = await session.getIdToken().payload.sub;
    let index;
    console.log('uploadFiles',uploadShareFiles)
    for (index = 0; index <= uploadShareFiles.length - 1; index++ ) {
      let file=uploadShareFiles[index].file;
      let fileExt = file.name.split('.').pop()
      let path=username + "/" + `share${index + 1}.` + fileExt
      let share = uploadShareFiles[index].name
      const result = await Storage.put(path, file, {
        contentType: uploadShareFiles[index].type,
      });
    let incomeData = {}
    incomeData[`share${index + 1}_path`] = path
    incomeData[`share${index + 1}_name`] = share
  const response = axios({
    method: 'post',
    headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
    url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
    data:{
      ...incomeData,
      client_id: await session.getIdToken().payload.sub,
    },
    crossDomain: true
  });
  
  console.log("upload done")
  }
}
  catch(err){
    console.log('err',err)
  }
}

if (uploadMOAFiles[0]) {
  try {      
    let session = await Auth.currentSession();
    let username = await session.getIdToken().payload.sub;
    let index;
    console.log('uploadFiles',uploadMOAFiles)
    for (index = 0; index <= uploadMOAFiles.length - 1; index++ ) {
      let file=uploadMOAFiles[index].file;
      let fileExt = file.name.split('.').pop()
      let path=username + "/" + `MOA${index + 1}.` + fileExt
      let MOA = uploadMOAFiles[index].name
      const result = await Storage.put(path, file, {
        contentType: uploadMOAFiles[index].type,
      });
    let incomeData = {}
    incomeData[`MOA${index + 1}_path`] = path
    incomeData[`MOA${index + 1}_name`] = MOA
  const response = axios({
    method: 'post',
    headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
    url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
    data:{
      ...incomeData,
      client_id: await session.getIdToken().payload.sub,
    },
    crossDomain: true
  });
  
  console.log("upload done")
  }
}
  catch(err){
    console.log('err',err)
  }
}

if (uploadCreditFiles[0]) {
  try {      
    let session = await Auth.currentSession();
    let username = await session.getIdToken().payload.sub;
    let index;
    console.log('uploadFiles',uploadCreditFiles)
    for (index = 0; index <= uploadCreditFiles.length - 1; index++ ) {
      let file=uploadCreditFiles[index].file;
      let fileExt = file.name.split('.').pop()
      let path=username + "/" + `credit${index + 1}.` + fileExt
      let credit = uploadCreditFiles[index].name
      const result = await Storage.put(path, file, {
        contentType: uploadCreditFiles[index].type,
      });
    let incomeData = {}
    incomeData[`credit${index + 1}_path`] = path
    incomeData[`credit${index + 1}_name`] = credit
  const response = axios({
    method: 'post',
    headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
    url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
    data:{
      ...incomeData,
      client_id: await session.getIdToken().payload.sub,
    },
    crossDomain: true
  });
  
  console.log("upload done")
  }
}
  catch(err){
    console.log('err',err)
  }
}

if (uploadAuditFiles[0]) {
  try {      
    let session = await Auth.currentSession();
    let username = await session.getIdToken().payload.sub;
    let index;
    console.log('uploadFiles',uploadAuditFiles)
    for (index = 0; index <= uploadAuditFiles.length - 1; index++ ) {
      let file=uploadAuditFiles[index].file;
      let fileExt = file.name.split('.').pop()
      let path=username + "/" + `audit${index + 1}.` + fileExt
      let audit = uploadAuditFiles[index].name
      const result = await Storage.put(path, file, {
        contentType: uploadAuditFiles[index].type,
      });
    let incomeData = {}
    incomeData[`audit${index + 1}_path`] = path
    incomeData[`audit${index + 1}_name`] = audit
  const response = axios({
    method: 'post',
    headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
    url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
    data:{
      ...incomeData,
      client_id: await session.getIdToken().payload.sub,
    },
    crossDomain: true
  });
  
  console.log("upload done")
  }
}
  catch(err){
    console.log('err',err)
  }
}

if (uploadIncomeFiles[0]) {
  try {      
    let session = await Auth.currentSession();
    let username = await session.getIdToken().payload.sub;
    let index;
    console.log('uploadFiles',uploadIncomeFiles)
    for (index = 0; index <= uploadIncomeFiles.length - 1; index++ ) {
      let file=uploadIncomeFiles[index].file;
      let fileExt = file.name.split('.').pop()
      let path=username + "/" + `income${index + 1}.` + fileExt
      let income = uploadIncomeFiles[index].name
      const result = await Storage.put(path, file, {
        contentType: uploadIncomeFiles[index].type,
      });
    let incomeData = {}
    incomeData[`income${index + 1}_path`] = path
    incomeData[`income${index + 1}_name`] = income
  const response = axios({
    method: 'post',
    headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
    url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
    data:{
      ...incomeData,
      client_id: await session.getIdToken().payload.sub,
    },
    crossDomain: true
  });
  
  console.log("upload done")
  }
}
  catch(err){
    console.log('err',err)
  }
}

if (uploadOfferLetterFiles[0]) {
  try {      
    let session = await Auth.currentSession();
    let username = await session.getIdToken().payload.sub;
    let index;
    console.log('uploadFiles',uploadOfferLetterFiles)
    for (index = 0; index <= uploadOfferLetterFiles.length - 1; index++ ) {
      let file=uploadOfferLetterFiles[index].file;
      let fileExt = file.name.split('.').pop()
      let path=username + "/" + `offerLetter${index + 1}.` + fileExt
      let offerLetter = uploadOfferLetterFiles[index].name
      const result = await Storage.put(path, file, {
        contentType: uploadOfferLetterFiles[index].type,
      });
    let incomeData = {}
    incomeData[`offerLetter${index + 1}_path`] = path
    incomeData[`offerLetter${index + 1}_name`] = offerLetter
  const response = axios({
    method: 'post',
    headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
    url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
    data:{
      ...incomeData,
      client_id: await session.getIdToken().payload.sub,
    },
    crossDomain: true
  });
  
  console.log("upload done")
  }
}
  catch(err){
    console.log('err',err)
  }
}


  

  if (uploadBankFiles[0]) {
    try {      
      let session = await Auth.currentSession();
      let username = await session.getIdToken().payload.sub;
      let index;
      console.log('uploadFiles',uploadBankFiles)
      for (index = 0; index <= uploadBankFiles.length - 1; index++ ) {
        let file=uploadBankFiles[index].file;
        let fileExt = file.name.split('.').pop()
        let path=username + "/" + `bank${index + 1}.` + fileExt
        let bank = uploadBankFiles[index].name
        const result = await Storage.put(path, file, {
          contentType: uploadBankFiles[index].type,
        });
      let incomeData = {}
      incomeData[`bank${index + 1}_path`] = path
      incomeData[`bank${index + 1}_name`] = bank
    const response = axios({
      method: 'post',
      headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
      url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
      data:{
        ...incomeData,
        client_id: await session.getIdToken().payload.sub,
      },
      crossDomain: true
    });
    
    console.log("upload done")
    }
  }
    catch(err){
      console.log('err',err)
    }
}

  if (uploadVisaFiles[0]) {
    try {      
      let session = await Auth.currentSession();
      let username = await session.getIdToken().payload.sub;
      let index;
      console.log('uploadFiles',uploadVisaFiles)
      for (index = 0; index <= uploadVisaFiles.length - 1; index++ ) {
        let file=uploadVisaFiles[index].file;
        let fileExt = file.name.split('.').pop()
        let path=username + "/" + `visa${index + 1}.` + fileExt
        let VisaName = uploadVisaFiles[index].name
        const result = await Storage.put(path, file, {
          contentType: uploadVisaFiles[index].type,
        });
      let incomeData = {}
      incomeData[`visa${index + 1}_path`] = path
      incomeData[`visa${index + 1}_name`] = VisaName
    const response = axios({
      method: 'post',
      headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
      url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
      data:{
        ...incomeData,
        client_id: await session.getIdToken().payload.sub,
      },
      crossDomain: true
    });
    setVisaName(path);
    console.log("upload done")
    }
  }
    catch(err){
      console.log('err',err)
    }
}

if (uploadEIDFiles[0]) {
  try {      
    let session = await Auth.currentSession();
    let username = await session.getIdToken().payload.sub;
    let index;
    console.log('uploadFiles',uploadEIDFiles)
    for (index = 0; index <= uploadEIDFiles.length - 1; index++ ) {
      let file=uploadEIDFiles[index].file;
      let fileExt = file.name.split('.').pop()
      let path=username + "/" + `EID${index + 1}.` + fileExt
      let EIDName = uploadEIDFiles[index].name
      const result = await Storage.put(path, file, {
        contentType: uploadEIDFiles[index].type,
      });
    let incomeData = {}
    incomeData[`EID${index + 1}_path`] = path
    incomeData[`EID${index + 1}_name`] = EIDName
  const response = axios({
    method: 'post',
    headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
    url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
    data:{
      ...incomeData,
      client_id: await session.getIdToken().payload.sub,
    },
    crossDomain: true
  });
  setEIDName(path);
  console.log("upload done")
  }
}
  catch(err){
    console.log('err',err)
  }
}

if (uploadSalaryFiles[0]) {
  try {      
    let session = await Auth.currentSession();
    let username = await session.getIdToken().payload.sub;
    let index;
    console.log('uploadFiles',uploadSalaryFiles)
    for (index = 0; index <= uploadSalaryFiles.length - 1; index++ ) {
      let file=uploadSalaryFiles[index].file;
      let fileExt = file.name.split('.').pop()
      let path=username + "/" + `salary${index + 1}.` + fileExt
      let SalaryName = uploadSalaryFiles[index].name
      const result = await Storage.put(path, file, {
        contentType: uploadSalaryFiles[index].type,
      });
    let incomeData = {}
    incomeData[`salary${index + 1}_path`] = path
    incomeData[`salary${index + 1}_name`] = salaryName
  const response = axios({
    method: 'post',
    headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
    url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
    data:{
      ...incomeData,
      client_id: await session.getIdToken().payload.sub,
    },
    crossDomain: true
  });
  setSalaryName(path);
  console.log("upload done")
  }
}
  catch(err){
    console.log('err',err)
  }
}
      let session = await Auth.currentSession();        
        const responseCrm = await axios({
        method: 'get',
        headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
        url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/submit_data_crm',
        crossDomain: true
      });
      console.log(responseCrm);
      dispatch(sidebar_check('Documents'));
      navigate('/Status')
      setLoading(false)
  }

  const handleEID = (e) => {    
    setEID(e.target.files[0])   
    set_EID_Modal(false);
  }
  return (
    <DocsComponent 
      handlePassport={handlePassport}
      handle_Close_Passport={handle_Close_Passport}
      Passport_Modal={Passport_Modal}
      handle_Passport_Modal={handle_Passport_Modal}
      //handle_Passport_Upload={handlePassportUpload}
      setPassport={setPassport}
      passportName={passportName}
      EIDName={EIDName}
      handle_EID_Upload={handleEIDUpload}
      setEID={setEID}
      passport={passport}
      Visa_Modal={Visa_Modal}
      handle_Close_Visa={handle_Close_Visa}
      handleVisa={handleVisa}
      handle_Visa_Modal={handle_Visa_Modal}
      handle_Visa_Upload={handleVisaUpload}
      setVisa={setVisa}
      visaName={visaName}
      visa={visa}
      EID_Modal={EID_Modal}
      handle_Close_EID={handle_Close_EID}
      handleEID={handleEID}
      EID={EID}
      submittedCrm={submittedCrm}
      setSubmittedCrm={setSubmittedCrm}
      handle_EID_Modal={handle_EID_Modal}
      handleSubmit={handleSubmit}

      handlePassFile={handlePassFile}
      uploadPassFiles={uploadPassFiles}
      setUploadPassFiles={setUploadPassFiles}
      localPass={localPass}
      setLocalPass={setLocalPass}
      passBlock={passBlock}
      setPassBlock={setPassBlock}
      handlePassDropFiles={handlePassDropFiles}
      handlePassRemove={handlePassRemove}

      handleBankFile={handleBankFile}
      uploadBankFiles={uploadBankFiles}
      setUploadBankFiles={setUploadBankFiles}
      localBank={localBank}
      setLocalBank={setLocalBank}
      bankBlock={bankBlock}
      setBankBlock={setBankBlock}
      handleBankDropFiles={handleBankDropFiles}
      handleBankRemove={handleBankRemove}

      handleVisaFile={handleVisaFile}
      uploadVisaFiles={uploadVisaFiles}
      setUploadVisaFiles={setUploadVisaFiles}
      localVisa={localVisa}
      setLocalVisa={setLocalVisa}
      VisaBlock={VisaBlock}
      setVisaBlock={setVisaBlock}
      handleVisaDropFiles={handleVisaDropFiles}
      handleVisaRemove={handleVisaRemove}

      handleEIDFile={handleEIDFile}
      uploadEIDFiles={uploadEIDFiles}
      setUploadEIDFiles={setUploadEIDFiles}
      localEID={localEID}
      setLocalEID={setLocalEID}
      EIDBlock={EIDBlock}
      setEIDBlock={setEIDBlock}
      handleEIDDropFiles={handleEIDDropFiles}
      handleEIDRemove={handleEIDRemove}

      handleTradeFile={handleTradeFile}
      uploadTradeFiles={uploadTradeFiles}
      setUploadTradeFiles={setUploadTradeFiles}
      localTrade={localTrade}
      setLocalTrade={setLocalTrade}
      tradeBlock={tradeBlock}
      setTradeBlock={setTradeBlock}
      handleTradeDropFiles={handleTradeDropFiles}
      handleTradeRemove={handleTradeRemove}

      handleShareFile={handleShareFile}
      uploadShareFiles={uploadShareFiles}
      setUploadShareFiles={setUploadShareFiles}
      localShare={localShare}
      setLocalShare={setLocalShare}
      shareBlock={shareBlock}
      setShareBlock={setShareBlock}
      handleShareDropFiles={handleShareDropFiles}
      handleShareRemove={handleShareRemove}

      handleIncomeFile={handleIncomeFile}
      uploadIncomeFiles={uploadIncomeFiles}
      setUploadIncomeFiles={setUploadIncomeFiles}
      localIncome={localIncome}
      setLocalIncome={setLocalIncome}
      incomeBlock={incomeBlock}
      setIncomeBlock={setIncomeBlock}
      handleIncomeDropFiles={handleIncomeDropFiles}
      handleIncomeRemove={handleIncomeRemove}

      handleCreditFile={handleCreditFile}
      uploadCreditFiles={uploadCreditFiles}
      setUploadCreditFiles={setUploadCreditFiles}
      localCredit={localCredit}
      setLocalCredit={setLocalCredit}
      creditBlock={creditBlock}
      setCreditBlock={setCreditBlock}
      handleCreditDropFiles={handleCreditDropFiles}
      handleCreditRemove={handleCreditRemove}

      handleAuditFile={handleAuditFile}
      uploadAuditFiles={uploadAuditFiles}
      setUploadAuditFiles={setUploadAuditFiles}
      localAudit={localAudit}
      setLocalAudit={setLocalAudit}
      auditBlock={auditBlock}
      setAuditBlock={setAuditBlock}
      handleAuditDropFiles={handleAuditDropFiles}
      handleAuditRemove={handleAuditRemove}

      handleMOAFile={handleMOAFile}
      uploadMOAFiles={uploadMOAFiles}
      setUploadMOAFiles={setUploadMOAFiles}
      localMOA={localMOA}
      setLocalMOA={setLocalMOA}
      MOABlock={MOABlock}
      setMOABlock={setMOABlock}
      handleMOADropFiles={handleMOADropFiles}
      handleMOARemove={handleMOARemove}

      handleOfferLetterFile={handleOfferLetterFile}
      uploadOfferLetterFiles={uploadOfferLetterFiles}
      setUploadOfferLetterFiles={setUploadOfferLetterFiles}
      localOfferLetter={localOfferLetter}
      setLocalOfferLetter={setLocalOfferLetter}
      offerLetterBlock={offerLetterBlock}
      setOfferLetterBlock={setOfferLetterBlock}
      handleOfferLetterDropFiles={handleOfferLetterDropFiles}
      handleOfferLetterRemove={handleOfferLetterRemove}

      handleSalaryFile={handleSalaryFile}
      uploadSalaryFiles={uploadSalaryFiles}
      setUploadSalaryFiles={setUploadSalaryFiles}
      localSalary={localSalary}
      setLocalSalary={setLocalSalary}
      SalaryBlock={SalaryBlock}
      setSalaryBlock={setSalaryBlock}
      handleSalaryDropFiles={handleSalaryDropFiles}
      handleSalaryRemove={handleSalaryRemove}
      handlePassUpload={handlePassUpload}
      handleVisaUpload={handleVisaUpload}
      handleSalaryUpload={handleSalaryUpload}
      loading={loading}
    />
  )
}

export default Docs