import { Amplify, Storage } from "aws-amplify";
import React, { Fragment } from "react";
import FloatingWhatsApp from "react-floating-whatsapp";
import { Route, Routes } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import "./App.css";
import Calculate from "./components/Calculate";
import Compare from "./components/Compare";
import Homeloan from "./components/Homeloan";
import Mortgage from "./components/Mortgage";
import Sme from "./components/Sme";
import { awsConfig } from "./constants/aws-exports";
import Preloader from "./containers/Preloader";
import FirstView from "./containers/firstview";
import ProtectedRoute from "./helpers/ProtectedRoute";
import Confirmation from "./pages/Auth/Confirmation";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import Dashboard from "./pages/Dashboard";
import Docs from "./pages/Docs";
import Income from "./pages/Income";
import Profile from "./pages/Profile";
import Property from "./pages/Property";
import Status from "./pages/Status";

Amplify.configure({ ...awsConfig, authenticationFlowType: "CUSTOM_AUTH" });
Storage.configure({ level: "private" });

function App() {
  // const [sidebarShow, setSidebarShow] = useState(false)
  return (
    <Fragment>
      {/* <Header /> */}
      <ToastProvider>
        {/* //<Container className='app-container' > */}
        <Routes>
          {/*<Route path='/' element={<React.Fragment><Home /></React.Fragment>} />*/}
          <Route
            path="/Overview"
            element={
              <React.Fragment>
                <FirstView />
              </React.Fragment>
            }
          />
          <Route
            path="/dashboard"
            element={
              <React.Fragment>
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              </React.Fragment>
            }
          />
          <Route
            path="/"
            element={
              <React.Fragment>
                <Login />
              </React.Fragment>
            }
          />
          <Route
            path="/signup"
            element={
              <React.Fragment>
                <Signup />
              </React.Fragment>
            }
          />
          <Route
            path="/confirmation"
            element={
              <React.Fragment>
                <Confirmation />
              </React.Fragment>
            }
          />
          <Route
            path="/Profile"
            element={
              <React.Fragment>
                <Profile />
              </React.Fragment>
            }
          />
          <Route
            path="/Income"
            element={
              <React.Fragment>
                <Income />
              </React.Fragment>
            }
          />
          <Route
            path="/Property"
            element={
              <React.Fragment>
                <Property />
              </React.Fragment>
            }
          />
          <Route
            path="/Calculator"
            element={
              <React.Fragment>
                <Calculate />
              </React.Fragment>
            }
          />
          {/* <Route path='/docs' element={<React.Fragment><Offers /></React.Fragment>} /> */}
          <Route
            path="/Documents"
            element={
              <React.Fragment>
                <Docs />
              </React.Fragment>
            }
          />
          <Route
            path="/Status"
            element={
              <React.Fragment>
                <Status />
              </React.Fragment>
            }
          />
          {/* <Route path='/Home' element={<React.Fragment><Home /></React.Fragment>} /> */}
          <Route
            path="/mortgage"
            element={
              <React.Fragment>
                <Mortgage />
              </React.Fragment>
            }
          />
          <Route
            path="/homeloan"
            element={
              <React.Fragment>
                <Homeloan />
              </React.Fragment>
            }
          />
          <Route
            path="/compare"
            element={
              <React.Fragment>
                <Compare />
              </React.Fragment>
            }
          />
          <Route
            path="/sme"
            element={
              <React.Fragment>
                <Sme />
              </React.Fragment>
            }
          />
        </Routes>
        <Preloader />
        {/* <Toast /> */}
        {/* </Container> */}

        <div
          style={{ left: "2rem", position: "relative", zIndex: 9999999999999 }}
        >
          <FloatingWhatsApp
            phoneNumber="+971551251768"
            accountName="Bandenia"
            chatMessage="Hello there! 🤝How we can help you?"
            allowEsc
            allowClickAway
            notification
            notificationSound
            avatar="./assets/images/cropped-logo.png"
            className="noextraheight"
          />
        </div>
      </ToastProvider>
    </Fragment>
  );
}

export default App;
