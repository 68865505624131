import { combineReducers } from 'redux'

import auth from './auth'
import common from './common'
import sidebar from './sidebar'
import progress from './progress'
import profile from './profile'
import income from './income'
import property from './property'
import offers from './offers'
import docs from './docs'
import status from './status'
const rootReducer = combineReducers({
  auth,
  common,
  sidebar,
  progress,
  profile,
  income,
  property,
  offers,
  docs,
  status
})

export default rootReducer
