// import React, { Fragment, useState, useEffect, useRef } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { sidebar_check } from '../actions/sidebar'
// import fetch from '../helpers/methods';
// import {
//   Badge,
//   Button,
// } from 'react-bootstrap';

// // const sideData = [
// //   'profile','income','property','payment','offers','docs','application','approval'
// // ]

// const Sidebar = ({logout}) => {
//   useEffect(() => {
//     fetch ()
//     .then (data => {
//       data=data.data.data
//       console.log('fetchData',data)
//       if (data.name === 'Invalid Session ID') {
//         navigate('/login')
//         return
//       }
//       const name = data?.first_name;
//       const job = data?.job_title;
//       const mortgage_type = data?.mortgage_type;
//       const salary_slip_path = data?.salary1_path;
//       const eid_path = data?.EID1_path;
//       const pass_path = data?.passport1_path;
//       const visa_path = data?.visa1_path;

//       console.log("printing job");
//       console.log(job);
//       if (name!=null && name!=="") {
//         dispatch(sidebar_check('Profile'))
//       }
//       if (job!=null && job!=="") {
//         dispatch(sidebar_check('Income'))
//       }
//       if (mortgage_type!=null && mortgage_type!=="") {
//         dispatch(sidebar_check('Property'))
//         //dispatch(sidebar_check('Calculator'))
//       }

//       if (eid_path || salary_slip_path || pass_path || visa_path) {
//         dispatch(sidebar_check('Documents'))
//       }

//     })
//     }, [])
//   const dispatch = useDispatch()
//   const location = useLocation()
//   const navigate = useNavigate()
//   const sidebar = useRef()
//   const _progressValue = useSelector((state) => state.progress.progressValue)
//   const sideData = useSelector((state) => state.sidebar)
//   // const [sideData, setSideData] = useState(useSelector((state) => state.sidebar))

//   const router = (idx, check = 2, url = '') => {
//     // if(check) {
//       navigate(`/${url}`)
//     // }
//   }

//   const handleClose = () => {
//     sidebar.current.style.width = '0px';
//   }
//   const handleOpen = () => {
//     sidebar.current.style.width = '122px';
//   }

import { useState } from "react";

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => {
    setSidebar(true);
  };
  const hideSidebar = () => {
    setSidebar(false);
  };
  return (
    <div>
      <div
        className={`side-menu ${sidebar ? "show-menu" : "display"}`}
        id="sidebar-menu"
      >
        <img
          src="/assets/images/cropped-logo.png"
          alt=""
          style={{ height: "80px" }}
        />
        <br />
        <ul>
          <li>
            <a class="no-line" href="https://mortgagebcf.com/">
              <span class="material-icons">
                <img
                  src="/assets/images/dashboard.svg"
                  alt=""
                  style={{ height: "15px", width: "15px" }}
                />
              </span>
              Home
            </a>
          </li>

          <li>
            <a class="no-line" href="https://user.mortgagebcf.com/">
              <span class="material-icons">
                <img
                  src="/assets/images/user-2.svg"
                  alt=""
                  style={{ height: "15px", width: "15px" }}
                />
              </span>
              My Profile
            </a>
          </li>
          <li>
            <a class="no-line" href="https://mortgagebcf.com/our-team">
              <span class="material-icons">
                <img
                  src="/assets/images/user-2.svg"
                  alt=""
                  style={{ height: "15px", width: "15px" }}
                />
              </span>
              Our Team
            </a>
          </li>
          {/* <li>
                    <a class="no-line" href="https://user.mortgagebcf.com/">
                    	<span class="material-icons"><img src="/assets/images/calculator.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Calculator
                    </a>
                </li> */}
          <li>
            <a class="no-line" href="https://mortgagebcf.com/compare">
              <span class="material-icons">
                <img
                  src="/assets/images/status.svg"
                  alt=""
                  style={{ height: "15px", width: "15px" }}
                />
              </span>
              Compare Mortgage
            </a>
          </li>
          <li>
            <a class="no-line" href="https://user.mortgagebcf.com/">
              <span class="material-icons">
                <img
                  src="/assets/images/status.svg"
                  alt=""
                  style={{ height: "15px", width: "15px" }}
                />
              </span>
              Status
            </a>
          </li>

          <li>
            <a class="no-line" href="https://user.mortgagebcf.com/">
              <span class="material-icons">
                <img
                  src="/assets/images/setting.svg"
                  alt=""
                  style={{ height: "15px", width: "15px" }}
                />
              </span>
              Profile Settings
            </a>
          </li>
          {/* <li>
                    <a class="no-line" href="https://user.mortgagebcf.com/">
                    	<span class="material-icons"><img src="/assets/images/share.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Social Media
                    </a>
                </li> */}
        </ul>
        <div class="close-btn" onClick={hideSidebar}>
          <span class="material-icons">
            <img
              src="/assets/images/close.svg"
              alt=""
              style={{ height: "24px", width: "24px" }}
            />
          </span>
        </div>
        <a
          class="sidebar-logout no-line main-default-btn2"
          style={{ height: "60px" }}
          href="https://broker.mortgagebcf.com/signup"
        >
          <span>
            <img
              src="/assets/images/logout.svg"
              alt=""
              style={{ width: "24px" }}
            />
          </span>
          Sign Up as Partner
        </a>
      </div>
      <div
        class="main-navbar"
        style={{ backgroundColor: "white", color: "black" }}
      >
        <nav
          class="navbar navbar-style-two navbar-expand-lg navbar-light"
          style={{ backgroundColor: "white" }}
        >
          <div class="container-fluid">
            <div class="logo">
              <button
                onClick={showSidebar}
                class="navbar-toggler2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <a href="https://mortgagebcf.com/">
                <img
                  src="/assets/images/cropped-logo.png"
                  className="white-logo"
                  alt="Logo"
                  style={{ height: "70px" }}
                />
              </a>
            </div>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav"></ul>

              <div class="others-option">
                <div class="d-flex align-items-center">
                  <div class="option-item">
                    <a
                      href="tel:+971 42014000"
                      class="call-btn"
                      style={{ color: "black" }}
                    >
                      Call Us: +971 42014000
                    </a>
                  </div>

                  <div class="option-item">
                    <a
                      href="https://broker.mortgagebcf.com/login"
                      class="sign-up"
                    >
                      Sign In as Partner{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>

    // <Fragment>
    //   {/* <div className='sidebar-show' onClick={handleOpen}>
    //     <img src='/assets/images/sidemenu2.png'/>
    //   </div> */}
    //   <div ref={sidebar} className='sidebar-container'>
    //     <div className='sidebar-wrapper'>
    //       <div className={` home-link ${location.pathname === '/Overview' ? 'white-bg' : ''}`} onClick={()=>{router(1, true, 'Overview')}}>
    //         <div>
    //           <img src='/assets/images/home.svg'  style={{ width: '22px'}}/>
    //         </div>
    //       </div>
    //       <div style={{ fontSize: '11px', color: '#666666' }}>Steps:</div>
    //       {
    //         sideData.map((item, idx) => {
    //           return (
    //             <div key={idx} className={`item ${`/${item.name}` === location.pathname ? 'white-bg' : ''}`} onClick={()=> {router(idx+2, item.check, item.name)}}>
    //               <img
    //                 src={item.imgurl}
    //                 className={`${item.check ? 'imgOp' : ''}`}
    //                 style={{ width: '22px'}}/>
    //               <div className={`${item.check ? 'colorOp' : ''}`}>
    //                 {item.name}
    //               </div>
    //               {item.check ?
    //                 <img
    //                   className='check-img'
    //                   src='/assets/images/check-circle-1.2f883aa2.svg'
    //                 />
    //                 : <></>
    //               }
    //             </div>
    //           )
    //         })
    //       }

    //       <div className='log-out-img' onClick={()=>logout()}>
    //         <img src='/assets/images/log-out.svg'/>
    //       </div>
    //     </div>
    //     {/* <div className='sub-text'>
    //       <img src='/assets/images/persent.svg'/>
    //       <div className='text1'>{_progressValue}%</div>
    //       <div>closer to your home financing</div>
    //     </div> */}
    //     <div className='sidebar-close'>
    //       <img style={{
    //         width: '80%',
    //         borderRadius: '50%',
    //         }} src='/assets/images/left.png'
    //         onClick={handleClose}
    //       />
    //     </div>
    //   </div>

    // </Fragment>
  );
};

export default Sidebar;
