import { useState,useEffect } from 'react'
import { docsAction } from '../actions/docs'
import { statusAction } from '../actions/status'
import { useDispatch, useSelector } from 'react-redux'
import fetch from '../helpers/methods';
import './files/index.css';
import {
  Container,
  Col,
  Card,
  Row,
  Badge,
  Button,
  Modal,
  Form,  Spinner,
  Dropdown,
    Carousel
} from 'react-bootstrap';
import DropZone from './files/dropzone'
import FileTile from './files/fileTile'
import { Link, useNavigate } from 'react-router-dom'
import Header from './Header'
const Docs = ({
  localPass,
  uploadPassFiles,
  handlePassDropFiles,
  handlePassRemove,
  localBank,
  uploadBankFiles,
  handleBankDropFiles,
  handleBankRemove,
  localVisa,
  uploadVisaFiles,
  handleVisaDropFiles,
  handleVisaRemove,
  localEID,
  uploadEIDFiles,
  handleEIDDropFiles,
  handleEIDRemove,
  localSalary,
  uploadSalaryFiles,
  handleSalaryDropFiles,
  handleSalaryRemove,
  SalaryBlock,
  VisaBlock,
  passBlock,
  bankBlock,
  EIDBlock,
  setLocalPass,
  setLocalBank,
  setLocalEID,
  setLocalVisa,
  setLocalSalary,

  localOfferLetter,
  uploadOfferLetterFiles,
  handleOfferLetterDropFiles,
  handleOfferLetterRemove,
  setLocalOfferLetter,
  offerLetterBlock,

  localMOA,
  uploadMOAFiles,
  handleMOADropFiles,
  handleMOARemove,
  setLocalMOA,
  MOABlock,

  localAudit,
  uploadAuditFiles,
  handleAuditDropFiles,
  handleAuditRemove,
  setLocalAudit,
  auditBlock,

  localCredit,
  uploadCreditFiles,
  handleCreditDropFiles,
  handleCreditRemove,
  setLocalCredit,
  creditBlock,

  localTrade,
  uploadTradeFiles,
  handleTradeDropFiles,
  handleTradeRemove,
  setLocalTrade,
  tradeBlock,

  localIncome,
  uploadIncomeFiles,
  handleIncomeDropFiles,
  handleIncomeRemove,
  setLocalIncome,
  incomeBlock,

  localShare,
  uploadShareFiles,
  handleShareDropFiles,
  handleShareRemove,
  setLocalShare,
  shareBlock,

  loading,
  setSubmittedCrm,
  handleSubmit,
  submittedCrm,
}) => {
  const [document, setDocument] = useState('');
  const dispatch = useDispatch();
  const [employed, setEmployment] = useState('');
  const[sidebar, setSidebar]=useState(false)
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
	useEffect(() => {		
		fetch()
		.then(data => {
		  data=data.data.data;
		  const passport4_path = data.passport4_path;		
      const visa4_path = data.visa4_path;	 
      const EID4_path = data.EID4_path;	
      const salary4_path = data.salary4_path;	
      const passport1_path = data.passport1_path;		
      const visa1_path = data.visa1_path;	 
      const EID1_path = data.EID1_path;	
      const salary1_path = data.salary1_path;	
      const passport2_path = data.passport2_path;		
      const visa2_path = data.visa2_path;	 
      const EID2_path = data.EID2_path;	
      const salary2_path = data.salary2_path;	
      const passport3_path = data.passport3_path;		
      const visa3_path = data.visa3_path;	 
      const EID3_path = data.EID3_path;	
      const salary3_path = data.salary3_path;	
      const bank2_path = data.bank2_path;	
      const bank4_path = data.bank4_path;	
      const bank1_path = data.bank1_path;	
      const bank3_path = data.bank3_path;	
      const MOA2_path = data.MOA2_path;	
      const MOA4_path = data.MOA4_path;	
      const MOA1_path = data.MOA1_path;	
      const MOA3_path = data.MOA3_path;	
      const trade2_path = data.trade2_path;	
      const trade4_path = data.trade4_path;	
      const trade1_path = data.trade1_path;	
      const trade3_path = data.trade3_path;	
      const share2_path = data.share2_path;	
      const share4_path = data.share4_path;	
      const share1_path = data.share1_path;	
      const share3_path = data.share3_path;	
      const credit2_path = data.credit2_path;	
      const credit4_path = data.credit4_path;	
      const credit1_path = data.credit1_path;	
      const credit3_path = data.credit3_path;	
      const audit2_path = data.audit2_path;	
      const audit4_path = data.audit4_path;	
      const audit1_path = data.audit1_path;	
      const audit3_path = data.audit3_path;	
      const income2_path = data.income2_path;	
      const income4_path = data.income4_path;	
      const income1_path = data.income1_path;	
      const income3_path = data.income3_path;	
      const offerLetter2_path = data.offerLetter2_path;	
      const offerLetter4_path = data.offerLetter4_path;	
      const offerLetter1_path = data.offerLetter1_path;	
      const offerLetter3_path = data.offerLetter3_path;	
      const employment=data.employment_status;
      
		  const payload = {
			  passport4Path:passport4_path,	
        visa4Path:visa4_path,	
        EID4Path:EID4_path,
        salary4Path:salary4_path,
        passport1Path:passport1_path,	
        visa1Path:visa1_path,	
        EID1Path:EID1_path,
        salary1Path:salary1_path,
        passport2Path:passport2_path,	
        visa2Path:visa2_path,	
        EID2Path:EID2_path,
        salary2Path:salary2_path,
        passport3Path:passport3_path,	
        visa3Path:visa3_path,	
        EID3Path:EID3_path,
        salary3Path:salary3_path,
        bank3Path:bank3_path,
        bank2Path:bank2_path,
        bank1Path:bank1_path,
        bank4Path:bank4_path,

        MOA3Path:MOA3_path,
        MOA2Path:MOA2_path,
        MOA1Path:MOA1_path,
        MOA4Path:MOA4_path,
        trade3Path:trade3_path,
        trade2Path:trade2_path,
        trade1Path:trade1_path,
        trade4Path:trade4_path,
        share3Path:share3_path,
        share2Path:share2_path,
        share1Path:share1_path,
        share4Path:share4_path,
        audit3Path:audit3_path,
        audit2Path:audit2_path,
        audit1Path:audit1_path,
        audit4Path:audit4_path,
        credit3Path:credit3_path,
        credit2Path:credit2_path,
        credit1Path:credit1_path,
        credit4Path:credit4_path,
        offerLetter3Path:offerLetter3_path,
        offerLetter2Path:offerLetter2_path,
        offerLetter1Path:offerLetter1_path,
        offerLetter4Path:offerLetter4_path,
        income3Path:income3_path,
        income2Path:income2_path,
        income1Path:income1_path,
        income4Path:income4_path,

        employment:employment,
        submittedCrm:data.submitted_crm
		  }      
		  dispatch(docsAction(payload));      
      setSubmittedCrm(data.submitted_crm);
      setLocalPass(passport1_path);
      setLocalVisa(visa1_path);
      setLocalEID(EID1_path);
      setLocalSalary(salary1_path);
      setLocalBank(bank1_path);
      setLocalMOA(MOA1_path);
      setLocalCredit(credit1_path);
      setLocalAudit(audit1_path);
      setLocalIncome(income1_path);
      setLocalShare(share1_path);
      setLocalOfferLetter(offerLetter1_path);
      setLocalTrade(trade1_path);
      setEmployment(employment);
		}
		)
		}, []) 
	const reduxData = useSelector(state => state.docs) 
	
  const bcf_passport4_path = reduxData.currentUser.passport4Path;
  const bcf_visa4_path = reduxData.currentUser.visa4Path;
  const bcf_EID4_path = reduxData.currentUser.EID4Path;
  const bcf_salary4_path = reduxData.currentUser.salary4Path;
  const bcf_passport1_path = reduxData.currentUser.passport1Path;
  const bcf_visa1_path = reduxData.currentUser.visa1Path;
  const bcf_EID1_path = reduxData.currentUser.EID1Path;
  const bcf_salary1_path = reduxData.currentUser.salary1Path;
  const bcf_passport2_path = reduxData.currentUser.passport2Path;
  const bcf_visa2_path = reduxData.currentUser.visa2Path;
  const bcf_EID2_path = reduxData.currentUser.EID2Path;
  const bcf_salary2_path = reduxData.currentUser.salary2Path;
  const bcf_passport3_path = reduxData.currentUser.passport3Path;
  const bcf_visa3_path = reduxData.currentUser.visa3Path;
  const bcf_EID3_path = reduxData.currentUser.EID3Path;
  const bcf_salary3_path = reduxData.currentUser.salary3Path;
  const bcf_bank3_path = reduxData.currentUser.bank3Path;
  const bcf_bank2_path = reduxData.currentUser.bank2Path;
  const bcf_bank1_path = reduxData.currentUser.bank1Path;
  const bcf_bank4_path = reduxData.currentUser.bank4Path;

  const bcf_MOA3_path = reduxData.currentUser.MOA3Path;
  const bcf_MOA2_path = reduxData.currentUser.MOA2Path;
  const bcf_MOA1_path = reduxData.currentUser.MOA1Path;
  const bcf_MOA4_path = reduxData.currentUser.MOA4Path;
  const bcf_trade3_path = reduxData.currentUser.trade3Path;
  const bcf_trade2_path = reduxData.currentUser.trade2Path;
  const bcf_trade1_path = reduxData.currentUser.trade1Path;
  const bcf_trade4_path = reduxData.currentUser.trade4Path;
  const bcf_income3_path = reduxData.currentUser.income3Path;
  const bcf_income2_path = reduxData.currentUser.income2Path;
  const bcf_income1_path = reduxData.currentUser.income1Path;
  const bcf_income4_path = reduxData.currentUser.income4Path;
  const bcf_share3_path = reduxData.currentUser.share3Path;
  const bcf_share2_path = reduxData.currentUser.share2Path;
  const bcf_share1_path = reduxData.currentUser.share1Path;
  const bcf_share4_path = reduxData.currentUser.share4Path;
  const bcf_audit3_path = reduxData.currentUser.audit3Path;
  const bcf_audit2_path = reduxData.currentUser.audit2Path;
  const bcf_audit1_path = reduxData.currentUser.audit1Path;
  const bcf_audit4_path = reduxData.currentUser.audit4Path;
  const bcf_credit3_path = reduxData.currentUser.credit3Path;
  const bcf_credit2_path = reduxData.currentUser.credit2Path;
  const bcf_credit1_path = reduxData.currentUser.credit1Path;
  const bcf_credit4_path = reduxData.currentUser.credit4Path;
  const bcf_offerLetter3_path = reduxData.currentUser.offerLetter3Path;
  const bcf_offerLetter2_path = reduxData.currentUser.offerLetter2Path;
  const bcf_offerLetter1_path = reduxData.currentUser.offerLetter1Path;
  const bcf_offerLetter4_path = reduxData.currentUser.offerLetter4Path;
  
  const bcf_submitted_to_crm = reduxData.currentUser.submittedCrm;
  console.log(bcf_submitted_to_crm);
  console.log(employed)
  return (
    <body class={`${sidebar ? 'overlay-body' : ''}`} style={{width:'100%'}}>
    
   <Header/>
 
   <div class="home">

    <Container fluid className='app-container'>
      <div className='pb-5' style={{paddingTop:'130px', marginBottom:'60px'}}>
        <h1 style={{
            fontFamily: 'AGaramond-regular',
            fontSize: '64px',
            letterSpacing: '-5px',
          }}
          >Documents</h1>
          <p>
            Easily upload all the documents required by the bank directly inside the Huspy Portal. 
            See what you need to do below for a smooth and speedy submission.
          </p>
          <div style={{boxShadow: '0px 0px 10px grey',
        borderRadius: `5px`,padding:'15px' , paddingBottom:'25px'}}>
          <Form.Label>Select Document to upload</Form.Label>
          {employed === "Employee at a company" ?
                  <Form.Select
                    value={document}
                    onChange={e => setDocument(e.target.value)} 
                    placeholder='Select an option'
                  >
                    <option>Select an Option</option>
                    <option value='Passport'>Passport</option>
                    <option value='SalarySlip'>Last 6 months pay slip (In case of income variation)</option>
                    <option value='VisaCopy'>VisaCopy </option>
                    <option value='EID'>EID</option>
                    <option value='BankStatement'>Last 6 months bank statement</option>
                    <option value='loan details'>Existing liability details -offer letter copy to ascertain the loan details.</option>
                    <option value='Source of income'>Proof for any additional income (rent, spouse income, etc)</option>
                    <option value='Credit card'>Latest credit card statement copy.</option>
                  </Form.Select>
                  :
                  <></>
                  }
                  {employed === "Self-employed" ?
                  <Form.Select
                    value={document}
                    onChange={e => setDocument(e.target.value)} 
                    placeholder='Select an option'
                  >
                    <option>Select an Option</option>
                    <option value='Passport'>Passport</option>
                    <option value='VisaCopy'>VisaCopy </option>
                    <option value='EID'>EID</option>
                    <option value='Trade License'>Trade License</option>
                    <option value='MOA & AOA'>MOA & AOA</option>
                    <option value='BankStatement'>Last 6 months bank statement</option>
                    <option value='Share holding pattern'>Share holding pattern</option>
                    <option value='Audit'>Audit Financial for last 2 years(if Available/ Optional)</option>
                    <option value='Source of income'>Proof for any additional source of income( rental income, spouse income, etc)</option>
                    <option value='loan details'>Existing liability details -offer letter copy to ascertain the loan details.</option>
                    <option value='Credit card'>Latest credit card statement copy</option>
                  </Form.Select>
                  :
                  <></>
                  }
          <Row>
          {document === 'Passport' ?
          <Row>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Col lg={4}>
              <Card className='striped-tabled-with-hover mt-5 py-3 px-3 ' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px',}}>
              <Card.Body style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
                 <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  
                   <Card.Subtitle >Passport</Card.Subtitle>
              </div>
              {
                localPass !== '' && localPass ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :   
                  <DropZone files={uploadPassFiles}  onDropFiles={(file) => handlePassDropFiles( file)}  />
              }
              </Card.Body>
              </Card>
             
            </Col>
            </div>
        
         </Row>
            :
            <></>
                }
                 {document === 'Trade License' ?
          <Row>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Col lg={4}>
              <Card className='striped-tabled-with-hover mt-5 py-3 px-3 ' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px',}}>
              <Card.Body style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
                 <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  
                   <Card.Subtitle >Trade License</Card.Subtitle>
              </div>
              {
                localTrade !== '' && localTrade ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :   
                  <DropZone files={uploadTradeFiles}  onDropFiles={(file) => handleTradeDropFiles( file)}  />
              }
              </Card.Body>
              </Card>
             
            </Col>
            </div>
        
         </Row>
            :
            <></>
                }
                 {document === 'Source of income' ?
          <Row>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Col lg={4}>
              <Card className='striped-tabled-with-hover mt-5 py-3 px-3 ' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px',}}>
              <Card.Body style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
                 <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  
                   <Card.Subtitle >Source of Income</Card.Subtitle>
              </div>
              {
                localIncome !== '' && localIncome ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :   
                  <DropZone files={uploadIncomeFiles}  onDropFiles={(file) => handleIncomeDropFiles( file)}  />
              }
              </Card.Body>
              </Card>
             
            </Col>
            </div>
        
         </Row>
            :
            <></>
                }
                 {document === 'loan details' ?
          <Row>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Col lg={4}>
              <Card className='striped-tabled-with-hover mt-5 py-3 px-3 ' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px',}}>
              <Card.Body style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
                 <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  
                   <Card.Subtitle >Offer Letter Copy</Card.Subtitle>
              </div>
              {
                localOfferLetter !== '' && localOfferLetter ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :   
                  <DropZone files={uploadOfferLetterFiles}  onDropFiles={(file) => handleOfferLetterDropFiles( file)}  />
              }
              </Card.Body>
              </Card>
             
            </Col>
            </div>
        
         </Row>
            :
            <></>
                }
                 {document === 'Credit card' ?
          <Row>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Col lg={4}>
              <Card className='striped-tabled-with-hover mt-5 py-3 px-3 ' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px',}}>
              <Card.Body style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
                 <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  
                   <Card.Subtitle >Credit Card Statement Copy</Card.Subtitle>
              </div>
              {
                localCredit !== '' && localCredit ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :   
                  <DropZone files={uploadCreditFiles}  onDropFiles={(file) => handleCreditDropFiles( file)}  />
              }
              </Card.Body>
              </Card>
             
            </Col>
            </div>
        
         </Row>
            :
            <></>
                }
                 {document === 'Share holding pattern' ?
          <Row>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Col lg={4}>
              <Card className='striped-tabled-with-hover mt-5 py-3 px-3 ' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px',}}>
              <Card.Body style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
                 <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  
                   <Card.Subtitle >Share holding pattern</Card.Subtitle>
              </div>
              {
                localShare !== '' && localShare ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :   
                  <DropZone files={uploadShareFiles}  onDropFiles={(file) => handleShareDropFiles( file)}  />
              }
              </Card.Body>
              </Card>
             
            </Col>
            </div>
        
         </Row>
            :
            <></>
                }
                 {document === 'Audit' ?
          <Row>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Col lg={4}>
              <Card className='striped-tabled-with-hover mt-5 py-3 px-3 ' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px',}}>
              <Card.Body style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
                 <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  
                   <Card.Subtitle >Audit Financial</Card.Subtitle>
              </div>
              {
                localAudit !== '' && localAudit ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :   
                  <DropZone files={uploadAuditFiles}  onDropFiles={(file) => handleAuditDropFiles( file)}  />
              }
              </Card.Body>
              </Card>
             
            </Col>
            </div>
        
         </Row>
            :
            <></>
                }
                 {document === 'MOA & AOA' ?
          <Row>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Col lg={4}>
              <Card className='striped-tabled-with-hover mt-5 py-3 px-3 ' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px',}}>
              <Card.Body style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
                 <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  
                   <Card.Subtitle >MOA & AOA</Card.Subtitle>
              </div>
              {
                localMOA !== '' && localMOA ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :   
                  <DropZone files={uploadMOAFiles}  onDropFiles={(file) => handleMOADropFiles( file)}  />
              }
              </Card.Body>
              </Card>
             
            </Col>
            </div>
        
         </Row>
            :
            <></>
                }


            {document === 'BankStatement' ?
          <Row>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Col lg={4}>
              <Card className='striped-tabled-with-hover mt-5 py-3 px-3 ' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px',}}>
              <Card.Body style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
                 <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  
                   <Card.Subtitle >Bank Statement</Card.Subtitle>
              </div>
              {
                localBank !== '' && localBank ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :   
                  <DropZone files={uploadBankFiles}  onDropFiles={(file) => handleBankDropFiles( file)}  />
              }
              </Card.Body>
              </Card>
             
            </Col>
            </div>
        
         </Row>
            :
            <></>
                }

        {document === 'SalarySlip' ?
                <Row>
                  <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Col lg={4}>
              <Card className='striped-tabled-with-hover mt-5 py-3 px-3 ' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px'}}>
               <Card.Body style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
                 <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                   <Card.Subtitle >Last 6 months pay slip</Card.Subtitle>
              </div>
              {
                localSalary !== '' && localSalary ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :   
                  <DropZone files={uploadSalaryFiles} onDropFiles={handleSalaryDropFiles} />
               } 
              </Card.Body>
              </Card>
            </Col>
            </div>
           
         </Row>
            :
            <></>
                }

                {document === 'VisaCopy' ?
                <Row>
                  <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Col lg={4}>
              <Card className='striped-tabled-with-hover mt-5 py-3 px-3 ' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px'}}>
               <Card.Body style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
                 <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                   <Card.Subtitle >Visa Copy</Card.Subtitle>
              </div>
              {
                localVisa !== '' && localVisa ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :   
                  <DropZone files={uploadVisaFiles} onDropFiles={handleVisaDropFiles} />
               }
              </Card.Body>
              </Card>
            </Col>
            </div>
           
         </Row>
            :
            <></>
                }
                {document === 'EID' ?
                <Row>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Col lg={4}>
              <Card className='striped-tabled-with-hover mt-5 py-3 px-3 ' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px'}}>
               <Card.Body style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
                 <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                   <Card.Subtitle >EID (Emirates ID) copy</Card.Subtitle>
              </div>
              {
                localEID !== '' && localEID ? 
                <div className="drop-area">
                  <label className="select-panel">
                    {"File is already selected."} 
                  </label>
                </div>
                :   
                  <DropZone files={uploadEIDFiles} onDropFiles={handleEIDDropFiles} />
                }
              </Card.Body>
              </Card>
            </Col>
            </div>
            {/* <Col>
              <Card className='striped-tabled-with-hover mt-5 py-3 px-3 ' style={{borderRadius:'20px',boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px', height:'300px'}}>
                <div className='d-flex justify-content-start'>
                  <Badge pill bg="secondary">Required</Badge>{' '}
                </div>
                <Card.Img variant="top" src="/assets/images/common-file-text-info.4b1bedfc.svg" style={{width:'32px',margin:'auto'}} />
                <Card.Body style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between'}}>
                  <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <Card.Title >EID (Emirates ID) copy</Card.Title>
                    <Card.Subtitle >EID (Emirates ID) Copy</Card.Subtitle>
                    {bcf_EID2_path && <p className='f-flex justify-content-center mt-3'>{bcf_EID2_path.split("/")[1]}</p>}
                    {!bcf_EID2_path && EID2Name && <p className='f-flex justify-content-center mt-3'>{EID2Name.split("/")[1]}</p>}  
                    {!EID2Name&&EID2.name && <p className='f-flex justify-content-center mt-3'>{EID2.name}</p>}
                  </div>
                  {
                  EID2.name
									? 
									<Button disabled={bcf_EID2_path} variant='outline-dark' style={{borderRadius:'20px'}} onClick={handle_EID2_Upload}>Submit</Button>
									:
									<Button disabled={bcf_EID2_path} variant='outline-dark' style={{borderRadius:'20px'}} onClick={handle_EID2_Modal}>Upload</Button>
                  }
                </Card.Body>
              </Card>
            </Col>
           
           */}
            </Row>
            :
            <></>
                }
          </Row> 
          <Row style={{marginTop:'20px'}}>
         
               <div>
               <p className={`${passBlock ? '' : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
                  Passport
                </p>  
                {
                localPass !== '' && localPass ? 
                    <div>
                       <span style={{width:'100%' , fontWeight:'bold'}}> Passport:</span> <br/>
                      <span style={{ width: '100%' }} >{localPass} </span> <br/>
                      {
                        bcf_passport2_path ?
                        <span> {bcf_passport2_path}</span> 
                        
                        :<></>
                      }
                      <br/>
                      {
                        bcf_passport3_path ?
                        <span> {bcf_passport3_path}</span> 
                        :<></>
                      }
                       <br/>
                      {
                        bcf_passport4_path ?
                        <span> {bcf_passport4_path}</span>
                        :<></>
                      }
                       <br/>
                      </div>
                  :
                  uploadPassFiles.map((f, i) =>
                    <FileTile key={i} file={f?.file} onRemove={handlePassRemove}/>
                  )
              } 
              </div>
              {employed === "Self-employed" ?
              <div>
               <p className={`${shareBlock ? '' : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
                 Share Holding pattern
                </p>  
                {
                localShare !== '' && localShare ? 
                    <div>
                       <span style={{width:'100%' , fontWeight:'bold'}}> Share Holding pattern:</span> <br/>
                      <span style={{ width: '100%' }} >{localShare} </span> <br/>
                      {
                        bcf_share2_path ?
                        <span> {bcf_share2_path}</span> 
                        
                        :<></>
                      }
                      <br/>
                      {
                        bcf_share3_path ?
                        <span> {bcf_share3_path}</span> 
                        :<></>
                      }
                       <br/>
                      {
                        bcf_share4_path ?
                        <span> {bcf_share4_path}</span>
                        :<></>
                      }
                       <br/>
                      </div>
                  :
                  uploadShareFiles.map((f, i) =>
                    <FileTile key={i} file={f?.file} onRemove={handleShareRemove}/>
                  )
              } 
              </div>
              :
              <></>
            }

              <div>
               <p className={`${creditBlock ? '' : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
               Credit Card Copy
                </p>  
                {
                localCredit !== '' && localCredit ? 
                    <div>
                       <span style={{width:'100%' , fontWeight:'bold'}}>  Credit Card Copy:</span> <br/>
                      <span style={{ width: '100%' }} >{localCredit} </span> <br/>
                      {
                        bcf_credit2_path ?
                        <span> {bcf_credit2_path}</span> 
                        
                        :<></>
                      }
                      <br/>
                      {
                        bcf_credit3_path ?
                        <span> {bcf_credit3_path}</span> 
                        :<></>
                      }
                       <br/>
                      {
                        bcf_credit4_path ?
                        <span> {bcf_credit4_path}</span>
                        :<></>
                      }
                       <br/>
                      </div>
                  :
                  uploadCreditFiles.map((f, i) =>
                    <FileTile key={i} file={f?.file} onRemove={handleCreditRemove}/>
                  )
              } 
              </div>
              {employed === "Self-employed" ?
              <div>
               <p className={`${auditBlock ? '' : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
                Audit Financial
                </p>  
                {
                localAudit !== '' && localAudit ? 
                    <div>
                       <span style={{width:'100%' , fontWeight:'bold'}}> Audit Financial:</span> <br/>
                      <span style={{ width: '100%' }} >{localAudit} </span> <br/>
                      {
                        bcf_audit2_path ?
                        <span> {bcf_audit2_path}</span> 
                        
                        :<></>
                      }
                      <br/>
                      {
                        bcf_audit3_path ?
                        <span> {bcf_audit3_path}</span> 
                        :<></>
                      }
                       <br/>
                      {
                        bcf_audit4_path ?
                        <span> {bcf_audit4_path}</span>
                        :<></>
                      }
                       <br/>
                      </div>
                  :
                  uploadAuditFiles.map((f, i) =>
                    <FileTile key={i} file={f?.file} onRemove={handleAuditRemove}/>
                  )
              } 
              </div>
              :
              <></>
            }
              <div>
               <p className={`${offerLetterBlock ? '' : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
                 Offer Letter
                </p>  
                {
                localOfferLetter !== '' && localOfferLetter ? 
                    <div>
                       <span style={{width:'100%' , fontWeight:'bold'}}> Offer Letter:</span> <br/>
                      <span style={{ width: '100%' }} >{localOfferLetter} </span> <br/>
                      {
                        bcf_offerLetter2_path ?
                        <span> {bcf_offerLetter2_path}</span> 
                        
                        :<></>
                      }
                      <br/>
                      {
                        bcf_offerLetter3_path ?
                        <span> {bcf_offerLetter3_path}</span> 
                        :<></>
                      }
                       <br/>
                      {
                        bcf_offerLetter4_path ?
                        <span> {bcf_offerLetter4_path}</span>
                        :<></>
                      }
                       <br/>
                      </div>
                  :
                  uploadOfferLetterFiles.map((f, i) =>
                    <FileTile key={i} file={f?.file} onRemove={handleOfferLetterRemove}/>
                  )
              } 
              </div>
              {employed === "Self-employed" ?
              <div>
               <p className={`${MOABlock ? '' : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
                 MOA & AOA
                </p>  
                {
                localMOA !== '' && localMOA ? 
                    <div>
                       <span style={{width:'100%' , fontWeight:'bold'}}> MOA & AOA:</span> <br/>
                      <span style={{ width: '100%' }} >{localMOA} </span> <br/>
                      {
                        bcf_MOA2_path ?
                        <span> {bcf_MOA2_path}</span> 
                        
                        :<></>
                      }
                      <br/>
                      {
                        bcf_MOA3_path ?
                        <span> {bcf_MOA3_path}</span> 
                        :<></>
                      }
                       <br/>
                      {
                        bcf_MOA4_path ?
                        <span> {bcf_MOA4_path}</span>
                        :<></>
                      }
                       <br/>
                      </div>
                  :
                  uploadMOAFiles.map((f, i) =>
                    <FileTile key={i} file={f?.file} onRemove={handleMOARemove}/>
                  )
              } 
              </div>
              :
              <></>
            }
              <div>
               <p className={`${incomeBlock ? '' : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
               Source of Income
                </p>  
                {
                localIncome !== '' && localIncome ? 
                    <div>
                       <span style={{width:'100%' , fontWeight:'bold'}}>  Source of Income:</span> <br/>
                      <span style={{ width: '100%' }} >{localIncome} </span> <br/>
                      {
                        bcf_income2_path ?
                        <span> {bcf_income2_path}</span> 
                        
                        :<></>
                      }
                      <br/>
                      {
                        bcf_income3_path ?
                        <span> {bcf_income3_path}</span> 
                        :<></>
                      }
                       <br/>
                      {
                        bcf_income4_path ?
                        <span> {bcf_income4_path}</span>
                        :<></>
                      }
                       <br/>
                      </div>
                  :
                  uploadIncomeFiles.map((f, i) =>
                    <FileTile key={i} file={f?.file} onRemove={handleIncomeRemove}/>
                  )
              } 
              </div>
              {employed === "Self-employed" ?
              <div>
               <p className={`${tradeBlock ? '' : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
                 Trade License
                </p>  
                {
                localTrade !== '' && localTrade ? 
                    <div>
                       <span style={{width:'100%' , fontWeight:'bold'}}> Trade License:</span> <br/>
                      <span style={{ width: '100%' }} >{localTrade} </span> <br/>
                      {
                        bcf_trade2_path ?
                        <span> {bcf_trade2_path}</span> 
                        
                        :<></>
                      }
                      <br/>
                      {
                        bcf_trade3_path ?
                        <span> {bcf_trade3_path}</span> 
                        :<></>
                      }
                       <br/>
                      {
                        bcf_trade4_path ?
                        <span> {bcf_trade4_path}</span>
                        :<></>
                      }
                       <br/>
                      </div>
                  :
                  uploadTradeFiles.map((f, i) =>
                    <FileTile key={i} file={f?.file} onRemove={handleTradeRemove}/>
                  )
              } 
              </div>
              :
              <></>
            }
              <div>
               <p className={`${bankBlock ? '' : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
               Last 6 months bank statement
                </p>  
                {
                localBank !== '' && localBank ? 
                    <div>
                       <span style={{width:'100%' , fontWeight:'bold'}}> Last 6 months bank statement:</span> <br/>
                      <span style={{ width: '100%' }} >{localBank} </span> <br/>
                      {
                        bcf_bank2_path ?
                        <span> {bcf_bank2_path}</span> 
                        
                        :<></>
                      }
                      <br/>
                      {
                        bcf_bank3_path ?
                        <span> {bcf_bank3_path}</span> 
                        :<></>
                      }
                       <br/>
                      {
                        bcf_bank4_path ?
                        <span> {bcf_bank4_path}</span>
                        :<></>
                      }
                       <br/>
                      </div>
                  :
                  uploadBankFiles.map((f, i) =>
                    <FileTile key={i} file={f?.file} onRemove={handleBankRemove}/>
                  )
              } 
              </div>
              <div> 
              <p className={`${EIDBlock ? '' : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
                 EID
                </p>  
                {
                localEID !== '' && localEID ? 
                
                <div>
                   <span style={{width:'100%' , fontWeight:'bold'}}> EID:</span> <br/>
                  <span style={{ width: '100%' }} >{localEID} </span> <br/>
                  {
                      bcf_EID2_path ?
                      <p> {bcf_EID2_path}</p>
                      :<></>
                    }
                    {
                      bcf_EID3_path ?
                      <p> {bcf_EID3_path}</p>
                      :<></>
                    }
                    {
                      bcf_EID4_path ?
                      <p> {bcf_EID4_path}</p>
                      :<></>
                    }
                 </div>
                  :
                  uploadEIDFiles.map((f, i ) =>
                    <FileTile key={i} file={f?.file} onRemove={handleEIDRemove} />
                  )
              } 
              
              
              </div>
               <div> 
               <p className={`${VisaBlock ? '' : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
                 Visa Copy
                </p>  
                {
                localVisa !== '' && localVisa ? 
                    <div>
                       <span style={{width:'100%', fontWeight:'bold'}}> Visa :</span> <br/>
                      <span style={{ width: '100%' }} >{localVisa} </span>
                      {
                        bcf_visa2_path ?
                        <p> {bcf_visa2_path}</p>
                        :<></>
                      }
                      {
                        bcf_visa3_path ?
                        <p> {bcf_visa3_path}</p>
                        :<></>
                      }
                      {
                        bcf_visa4_path ?
                        <p> {bcf_visa4_path}</p>
                        :<></>
                      }
                    </div>
                  :
                  uploadVisaFiles.map((f, i) =>
                    <FileTile key={i} file={f?.file} onRemove={handleVisaRemove}/>
                  )
              } 
              
              
              </div>
              { employed === "Employee at a company" ? 
              <div> 
          <p className={`${SalaryBlock ? '' : 'display'}`} style={{width:'100%', fontWeight:'bold'}}>
                  Last 6 months pay slip
                </p>  
              {
                localSalary !== '' && localSalary ? 
                    <div>
                      <span style={{width:'100%' , fontWeight:'bold'}}> Last 6 months pay slip:</span> <br/>
                      <span style={{ width: '100%' }} > {localSalary} </span>
                      {
                        bcf_salary2_path ?
                        <p> {bcf_salary2_path}</p>
                        :<></>
                      }
                      {
                        bcf_salary3_path ?
                        <p> {bcf_salary3_path}</p>
                        :<></>
                      }
                      {
                        bcf_salary4_path ?
                        <p> {bcf_salary4_path}</p>
                        :<></>
                      }
                    </div>
                  :
                
                  uploadSalaryFiles.map((f, i) =>  
                    <FileTile key={i} file={f?.file} onRemove={handleSalaryRemove}/>
                  ) 
              }
               </div>
                : <></>
                }

          </Row>
          </div>
          
          {/* <Modal show={EID2_Modal} onHide={handle_Close_EID2}>
            <Modal.Header closeButton>
              <Modal.Title style={{display:'flex', gap:'10px', alignItems:'center'}}>
                EID (Emirates ID) Copy
                <Badge pill bg="secondary" style={{display:'flex', justifyContent:'center', alignItems:'center', height:'30px'}}>Required</Badge>{' '}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Please keep in mind that we need a document that is...
              </p>
              <ul>
                <li>Clear</li>
                <li>Valid(not expired)</li>
                <li>Address Page(if available)</li>
              </ul>
            </Modal.Body>
            <Modal.Footer style={{border:'1px solid grey', borderRadius:'20px', margin:'10px', display:'flex', justifyContent:'center'}}>
              <input variant="secondary" name= 'passport' type="file" onChange={(e) => handleEID2(e)} />
            </Modal.Footer>
          </Modal>
        */}
          <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', justifyContent:'space-between', marginTop:40 , marginRight:30 }}>
          {
            bcf_submitted_to_crm
          ?
          <Button variant='outline-dark' size="lg" style={{borderRadius:'20px'}}  onClick={async () => { await handleSubmit() }}> {loading && <Spinner animation='border' />}
          &nbsp;&nbsp;&nbsp;Submit</Button>            
          :
          uploadPassFiles &&
          <Link to='/Status'>
                <Button variant='outline-dark' size="lg" style={{borderRadius:'20px'}} onClick={async () => { await handleSubmit() }}
                   > {loading && <Spinner animation='border' />}
                   &nbsp;&nbsp;&nbsp;Submit</Button>
            </Link>
                
          }
          </div>
      </div>
    </Container>
    </div>
    </body>
  )
}

export default Docs