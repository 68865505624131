import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Card, 
  Dropdown,
  Carousel
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { putData } from '../helpers/methods'
import './style/profile.css';
import { sidebar_check } from '../actions/sidebar'
import { progress_value } from '../actions/progress'
import { useEffect } from 'react'
import { propertyAction } from '../actions/property'
import fetch from '../helpers/methods'
import Sidebar from './Sidebar'
import Header from './Header'
const Property = ({
  propertyRadio,
  setPropertyRadio,
  mortgageRadio,
  setMortgageRadio,
  property,
  setProperty,
  lending,
  setLending,
  propertyPrice,
  setPropertyPrice,
  mortgageType,
  setMortgageType,
  handleData
}) => {
  const dispatch = useDispatch()
  const [localPropertyRadio, setLocalPropertyRadio] = useState(null)
  const [localPropertyPrice,setLocalPropertyPrice] = useState(null)
  const [localPropertyType, setLocalPropertyType] = useState('')
  const [localMortgageType, setLocalMortgage_type] = useState('')
  const [localLendingType, setLocalLendingType] = useState('')
  const [localMortgageRadio, setLocalMortgageRadio] = useState(null)
  const [loading , setLoading]=useState(false)
  const[sidebar, setSidebar]=useState(false)
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
  useEffect(() => {
    setLoading(true)
    fetch()
    .then(data => {
      data=data.data.data;
      const mind_property_c = data.mind_property;
      const mind_property_type_c = data.mind_property_type;
      const mind_property_price_c = data.mind_property_price;
      const mortgage_type_c = data.mortgage_type;
      const lending_type_c = data.lending_type;
      const fee_financing_c = data.fee_financing;
      
      const payload = {
        mind_property:mind_property_c,
        mind_property_type:mind_property_type_c,
        mind_property_price:mind_property_price_c,
        mortgage_type:mortgage_type_c,
        lending_type:lending_type_c,
        fee_financing:fee_financing_c        
      }

      dispatch(propertyAction(payload))
      setLocalPropertyRadio(mind_property_c)
      setLocalPropertyPrice(mind_property_price_c) 
      setLocalPropertyType(mind_property_type_c)           
      setLocalMortgage_type(mortgage_type_c)
      setLocalLendingType(lending_type_c)
      setLocalMortgageRadio(fee_financing_c)
    })
    setLoading(false)
  })

  let reduxData = useSelector(state => state.profile)  
  const bcf_mind_property = reduxData.currentUser.mind_property_c;
  const bcf_mind_property_type = reduxData.currentUser.mind_property_type;
  const bcf_mind_property_price = reduxData.currentUser.mind_property_price;
  const bcf_mortgage_type = reduxData.currentUser.mortgage_type;
  const bcf_lending_type = reduxData.currentUser.lending_type;
  const bcf_fee_financing = reduxData.currentUser.fee_financing; 
  console.log(bcf_mind_property_type) 

  return (
    <>
    {
      loading ?
      <div id="preloader"> </div> :
      <div id="preloader" style={{display: 'none'}}></div>
    }
    <body class={`${sidebar ? 'overlay-body' : ''}`} style={{width:'100%'}}>
      
       <Header/>
     
       <div class="home" style={{backgroundColor:'#f4f4f6'}}>
    <Container fluid className='app-container'>
      <div className='pb-5' style={{paddingTop:'120px', marginBottom:'60px'}}>
        <div style={{
            fontFamily: 'AGaramond-regular',
            fontSize: 'calc(45px + 1vw)',
            letterSpacing: '-5px',
            color:'black'
          }}
        >
          Property & Mortgage
        </div>
        <div style={{
          color: 'rgb(102, 102, 102)',
          fontSize: '14px',
          lineHeight: '15px',
          letterSpacing: '0.2px',
        }}
        >
          Provide us with information about the property you’re buying and the type of financing you need.
        </div>
        <Card
          className='striped-tabled-with-hover mt-5 py-5 px-3'
          style={{
            borderRadius:'20px',
            boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px',
            border: '0px',
          }}
        >
          <h1 style={{
              fontFamily: 'AGaramond-regular',
              letterSpacing: '-3px'
            }}
          >About the property</h1>
          <p>
          Have you already identified the property
          </p>
         
            {localPropertyRadio ?
            <div className='d-flex'>
            <Form.Check 
              inline
              type='radio'
              label='Yes'
              value='Yes'
              name='propertyRadio'
              id='radio_1'
              //disabled={localPropertyRadio?true:false}
              checked={localPropertyRadio==='Yes'}
              onChange={(e) => setPropertyRadio(e.target.value)}
            />
            <Form.Check 
              inline
              type='radio'
              label='No'
              name='propertyRadio'
              value='No'
              id='radio_2'
              //disabled={localPropertyRadio?true:false}
              checked={localPropertyRadio==='No'}
              onChange={(e) => setPropertyRadio(e.target.value)}
            />
            </div>
            :
            <div className='d-flex'>
            <Form.Check 
            inline
            type='radio'
            label='Yes'
            value='Yes'
            name='propertyRadio'
            id='radio_1'
            //disabled={localPropertyRadio?true:false}
            //checked={localPropertyRadio==='Yes'}
            onChange={(e) => setPropertyRadio(e.target.value)}
          />
          <Form.Check 
            inline
            type='radio'
            label='No'
            name='propertyRadio'
            value='No'
            id='radio_2'
            //disabled={localPropertyRadio?true:false}
            //checked={localPropertyRadio==='No'}
            onChange={(e) => setPropertyRadio(e.target.value)}
          />
          </div>
          }
         
          <Form>
            <div key='inline-radio' style={{width:'80%'}}>
              <Form.Group className="d-flex justify-content-between align-items-center mt-3 gap-2 mobile-class" controlId="formBasicEmail">
                <div style={{width:'100%'}}>
                  <Form.Label>Approxymate property price</Form.Label>
                  <Form.Control 
                    //disabled={localPropertyPrice ? true : false}
                    type="number" 
                    placeholder="E.g.100,000" 
                    name='property_price'
                    value={localPropertyPrice ? localPropertyPrice : propertyPrice}
                    onChange={(e) => setPropertyPrice(e.target.value)}
                    required
                  />
                </div>
                <div style={{width:'100%'}}> 
                  <Form.Label>Property type</Form.Label>
                  <Form.Select 
                    //disabled={localPropertyType ? true : false}
                    value={localPropertyType ? localPropertyType : property}
                    onChange={(e) => setProperty(e.target.value)}>
                    <option vlaue='Residential'>Residential</option>
                    <option value='Commercial'>Commercial</option>
                    <option value='Others'>Others</option>
                  </Form.Select>
                </div>
              </Form.Group>
            </div>
          </Form>
        </Card>
        <Card
          className='striped-tabled-with-hover mt-5 py-5 px-3'
          style={{
            borderRadius:'20px',
            boxShadow: 'rgb(127 133 148 / 12%) 0px -4px 16px',
            border: '0px',
            }}
          >
          <h1 style={{
              fontFamily: 'AGaramond-regular',
              letterSpacing: '-3px'
            }}
          >About the mortgage</h1>
          <Form>
            <div key='inline-radio' style={{width:'80%'}}>
              <Form.Group className="d-flex justify-content-between align-items-center mt-3 gap-2 property-mobile" controlId="formBasicEmail">
                <div style={{width:'100%'}}>
                  <Form.Label>What kind of mortgage do you need?</Form.Label>
                  <Form.Select 
                    //disabled={localMortgageType ? true : false}
                    value={localMortgageType ? localMortgageType : mortgageType}
                    onChange={(e) => setMortgageType(e.target.value)}
                  >
                    <option value='Buy a property'>Buy a property</option>
                    <option value='Final handover payment'>Final handover payment</option>
                    <option value='Transfer existing mortgate'>Transfer existing mortgate</option>
                    <option value='Cash out property'>Cash out property</option>
                    <option value='Under construction property'>Under construction property</option>
                    <option value='Buy Out plus equity'>Buy Out plus equity</option>
                    <option value='Refinance'>Refinance</option>
                  </Form.Select>
                </div>
                <div style={{width:'100%'}}> 
                  <Form.Label>Lending type</Form.Label>
                  <Form.Select 
                    //disabled={localLendingType ? true : false}
                    value={localLendingType ? localLendingType : lending}
                    onChange={e => setLending(e.target.value)}
                  >
                    <option vlaue='conventional'>Conventional</option>
                    <option value='islamic'>Islamic</option>
                    <option value='best possible rate'>Best possible rate</option>
                  </Form.Select>
                </div>
              </Form.Group>
              <div className='mt-3'>
                <p>Do you want to add fee financing?</p>
                {localMortgageRadio ?
                <div className='d-flex'>
                  <Form.Check 
                    inline
                    type='radio'
                    label='Yes'
                    value='yes'
                    name='mortgageRadio'
                    id='radio_1'
                    //disabled={localMortgageRadio?true:false}
                    checked={localMortgageRadio==='yes'}
                    onChange={(e) => setMortgageRadio(e.target.value)}
                  />
                  <Form.Check 
                    inline
                    type='radio'
                    label='No'
                    name='mortgageRadio'
                    value='no'
                    id='radio_2'
                    //disabled={localMortgageRadio?true:false}
                    checked={localMortgageRadio==='no'}
                    onChange={(e) => setMortgageRadio(e.target.value)}
                  />
                </div>
                :
                <div className='d-flex'>
                  <Form.Check 
                    inline
                    type='radio'
                    label='Yes'
                    value='yes'
                    name='mortgageRadio'
                    id='radio_1'
                    //disabled={localMortgageRadio?true:false}
                    //checked={localMortgageRadio==='yes'}
                    onChange={(e) => setMortgageRadio(e.target.value)}
                  />
                  <Form.Check 
                    inline
                    type='radio'
                    label='No'
                    name='mortgageRadio'
                    value='no'
                    id='radio_2'
                    //disabled={localMortgageRadio?true:false}
                    //checked={localMortgageRadio==='no'}
                    onChange={(e) => setMortgageRadio(e.target.value)}
                  />
                </div>
            }

              </div>
            </div>
          </Form>
        </Card>
        <div className='d-flex justify-content-end mt-5'>
          <Link to='/Documents' style={{
            textDecoration: 'none'
          }}>
            <Button
              className="d-flex justify-content-center align-items-center rounded-4"
              style={{minWidth:'150px'}}
              variant="outline-dark"
              type="submit"
              onClick={()=> {
                dispatch(sidebar_check('Property'));
                dispatch(progress_value(45));
                handleData();
              }}
            >
              Next step
            </Button>
          </Link>
        </div>
      </div>
    </Container>
    </div>
    </body>
    </>
  )
}

export default Property