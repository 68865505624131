import React, { useEffect,useState }  from 'react'
import { Card, Button, Row,Dropdown,
  Carousel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import Sidebar from '../containers/Sidebar'
import fetch from '../helpers/methods'
import Header from './Header'
const alertTexts = [
  'Basic information about your identity and residency status.',
  'Details about your financial situation to help us calculate your loan amount.',
  'Information about the property you’re buying and the type of financing you need.',
  'Calculate your estimated monthly payments based on the loan amount.',
  'See personalized recommendations for the best offers based on the info you provided.',
  'Easily upload all the documents required by the bank.',
  'Review and finalize the application to send to the bank of your choice.',
  'Once the bank issues your pre-approval, you can download it from here.',
]

const FirstView = () => {
  const [progressValue, setProgressValue] = useState(0);

  const _alertData = useSelector((state) => state.sidebar)
  
  const property = useSelector((state) => state.property)
  const bcf_passport_path = property.currentUser.passportPath;
  const bcf_visa_path = property.currentUser.visaPath;
  const bcf_EID_path = property.currentUser.EIDPath;
  const[sidebar, setSidebar]=useState(false)
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }

  useEffect(() => {
    fetch()
		.then(data => {
		  data=data.data.data;      
      let _progressValue=0      
    if (data.first_name) {
      _progressValue+=20
    }  
    if (data.job_title) {
      _progressValue+=20
    }
    if (data.mind_property) {
      _progressValue+=20
    }
    if (data.salary_slip_path) {
      _progressValue+=20
    }
    if (data.passport_path) {
      _progressValue+=10
     }
    if (data.visa_path) {
      _progressValue+=5
    }
    if (data.EID_path) {
      _progressValue+=5
    }
    setProgressValue(_progressValue)
      
    })

  },[]);

  return (
    <body class={`${sidebar ? 'overlay-body' : ''}`} style={{width:'100%'}}>
    <Header/>
  
   <div class="home" style={{backgroundColor:'#f4f4f6', paddingBottom:'40px'}}>

    <div className='welcome_back app-container'>
      <div className='wrapper' style={{paddingTop:'120px'}}>
        <h1 className='h1' style={{fontFamily: 'AGaramond-regular'}}>Welcome back</h1>
        <p style={{ maxWidth: '671px', fontSize: '14px'}}>
          Ready to continue? All your progress has been saved automatically, so you can easily pick up where you left off.</p>
        <div className='center-section'>
          <div className='left-block'>
            <div className='progress' style={{backgroundColor:'white'}}>
            <CircularProgressbarWithChildren value={progressValue} styles={buildStyles({
                  textColor: "red",
                  pathColor: "#00C195",
                })}>
  {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
  <img src='/assets/images/house-heart.1f206df2.svg'/>
  
</CircularProgressbarWithChildren>
              {/* <CircularProgressbarWithChildren
                value={progressValue}
                styles={buildStyles({
                  textColor: "red",
                  pathColor: "#00C195",
                })}
                className='progress-bar'
              >
                <img src='/assets/images/house-heart.1f206df2.svg'/>
              </CircularProgressbarWithChildren> */}
            </div>
            <div className='text-bar'>
              <div className='subtext1'>
                <span>{progressValue}%</span>closer to your Financial Solution
              </div>
              <div className='subtext2'>
                The more you progress, the higher the number gets! Your progress is saved automatically.
              </div>
            </div>
          </div>
          <div className='right-block'>
            {
              _alertData.map((item, idx)=>{
                return (
                  <div key={idx} className='item'>
                    <div className='item-wrapper'>
                      <div className='left-text'>
                        <span  class='icon'>
                        <img
                          src={item.imgurl}
                          //className={`${item.check ? 'checked-img' : 'uncheck-img'}`}
                          style={{ width: '22px'}}/>
                          </span>
                        {item.check ? 
                          <img
                            className={`check-img`}
                            src='/assets/images/check-circle-1.2f883aa2.svg'
                          /> : <></>
                        }
                      </div>
                      <div className='right-text'>
                        <div className='subtext1'>{item.name}</div>
                        <div className='subtext'>{alertTexts[idx]}</div>
                      </div>
                    </div>
                    <Link to={`/${item.name}`} style={{textDecoration:'none'}}>
                      <button className={`${item.check ? 'done' : 'todo'}`}>
                        {
                          item.check ? 'Done' : 'To-do'
                        }
                      </button>
                    </Link>
                  </div>
                )
              })
            }
          </div>
        </div>
        <Row className='d-flex justify-content-end mt-3' style={{float:'right'}}>
          <Link to='/Profile' 
            style={{
              textDecoration: 'none'
            }}>
            <Button variant='primary'
              className="d-flex justify-content-center align-items-center rounded-4"
              style={{minWidth:'150px'}}
            
            >
              Continue
            </Button>
          </Link>
        </Row>
      </div>
    </div>
    </div>
    </body>
  )
}

export default FirstView;