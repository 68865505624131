import {
  SIDEBAR_CHECK
} from "../constants/actionTypes/sidebar"

const INITIAL_STATE = [

  {
    imgurl: '/assets/images/user-2.svg',
    name: 'Profile',
    check: false,
  },
  { 
    imgurl: '/assets/images/revenue.svg',
    name: 'Income',
    check: Boolean(localStorage.getItem('income')) ? Boolean(localStorage.getItem('income')) : false,
  },
  {
    imgurl: '/assets/images/property.svg',
    name: 'Property',
    check: Boolean(localStorage.getItem('property')) ? Boolean(localStorage.getItem('property')) : false,
  },
  
  {
    imgurl: '/assets/images/document.svg',
    name: 'Documents',
    check: Boolean(localStorage.getItem('docs')) ? Boolean(localStorage.getItem('docs')) : false,
  },
  
 
  {
    imgurl: '/assets/images/trophy.svg',
    name: 'Status',
    check: Boolean(localStorage.getItem('approval')) ? Boolean(localStorage.getItem('approval')) : false,
  },
]

const reducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case SIDEBAR_CHECK:
      // localStorage.setItem(action.name, 'true');
      const newState = state.map(item => item.name === action.name ? {...item, check: true} : item)
      return [
        ...newState
      ]
    default:
      return state
  }
}

export default reducer;