
import { useEffect, useState , useRef} from 'react'

  
  //   import {GiHamburgerMenu } from "react-icons/gi";
  import { Link , useNavigate } from 'react-router-dom'
  import Calculator from './Calculator'
  import Header from './Header'
  import $ from 'jquery'
  import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//   import OwlCarousel from 'react-owl-carousel';  
import Footer from './Footer';
//   import 'owl.carousel/dist/assets/owl.carousel.css';  
 
//   import 'owl.carousel/dist/assets/owl.theme.default.css'; 
  //import $ from '../assets/js/bootstrap.bundle.min.js'
 
  
  import '../assets/css/responsive.scss'
//   import { Helmet } from 'react-helmet'
import Findloan from './Findloan';
  const Homeloan = (onChange, value) => {
   
    const[sidebar, setSidebar]=useState(false)
    const navigate=useNavigate();
    const [navClass, setNavClass] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef();
    const handleScroll = () => {
      if (window.scrollY > 120) {
        setNavClass('is-sticky');
      } else {
        setNavClass('');
      }
    };
  
   
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
 const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (selectedOption) => {
    onChange(selectedOption.value);
    setIsOpen(false);
  };
  const options = [
    {value: 1, label: 'Option 1'},
    {value: 2, label: 'Option 2'},
    {value: 3, label: 'Option 3'},
    {value: 4, label: 'Option 3'},
  ];
 const responsive = {
  
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1100 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1100, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2
    },
    smallMobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1
      }
  };
 useEffect(() => {
    $('.loader').fadeOut(1500);
    window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };

      localStorage.getItem(sidebar)
      console.log(sidebar)
    
    });
    
    return (
   
    <body data-spy="scroll" data-offset="70">
        {/* <!-- Preloader --> */}
        <div class="loader">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
        {/* <!-- End Preloader --> */}

       
        <Header/>
        {/* <!-- End Main Navbar Area --> */}

        {/* <!-- Start Main Banner Area --> */}
        <div id="home" class="choice-home-area" style={{marginTop:'40px'}}>
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="choice-home-content">
                            <span class="sub-title">Reliable Sustainable Provider</span>
                            <h1>Get Home From Your Choice</h1>
        
                            <ul class="choice-home-btn">
                                <li>
                                    <a href="#" class="main-default-btn">Mortgage Expert</a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="video-btn popup-youtube"><i class="bx bx-play"></i> <span>Intro With Company</span></a>
                                </li>
                            </ul>
                        </div> 
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="mortgage-calculator-form">
                            <h2>Mortgage calculator</h2>

                            <form>
                                <div class="form-group">
                                    <label>
                                        <i class='bx bx-money'></i>
                                    </label>
                                    <input type="text" class="form-control" placeholder="Purchase price*"/>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <i class='bx bx-dollar-circle'></i>
                                    </label>
                                    <input type="text" class="form-control" placeholder="Estimated monthly*"/>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <i class='bx bxs-badge-dollar'></i>
                                    </label>
                                    <input type="text" class="form-control" placeholder="Down payment*"/>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <i class='bx bx-code-block'></i>
                                    </label>
                                    <input type="text" class="form-control" placeholder="Property ZIP code*"/>
                                </div>
                                <div class="form-group">
                                    <div class="dropdown">
                                        <label>
                                            <i class='bx bxs-check-circle'></i>
                                        </label>
                                        <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Dropdown link
                                        </a>
                                        
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a class="dropdown-item" href="#">Action</a>
                                            <a class="dropdown-item" href="#">Another action</a>
                                            <a class="dropdown-item" href="#">Something else here</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <i class='bx bx-dollar'></i>
                                    </label>
                                    <input type="text" class="form-control" placeholder="Load Amount*"/>
                                </div>
                                <div class="form-group">
                                    <label>
                                        <i class='bx bx-message-square-detail'></i>
                                    </label>
                                    <input type="email" class="form-control" placeholder="Email address*"/>
                                </div>
                                <button type="submit" class="main-default-btn">Calculate Payment</button>
                            </form>
                        </div>

                        <ul class="choice-home-social-links">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <ul class="choice-home-information">
                <li>
                    <span>Call Us:</span> <a href="tel:+971 42014000">+971 42014000</a>
                </li>
                <li>
                    <span>Mail Us:</span> <a href="mailto:support@bcf.ae">support@bcf.ae</a>
                </li>
            </ul>
        </div>
        {/* <!-- End Choice Home Area -->
        
        <!-- Start Information Area --> */}
        <div class="information-area with-bg-black-color pt-100 pb-70">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-sm-6">
                        <div class="information-card">
                            <div class="icon">
                                <i class='bx bx-time'></i>
                            </div>
                            <p>
                                <span>Mon-Fri (8am - 6pm)</span>
                                <span>Sat-Sun (10am - 2pm)</span>
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="information-card">
                            <div class="icon">
                                <i class='bx bxs-contact'></i>
                            </div>
                            <p>
                                <span>Contact us</span>
                                <span><a href="mailto:support@bcf.ae">support@bcf.ae</a></span>
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="information-card">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <p>
                                <span>(24 hours / 7 days)</span>
                                <span><a href="tel:4234543123678">+4 234-543 123 678</a></span>
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="information-card">
                            <div class="icon">
                                <i class='bx bx-world'></i>
                            </div>
                            <p>
                                <span>9675 Grant Ave.</span>
                                <span>Chicago Heights, IL 60411</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Information Area -->

        <!-- Start About Style Area --> */}
        <div id="about" class="about-style-area-with-full-width ptb-100">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-style-full-content">
                            <span class="sub-title">About Us</span>
                            <h3>We Are Fully Dedicated To Support You</h3>
                            <p class="bold">Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vivamus magna justo lacinia eget consectetur.</p>

                            <div class="inner-content">
                                <h4>Support Service</h4>
                                <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                            </div>
                            <div class="inner-content">
                                <h4>Refinance Guide</h4>
                                <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                            </div>
                            <div class="about-btn">
                                <a href="#" class="main-default-btn">Learn More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6">
                                <div class="about-full-image-wrap">
                                    <img src="/assets/images/home2.jpg" alt="image"/>

                                    <div class="counter-box">
                                        <h4>20+</h4>
                                        <span>Years Of Experience</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="about-full-image-wrap">
                                    <img src="/assets/images/home5.jpg" alt="image"/>

                                    <div class="counter-box">
                                        <h4>90%</h4>
                                        <span>Customer Worldwide</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End About Area -->

        <!-- Start Expect Style Area --> */}
        <div class="expect-style-area pt-100 pb-70">
            <div class="container">
                <div class="main-section-title">
                    <span class="sub-title">What’s Your Expect</span>
                    <h2>We Have Offer For You That You Expect</h2>
                </div>

                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="expect-style-card">
                            <h3>Get Mortgage Rate</h3>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                            <a href="#">Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="expect-style-card">
                            <h3>Home Buyer’s Guide</h3>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                            <a href="#">Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="expect-style-card">
                            <h3>Home Equity</h3>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                            <a href="#">Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="expect-style-card">
                            <h3>Refinancing Service</h3>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque auctor sit amet aliquam vel ullamcorper sit amet ligula.</p>
                            <a href="#">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Expect Style Area -->
        
        <!-- Start Fun Fact Style Area --> */}
        <div class="fun-fact-style-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="fun-fact-style-card">
                                    <h3>20</h3>
                                    <p>Years Of Experience</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="fun-fact-style-card bg-E5F9F4">
                                    <h3>90</h3>
                                    <p>Customer Worldwide</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="fun-fact-style-card bg-E5F9F4">
                                    <h3>56</h3>
                                    <p>Volunteers Participated</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="fun-fact-style-card">
                                    <h3>98</h3>
                                    <p>Veteran Home Completed</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="fun-fact-style-content">
                            <span class="sub-title">Our Record</span>
                            <h3>Our Achievement Of Hard Working</h3>
                            <p class="bold">Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.</p>
                            <div class="fun-fact-btn">
                                <a href="#" class="main-default-btn">Join Community</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Fun Fact Style Area -->
        
        <!-- Start Mortgage Quote Area --> */}
        <div class="mortgage-quote-area-with-full-width">
            <div class="container-fluid">
                <div class="row m-0">
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-content">
                            <h3>Get Mortgage Quote</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing eltsed do eiusmod tempor incididunt ut labore et dolore </p>
                            <a href="#" class="quote-btn">Get Mortgage Quote</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-image"></div>
                    </div>
                </div>
            </div>
        </div>
        {/* {/* <!-- End Mortgage Quote Area --> */}

       
        <Findloan/>
        {/* <!-- End Find Own Loan Area -->

       

        <!-- Start Testimonials Style Area --> */}
        {/* <div class="testimonials-style-area bg-ffffff ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-5">
                        <div class="testimonials-style-image">
                            <img src="/assets/img/more-home/testimonials/testimonials-3.jpg" alt="image"/>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-7">
                        <div class="testimonials-style-content with-border-color">
                            <span class="sub-title">Testimonials</span>
                            <h3>People Are Saying About Us.</h3>

                            <div class="testimonials-style-slider owl-carousel owl-theme">
                                <div class="testimonials-style-card">
                                    <div class="info">
                                        <i class='bx bxs-quote-alt-left'></i>
                                        <h4>Leslie Williamson</h4>
                                        <span>Homeowners</span>
                                    </div>
                                    <p>“Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.”</p>
                                    <ul class="star-list">
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                    </ul>
                                </div>

                                <div class="testimonials-style-card">
                                    <div class="info">
                                        <i class='bx bxs-quote-alt-left'></i>
                                        <h4>Leslie Williamson</h4>
                                        <span>Homeowners</span>
                                    </div>
                                    <p>“Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.”</p>
                                    <ul class="star-list">
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                    </ul>
                                </div>

                                <div class="testimonials-style-card">
                                    <div class="info">
                                        <i class='bx bxs-quote-alt-left'></i>
                                        <h4>Leslie Williamson</h4>
                                        <span>Homeowners</span>
                                    </div>
                                    <p>“Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.”</p>
                                    <ul class="star-list">
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        {/* <!-- End Testimonials Style Area -->

        <!-- Start FAQ Style Area --> */}
        <div class="faq-style-area-with-full-width-wrap">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="faq-style-accordion">
                            <span class="sub-title">FAQ</span>
                            <h3>Need Help? Read Popular Questions</h3>

                            <div class="accordion" id="FaqAccordion">
                                <div class="accordion-item">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        How Do You Qualify For A Loan?
                                    </button>
                                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#FaqAccordion">
                                        <div class="accordion-body">
                                            <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="accordion-item">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        A Good Lender Will Clearly Explain Mortgage?
                                    </button>
                                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                        <div class="accordion-body">
                                            <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="accordion-item">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Can You Get A Mortgage Without A Credit Score?
                                    </button>
                                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                        <div class="accordion-body">
                                            <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="accordion-item">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        How Much Home Can You Afford?
                                    </button>
                                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                        <div class="accordion-body">
                                            <p>Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-12">
                        <div class="faq-style-image-wrap"></div>
                    </div>
                </div>
            </div>
        </div>
        {/* {/* <!-- End FAQ Style Area --> */}

<Footer/>
        {/* // <!-- dark version --> */} 
        {/* <div class="dark-version">
            <label id="switch" class="switch">
                <input type="checkbox" onchange="toggleTheme()" id="slider"/>
                <span class="slider round"></span>
            </label>
        </div> */}
        {/* <!-- dark version --> */}

        {/* <!-- Essential JS --> */}
        <script src="/assets/js/jquery.min.js"></script>
        <script src="/assets/js/bootstrap.bundle.min.js"></script>
        {/* <!-- Magnific Popup JS --> */}
        <script src="/assets/js/jquery.magnific-popup.min.js"></script>
        {/* <!-- Owl Carousel JS --> */}
        <script src="/assets/js/owl.carousel.min.js"></script>
        {/* <!-- Nice Select JS --> */}
        <script src="/assets/js/jquery.nice-select.min.js"></script>
        {/* <!-- Form Ajaxchimp JS --> */}
		<script src="/assets/js/jquery.ajaxchimp.min.js"></script>
		{/* <!-- Form Validator JS --> */}
		<script src="/assets/js/form-validator.min.js"></script>
		{/* <!-- Contact JS --> */}
        <script src="/assets/js/contact-form-script.js"></script>
        {/* <!-- Mixitup JS --> */}
        <script src="/assets/js/jquery.mixitup.min.js"></script>
        {/* <!-- Custom JS --> */}
        <script src="/assets/js/custom.js"></script>
    </body>
        
    )
  }
  
  export default Homeloan
