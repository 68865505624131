import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import reduxThunk from 'redux-thunk'
import {
  BrowserRouter
} from 'react-router-dom'
import './index.css'
import './global.scss'
import App from './App.jsx'
import './assets/css/bootstrap.min.css'
import './assets/css/boxicons.min.css'
import './assets/css/magnific-popup.min.css'
import './assets/css/owl.carousel.min.css'
import './assets/css/owl.theme.default.min.css'
import './assets/css/nice-select.min.css'
import './assets/css/animate.min.css'
import './assets/css/dark.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import './assets/css/dark.scss'
import './assets/css/progressbar.min.css'
import './assets/css/responsive.css.map'
import './assets/css/responsive.scss'
import './assets/css/style.css.map'
import './assets/css/style.scss'
import reducers from './reducers/index';
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';
import { composeWithDevTools } from 'redux-devtools-extension'

const middlewares = [reduxThunk]
const middlewareEnhancer = applyMiddleware(...middlewares)
const enhancers = [middlewareEnhancer]
const composedEnhancers = composeWithDevTools(...enhancers)
const store = createStore(reducers, composedEnhancers)


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
