import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import AWS from 'aws-sdk'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { sidebar_check } from '../actions/sidebar'
import { progress_value } from '../actions/progress'
import StatusComponent from '../components/Status';
import utf8 from 'utf8'
import { Storage } from "aws-amplify";
import { Auth } from 'aws-amplify'

const Status = () => {
  
  const dispatch = useDispatch();
	const navigate = useNavigate();


  return (
    <StatusComponent       
    />
  )
}

export default Status