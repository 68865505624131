import React, { useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux';
import PropertyComponent from '../components/Property';
import { sidebar_check } from '../actions/sidebar'
import fetch from '../helpers/methods'
import { propertyAction } from '../actions/property';
import { Auth } from 'aws-amplify'

const Property = () => {
  const dispatch = useDispatch();
  const [propertyRadio, setPropertyRadio] = useState();
  const [mortgageRadio, setMortgageRadio] = useState(null);
  const [property, setProperty] = useState('Completed');
  const [lending, setLending] = useState('Conventional');
  const [propertyPrice, setPropertyPrice] = useState(null)
  const [mortgageType, setMortgageType] = useState('Buy a property')
   
  const propertyData = {    
        mind_property:propertyRadio,
        mind_property_type:property,
        mind_property_price:propertyPrice,
        mortgage_type:mortgageType,
        lending_type:lending,        
        fee_financing:mortgageRadio     
  }

  const handleData = async () => {
    console.log("handleData property");
    let session = await Auth.currentSession();    
    try {
      dispatch(propertyAction(propertyData));      
      const response = axios({
        method: 'post',
        headers:{'x-access-token':session.accessToken.jwtToken, "x-id-token": session?.idToken?.jwtToken},
        url: 'https://89hrz8w5tj.execute-api.eu-west-2.amazonaws.com/test/save_user_data',
        data: propertyData,
        crossDomain: true
      });
            
      if(response.status === 200) {
        dispatch(sidebar_check('Property'));
        //dispatch(sidebar_check('Calculator'));
      }
    }
    catch (errors) {
      console.log('errors', errors)
    }
  }
  
  return (
    <PropertyComponent 
      propertyRadio={propertyRadio}
      setPropertyRadio={setPropertyRadio}
      mortgageRadio={mortgageRadio}
      setMortgageRadio={setMortgageRadio}
      property={property}
      setProperty={setProperty}
      lending={lending}
      setLending={setLending}
      propertyPrice={propertyPrice}
      setPropertyPrice={setPropertyPrice}
      mortgageType={mortgageType}
      setMortgageType={setMortgageType}
      handleData={handleData}
    />
  )
}

export default Property