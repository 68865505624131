
import { useEffect, useState , useRef} from 'react'

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,
    
  } from 'react-bootstrap'
  import Footer from './Footer';
  //   import {GiHamburgerMenu } from "react-icons/gi";
  import { Link , useNavigate } from 'react-router-dom'
  import Calculator from './Calculator'
  import Header from './Header'
  import $ from 'jquery'
  import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//   import OwlCarousel from 'react-owl-carousel';  

//   import 'owl.carousel/dist/assets/owl.carousel.css';  
 
//   import 'owl.carousel/dist/assets/owl.theme.default.css'; 
  //import $ from '../assets/js/bootstrap.bundle.min.js'
 
  
  import '../assets/css/responsive.scss'
//   import { Helmet } from 'react-helmet'
  const Mortgage = (onChange, value) => {
   
    const[sidebar, setSidebar]=useState(false)
    const navigate=useNavigate();
    const [navClass, setNavClass] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef();
    const handleScroll = () => {
      if (window.scrollY > 120) {
        setNavClass('is-sticky');
      } else {
        setNavClass('');
      }
    };
  
   
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
 const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (selectedOption) => {
    onChange(selectedOption.value);
    setIsOpen(false);
  };
  const options = [
    {value: 1, label: 'Option 1'},
    {value: 2, label: 'Option 2'},
    {value: 3, label: 'Option 3'},
    {value: 4, label: 'Option 3'},
  ];
 const responsive = {
  
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1100 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1100, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2
    },
    smallMobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1
      }
  };
 useEffect(() => {
    $('.loader').fadeOut(1500);
    window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };

      localStorage.getItem(sidebar)
      console.log(sidebar)
    
    });
    
    return (
   
    <body data-spy="scroll" data-offset="70">
        {/* <!-- Preloader --> */}
        <div class="loader">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
        {/* <!-- End Preloader --> */}

        {/* <!-- Start Main Top Header Area --> */}
        {/* <div class="main-top-header">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-7">
                        <ul class="top-header-information">
                            <li>
                                <span>Call Us:</span> <a href="tel:4234543123678">+4 234-543 123 678</a>
                            </li>
                            <li>
                                <span>Mail Us:</span> <a href="mailto:info@pento.com">info@pento.com</a>
                            </li>
                        </ul>
                    </div>

                    <div class="col-lg-5 col-md-5">
                        <ul class="top-header-social">
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> */}
        {/* <!-- End Main Top Header Area --> */}

        {/* <!-- Start Main Navbar Area --> */}
        {/* <div class="main-navbar">
            <nav class="navbar navbar-expand-lg navbar-light">
                <div class="container-fluid">
                    <div class="logo">
                        <a href="index-7.html">
                            <img src="/assets/img/logo.png" class="black-logo" alt="Logo"/>
                            <img src="/assets/img/logo-two.png" class="white-logo" alt="Logo"/>
                        </a>
                    </div>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a href="#home" class="nav-link">
                                    Home
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#about" class="nav-link">
                                    About
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#services" class="nav-link">
                                    Our Services
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#team" class="nav-link">
                                    Team
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#blog" class="nav-link">
                                    Blog
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#contact" class="nav-link">
                                    Contact
                                </a>
                            </li>
                        </ul>
    
                        <div class="others-option">
                            <div class="d-flex align-items-center">
                                <div class="option-item">
                                    <a href="#" class="log-in">Log In</a>
                                </div>
                                <div class="option-item">
                                    <a href="#" class="sign-up">Sign Up</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div> */}
        <Header/>
        {/* <!-- End Main Navbar Area --> */}

        {/* <!-- Start Main Banner Area --> */}
        <div id="home" class="main-banner-area" style={{marginTop:"80px"}}>
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span class="sub-title">Reliable Sustainable Provider</span>
                            <h1>Mortgage Is A Great For You</h1>
                            <div class="banner-popup align-items-center">
                                <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="video-btn popup-youtube"><i class="bx bx-play"></i> <span>Intro With Company</span></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-form-wrap">
                            <h3>Calculate your Mortgage</h3>
                            
                            <form>
                                <div class="form-group">
                                    <label>Total Amount</label>
                                
                                    <div class="select-box">
                                        {/* <select>
                                            <option>$50,000</option>
                                            <option>$60,000</option>
                                            <option>$70,000</option>
                                            <option>$80,000</option>
                                            <option>$90,000</option>
                                            <option>$40,000</option>
                                        </select> */}
                                        <div
      ref={selectRef}
      className={`nice-select${isOpen ? " open" : ""}`}
      tabIndex={0}
      onClick={toggleSelect}
    >
      <span className="current">
        $50,000
      </span>
      {/* <ul className="list">
        {options.map((option) => (
          <li
            key={option.value}
            className={`option${option.value === value ? " selected" : ""}`}
            onClick={() => handleOptionClick(option)}
          >
            {option.text}
          </li>
        ))}
      </ul> */}
       <ul class="list">
                                                <li data-value="$50,000" class="option focus"  onClick={() => handleOptionClick(value)}>$50,000</li>
                                                <li data-value="$60,000" class="option"  onClick={() => handleOptionClick(value)}>$60,000</li>
                                                <li data-value="$70,000" class="option" onClick={() => handleOptionClick(value)}>$70,000</li>
                                                <li data-value="$80,000" class="option" onClick={() => handleOptionClick(value)}>$80,000</li>
                                                <li data-value="$90,000" class="option" onClick={() => handleOptionClick(value)}>$90,000</li>
                                                <li data-value="$40,000" class="option selected" onClick={() => handleOptionClick(value)}>$40,000</li>
                                                </ul>
    </div>
                                        {/* <div className='nice-select' tabindex='0'>
                                            <span className='current'>$40,000</span>
                                            <ul class="list">
                                                <li data-value="$50,000" class="option focus">$50,000</li>
                                                <li data-value="$60,000" class="option">$60,000</li>
                                                <li data-value="$70,000" class="option">$70,000</li>
                                                <li data-value="$80,000" class="option">$80,000</li>
                                                <li data-value="$90,000" class="option">$90,000</li>
                                                <li data-value="$40,000" class="option selected">$40,000</li>
                                                </ul>
                                        </div>
                                     */}
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Interest Rate</label>
                                
                                    <div class="select-box">
                                    <div className='nice-select' tabindex='0'>
                                            <span className='current'>25%</span>
                                            <ul class="list"><li data-value="5%" class="option focus">5%</li>
                                            <li data-value="10%" class="option">10%</li>
                                            <li data-value="15%" class="option">15%</li>
                                            <li data-value="20%" class="option">20%</li>
                                            <li data-value="25%" class="option selected">25%</li>
                                            <li data-value="30%" class="option">30%</li></ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Down Payment</label>
                                
                                    <div class="select-box">
                                    <div className='nice-select' tabindex='0'>
                                            <span className='current'>$70,000</span>
                                            <ul class="list"><li data-value="$50,000" class="option focus">$50,000</li>
                                            <li data-value="$60,000" class="option">$60,000</li>
                                            <li data-value="$70,000" class="option selected">$70,000</li>
                                            <li data-value="$80,000" class="option">$80,000</li>
                                            <li data-value="$90,000" class="option">$90,000</li>
                                            <li data-value="$40,000" class="option">$40,000</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Mortgage Period</label>
                                
                                    <div class="select-box">
                                    <div className='nice-select' tabindex='0'>
                                            <span className='current'>15 Years</span>
                                            <ul class="list"><li data-value="15 Years" class="option focus selected">15 Years</li>
                                            <li data-value="16 Years" class="option">16 Years</li>
                                            <li data-value="17 Years" class="option">17 Years</li>
                                            <li data-value="18 Years" class="option">18 Years</li>
                                            <li data-value="19 Years" class="option">19 Years</li>
                                            <li data-value="20 Years" class="option">20 Years</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="calculat-button">
                                    <button type="submit" class="default-btn">Calculate Mortgage</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Main Banner Area -->
        
        <!-- Start About Style Area --> */}
        <div id="about" class="about-style-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-style-image">
                            <img src="/assets/images/mortgage2.jpg" alt="image"/>

                            <div class="quote-content">
                                <p>“Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.”</p>
                                <span>Brokly Simmons</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="about-style-content">
                            <span class="sub-title">About Our Company</span>
                            <h3>Why Should You Choose Us?</h3>
                            <p class="bold">Sed porttitor lectus nibh. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vivamus magna justo lacinia eget consectetur.</p>
                            <p>Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>

                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="counter-box">
                                        <h4>20+</h4>
                                        <span>Years Of Experience</span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-6">
                                    <div class="counter-box">
                                        <h4>90%</h4>
                                        <span>Customer Worldwide</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End About Area -->

        <!-- Start Core Service Area --> */}
        <div id="services" class="core-service-area ptb-100">
            <div class="container">
                <div class="main-section-title">
                    <span class="sub-title">What We Offer</span>
                    <h2>Our Core Service</h2>
                </div>

                <div class="core-service-list-tab">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="core-service-1-tab" data-bs-toggle="tab" href="#core-service-1" role="tab" aria-controls="core-service-1">Purchase New House</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" id="core-service-2-tab" data-bs-toggle="tab" href="#core-service-2" role="tab" aria-controls="core-service-2">Apply For Loan</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" id="core-service-3-tab" data-bs-toggle="tab" href="#core-service-3" role="tab" aria-controls="core-service-3">Refinancing Your Home</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" id="core-service-4-tab" data-bs-toggle="tab" href="#core-service-4" role="tab" aria-controls="core-service-4">Capital Management</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" id="core-service-5-tab" data-bs-toggle="tab" href="#core-service-5" role="tab" aria-controls="core-service-5">Renovation</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" id="core-service-6-tab" data-bs-toggle="tab" href="#core-service-6" role="tab" aria-controls="core-service-6">Refinance Advisor</a>
                        </li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="core-service-1" role="tabpanel">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-lg-7 col-md-6">
                                    <div class="core-service-content">
                                        <div class="icon">
                                            <i class="bx bxs-building-house"></i>
                                        </div>
                                        <h3>Purchase New House</h3>
                                        <p>Nulla quis lorem ut libero malesuada feugiat. Quisque velit nisi pretium ut lacinia in elementum id enim. Donec rutrum congue leo eget malesuada. Sed porttitor lectus nibh. Proin eget tortor risus.</p>
                                        <ul class="list">
                                            <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                            <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                            <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                            <li><i class='bx bx-chevrons-right'></i> Unquestionable integrity</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-6">
                                    <div class="core-service-image">
                                        <img src="/assets/images/mortgage5.jpg" alt="image"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="core-service-2" role="tabpanel">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-lg-7 col-md-6">
                                    <div class="core-service-content">
                                        <div class="icon">
                                            <i class="bx bxs-dollar-circle"></i>
                                        </div>
                                        <h3>Apply For Loan</h3>
                                        <p>Nulla quis lorem ut libero malesuada feugiat. Quisque velit nisi pretium ut lacinia in elementum id enim. Donec rutrum congue leo eget malesuada. Sed porttitor lectus nibh. Proin eget tortor risus.</p>
                                        <ul class="list">
                                            <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                            <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                            <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                            <li><i class='bx bx-chevrons-right'></i> Unquestionable integrity</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-6">
                                    <div class="core-service-image">
                                        <img src="/assets/img/more-home/core-service/core-service-2.jpg" alt="image"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="core-service-3" role="tabpanel">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-lg-7 col-md-6">
                                    <div class="core-service-content">
                                        <div class="icon">
                                            <i class="bx bxs-home-circle"></i>
                                        </div>
                                        <h3>Refinancing Your Home</h3>
                                        <p>Nulla quis lorem ut libero malesuada feugiat. Quisque velit nisi pretium ut lacinia in elementum id enim. Donec rutrum congue leo eget malesuada. Sed porttitor lectus nibh. Proin eget tortor risus.</p>
                                        <ul class="list">
                                            <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                            <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                            <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                            <li><i class='bx bx-chevrons-right'></i> Unquestionable integrity</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-6">
                                    <div class="core-service-image">
                                        <img src="/assets/img/more-home/core-service/core-service-3.jpg" alt="image"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="core-service-4" role="tabpanel">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-lg-7 col-md-6">
                                    <div class="core-service-content">
                                        <div class="icon">
                                            <i class="bx bxs-hand-up"></i>
                                        </div>
                                        <h3>Capital Management</h3>
                                        <p>Nulla quis lorem ut libero malesuada feugiat. Quisque velit nisi pretium ut lacinia in elementum id enim. Donec rutrum congue leo eget malesuada. Sed porttitor lectus nibh. Proin eget tortor risus.</p>
                                        <ul class="list">
                                            <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                            <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                            <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                            <li><i class='bx bx-chevrons-right'></i> Unquestionable integrity</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-6">
                                    <div class="core-service-image">
                                        <img src="/assets/img/more-home/core-service/core-service-4.jpg" alt="image"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="core-service-5" role="tabpanel">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-lg-7 col-md-6">
                                    <div class="core-service-content">
                                        <div class="icon">
                                            <i class="bx bxs-wrench"></i>
                                        </div>
                                        <h3>Renovation House</h3>
                                        <p>Nulla quis lorem ut libero malesuada feugiat. Quisque velit nisi pretium ut lacinia in elementum id enim. Donec rutrum congue leo eget malesuada. Sed porttitor lectus nibh. Proin eget tortor risus.</p>
                                        <ul class="list">
                                            <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                            <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                            <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                            <li><i class='bx bx-chevrons-right'></i> Unquestionable integrity</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-6">
                                    <div class="core-service-image">
                                        <img src="/assets/img/more-home/core-service/core-service-5.jpg" alt="image"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="core-service-6" role="tabpanel">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-lg-7 col-md-6">
                                    <div class="core-service-content">
                                        <div class="icon">
                                            <i class="bx bxs-user-circle"></i>
                                        </div>
                                        <h3>Refinance Advisor</h3>
                                        <p>Nulla quis lorem ut libero malesuada feugiat. Quisque velit nisi pretium ut lacinia in elementum id enim. Donec rutrum congue leo eget malesuada. Sed porttitor lectus nibh. Proin eget tortor risus.</p>
                                        <ul class="list">
                                            <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                            <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                            <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                            <li><i class='bx bx-chevrons-right'></i> Unquestionable integrity</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-6">
                                    <div class="core-service-image">
                                        <img src="/assets/img/more-home/core-service/core-service-6.jpg" alt="image"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Core Service Area -->

        <!-- Start Buying Home Area --> */}
        <div class="buying-home-area ptb-100">
            <div class="container-fluid">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-5 col-md-12">
                        <div class="buying-home-image">
                            <img src="/assets/images/mortgage6.jpg" alt="image"/>

                            <div class="counter-box">
                                <h4>56+</h4>
                                <span>Veteran Homes Completed</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="buying-home-content">
                            <span class="sub-title">Buying A Home</span>
                            <h3>We Are Here To Help You</h3>
                            <p>Sed porttitor lectus nibh quisque velit nisi pretium ut lacinia in elementum id enim. Mauris blandit aliquet elit eget tincidunt nibh pulvinar convallis at tellus.</p>

                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Make it Your Dream</h4>
                                        <p>When you own your own homet seeturlit sed dolor sit am</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Create A Place</h4>
                                        <p>You can grow in your new homeit amet seeturlit sed dolor sit</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Be A Part Of Community</h4>
                                        <p>Buying a home is an investmemet seeturlit sed dolo ameol</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Pay Principal</h4>
                                        <p>Gain home equity by paying sit amet seeturlit sed dolor sit</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Get Tax Benefits</h4>
                                        <p>Take advantage of tax benefitsit amet seeturlit sed dolor sit</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Build Credit</h4>
                                        <p>Our plan dolor sit amet seeturlit sed dolor sit ameolor sit</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Buying Home Area -->

        <!-- Start Mortgage Quote Area --> */}
        <div class="mortgage-quote-area pb-100">
            <div class="container">
                <div class="row m-0">
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-content">
                            <h3>Get Mortgage Quote</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing eltsed do eiusmod tempor incididunt ut labore et dolore </p>
                            <a href="https://www.user.bcf-mortgage.com/" class="quote-btn">Get Mortgage Quote</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-image"></div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Mortgage Quote Area -->

        <!-- Start Why Choose Us Area --> */}
        {/* <div class="why-choose-us-area ptb-100">
            <div class="container">
                <div class="main-section-title">
                    <span class="sub-title">Why Choose Us</span>
                    <h2>Among Them We’re</h2>
                </div>

                <div class="why-choose-us-slider owl-carousel owl-theme">
                    <div class="why-choose-us-card">
                        <div class="choose-image">
                            <img src="/assets/img/more-home/choose/choose-1.jpg" alt="image"/>
                        </div>
                        <div class="choose-content">
                            <h3>Realtor Giving Keys To The Happy New Owner</h3>
                            <p>Choose started sit amet consectetur adipi elit sed do eiusmod tempor incididunt.</p>
                            <a href="#" class="choose-btn">Read More</a>
                        </div>
                    </div>

                    <div class="why-choose-us-card">
                        <div class="choose-image">
                            <img src="/assets/img/more-home/choose/choose-2.jpg" alt="image"/>
                        </div>
                        <div class="choose-content">
                            <h3>Refinancing Your Home No Doubt About Anything</h3>
                            <p>Choose started sit amet consectetur adipi elit sed do eiusmod tempor incididunt.</p>
                            <a href="#" class="choose-btn">Read More</a>
                        </div>
                    </div>

                    <div class="why-choose-us-card">
                        <div class="choose-image">
                            <img src="/assets/img/more-home/choose/choose-3.jpg" alt="image"/>
                        </div>
                        <div class="choose-content">
                            <h3>Delivering A Great Customer Experience</h3>
                            <p>Choose started sit amet consectetur adipi elit sed do eiusmod tempor incididunt.</p>
                            <a href="#" class="choose-btn">Read More</a>
                        </div>
                    </div>

                    <div class="why-choose-us-card">
                        <div class="choose-image">
                            <img src="/assets/img/more-home/choose/choose-1.jpg" alt="image"/>
                        </div>
                        <div class="choose-content">
                            <h3>Realtor Giving Keys To The Happy New Owner</h3>
                            <p>Choose started sit amet consectetur adipi elit sed do eiusmod tempor incididunt.</p>
                            <a href="#" class="choose-btn">Read More</a>
                        </div>
                    </div>

                    <div class="why-choose-us-card">
                        <div class="choose-image">
                            <img src="/assets/img/more-home/choose/choose-2.jpg" alt="image"/>
                        </div>
                        <div class="choose-content">
                            <h3>Refinancing Your Home No Doubt About Anything</h3>
                            <p>Choose started sit amet consectetur adipi elit sed do eiusmod tempor incididunt.</p>
                            <a href="#" class="choose-btn">Read More</a>
                        </div>
                    </div>

                    <div class="why-choose-us-card">
                        <div class="choose-image">
                            <img src="/assets/img/more-home/choose/choose-3.jpg" alt="image"/>
                        </div>
                        <div class="choose-content">
                            <h3>Delivering A Great Customer Experience</h3>
                            <p>Choose started sit amet consectetur adipi elit sed do eiusmod tempor incididunt.</p>
                            <a href="#" class="choose-btn">Read More</a>
                        </div>
                    </div>

                    <div class="why-choose-us-card">
                        <div class="choose-image">
                            <img src="/assets/img/more-home/choose/choose-1.jpg" alt="image"/>
                        </div>
                        <div class="choose-content">
                            <h3>Realtor Giving Keys To The Happy New Owner</h3>
                            <p>Choose started sit amet consectetur adipi elit sed do eiusmod tempor incididunt.</p>
                            <a href="#" class="choose-btn">Read More</a>
                        </div>
                    </div>

                    <div class="why-choose-us-card">
                        <div class="choose-image">
                            <img src="/assets/img/more-home/choose/choose-2.jpg" alt="image"/>
                        </div>
                        <div class="choose-content">
                            <h3>Refinancing Your Home No Doubt About Anything</h3>
                            <p>Choose started sit amet consectetur adipi elit sed do eiusmod tempor incididunt.</p>
                            <a href="#" class="choose-btn">Read More</a>
                        </div>
                    </div>

                    <div class="why-choose-us-card">
                        <div class="choose-image">
                            <img src="/assets/img/more-home/choose/choose-3.jpg" alt="image"/>
                        </div>
                        <div class="choose-content">
                            <h3>Delivering A Great Customer Experience</h3>
                            <p>Choose started sit amet consectetur adipi elit sed do eiusmod tempor incididunt.</p>
                            <a href="#" class="choose-btn">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        {/* <!-- End Why Choose Us Area -->

        <!-- Start Find Loan Officer Area --> */}
        <div class="find-loan-officer-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="find-loan-officer-form">
                            <h3>Find A Loan Officer</h3>
                            
                            <div class="loan-check-box">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked/>
                                    <label class="form-check-label" for="exampleRadios1">
                                        Real Estate Agents
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2"/>
                                    <label class="form-check-label" for="exampleRadios2">
                                        Loan Officer
                                    </label>
                                </div>
                            </div>

                            <form>
                                <div class="form-group">
                                    <label>First Name</label>
                                    <input type="text" class="form-control" placeholder="Darlene"/>
                                </div>
                                <div class="form-group">
                                    <label>Last Name</label>
                                    <input type="text" class="form-control" placeholder="Robertson"/>
                                </div>
                                <div class="form-group">
                                    <label>License State</label>
                                
                                    <div class="select-box">
                                        <select>
                                            <option>License State</option>
                                            <option>Canada</option>
                                            <option>Japan</option>
                                            <option>Germany</option>
                                            <option>Switzerland</option>
                                            <option>Australia</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="loan-button">
                                    <button type="submit" class="default-btn">Search Your Result</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="find-loan-officer-image">
                            <img src="/assets/images/mortgage7.jpg" alt="image"/>

                            <div class="find-loan-popup">
                                <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="video-btn popup-youtube">
                                    <i class='bx bx-play-circle'></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Find A Loan Officer Area -->

        <!-- Start Staff Area --> */}
        {/* <div id="team" class="staff-area ptb-100">
            <div class="container">
                <div class="main-section-title">
                    <span class="sub-title">Our Team</span>
                    <h2>Our Staff Profiles</h2>
                </div>

                <div class="staff-slider owl-carousel owl-theme">
                    <div class="staff-card">
                        <div class="staff-image">
                            <img src="/assets/img/more-home/staff/staff-1.jpg" alt="image"/>

                            <ul class="social-link">
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>David Driscoll</h3>
                            <span>Manager</span>
                        </div>
                    </div>

                    <div class="staff-card">
                        <div class="staff-image">
                            <img src="/assets/img/more-home/staff/staff-2.jpg" alt="image"/>

                            <ul class="social-link">
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>Andon Wardin</h3>
                            <span>Loan Officer</span>
                        </div>
                    </div>

                    <div class="staff-card">
                        <div class="staff-image">
                            <img src="/assets/img/more-home/staff/staff-3.jpg" alt="image"/>

                            <ul class="social-link">
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>Mark Morrell</h3>
                            <span>Area Managerr</span>
                        </div>
                    </div>

                    <div class="staff-card">
                        <div class="staff-image">
                            <img src="/assets/img/more-home/staff/staff-4.jpg" alt="image"/>

                            <ul class="social-link">
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>Jubin Indona</h3>
                            <span>Accountant</span>
                        </div>
                    </div>

                    <div class="staff-card">
                        <div class="staff-image">
                            <img src="/assets/img/more-home/staff/staff-1.jpg" alt="image"/>

                            <ul class="social-link">
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>David Driscoll</h3>
                            <span>Manager</span>
                        </div>
                    </div>

                    <div class="staff-card">
                        <div class="staff-image">
                            <img src="/assets/img/more-home/staff/staff-2.jpg" alt="image"/>

                            <ul class="social-link">
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>Andon Wardin</h3>
                            <span>Loan Officer</span>
                        </div>
                    </div>

                    <div class="staff-card">
                        <div class="staff-image">
                            <img src="/assets/img/more-home/staff/staff-3.jpg" alt="image"/>

                            <ul class="social-link">
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>Mark Morrell</h3>
                            <span>Area Managerr</span>
                        </div>
                    </div>

                    <div class="staff-card">
                        <div class="staff-image">
                            <img src="/assets/img/more-home/staff/staff-4.jpg" alt="image"/>

                            <ul class="social-link">
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>Jubin Indona</h3>
                            <span>Accountant</span>
                        </div>
                    </div>

                    <div class="staff-card">
                        <div class="staff-image">
                            <img src="/assets/img/more-home/staff/staff-1.jpg" alt="image"/>

                            <ul class="social-link">
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>David Driscoll</h3>
                            <span>Manager</span>
                        </div>
                    </div>

                    <div class="staff-card">
                        <div class="staff-image">
                            <img src="/assets/img/more-home/staff/staff-2.jpg" alt="image"/>

                            <ul class="social-link">
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>Andon Wardin</h3>
                            <span>Loan Officer</span>
                        </div>
                    </div>

                    <div class="staff-card">
                        <div class="staff-image">
                            <img src="/assets/img/more-home/staff/staff-3.jpg" alt="image"/>

                            <ul class="social-link">
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>Mark Morrell</h3>
                            <span>Area Managerr</span>
                        </div>
                    </div>

                    <div class="staff-card">
                        <div class="staff-image">
                            <img src="/assets/img/more-home/staff/staff-4.jpg" alt="image"/>

                            <ul class="social-link">
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="staff-content">
                            <h3>Jubin Indona</h3>
                            <span>Accountant</span>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        {/* <!-- End Staff Area -->

        <!-- Start Need Help FAQ Area --> */}
        <div class="need-help-faq-area pb-100">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="need-help-faq-content">
                            <span class="sub-title">FAQ</span>
                            <h3>Need Help? Read Popular Questions</h3>
    
                            <div class="need-help-faq-accordion">
                                <div class="accordion" id="FaqAccordion">
                                    <div class="accordion-item">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            How Do You Qualify For A Loan?
                                        </button>
                                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#FaqAccordion">
                                            <div class="accordion-body">
                                                <p>Donec sollicitudin molestie malesuada. Vivamus magna justo lacinia eget consectetur sed convallis at tellus. Quisque velit nisi, pretium ut lacinia in elementum id enim. Quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="accordion-item">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            A Good Lender Will Clearly Explain Mortgage?
                                        </button>
                                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                            <div class="accordion-body">
                                                <p>Donec sollicitudin molestie malesuada. Vivamus magna justo lacinia eget consectetur sed convallis at tellus. Quisque velit nisi, pretium ut lacinia in elementum id enim. Quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="accordion-item">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Can You Get A Mortgage Without A Credit Score?
                                        </button>
                                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                            <div class="accordion-body">
                                                <p>Donec sollicitudin molestie malesuada. Vivamus magna justo lacinia eget consectetur sed convallis at tellus. Quisque velit nisi, pretium ut lacinia in elementum id enim. Quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="accordion-item">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            How Much Home Can You Afford?
                                        </button>
                                        <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                            <div class="accordion-body">
                                                <p>Donec sollicitudin molestie malesuada. Vivamus magna justo lacinia eget consectetur sed convallis at tellus. Quisque velit nisi, pretium ut lacinia in elementum id enim. Quisque velit nisi pretium ut lacinia in elementum id enim.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-lg-6 col-md-12">
                        <div class="need-help-faq-image">
                            <img src="/assets/images/mortgage8.jpg" alt="image"/>

                            <div class="help-box">
                                <h3>Find</h3>
                                <span>Questions & Answers</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Need Help FAQ Area -->

        <!-- Start Testimonials Style Area --> */}
        {/* <div class="testimonials-style-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-5">
                        <div class="testimonials-style-image">
                            <img src="/assets/img/more-home/testimonials/testimonials.png" alt="image"/>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-7">
                        <div class="testimonials-style-content">
                            <span class="sub-title">Testimonials</span>
                            <h3>People Are Saying About Us.</h3>

                            <div class="testimonials-style-slider owl-carousel owl-theme">
                                <div class="testimonials-style-card">
                                    <div class="info">
                                        <i class='bx bxs-quote-alt-left'></i>
                                        <h4>Leslie Williamson</h4>
                                        <span>Homeowners</span>
                                    </div>
                                    <p>“Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.”</p>
                                    <ul class="star-list">
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                    </ul>
                                </div>

                                <div class="testimonials-style-card">
                                    <div class="info">
                                        <i class='bx bxs-quote-alt-left'></i>
                                        <h4>Leslie Williamson</h4>
                                        <span>Homeowners</span>
                                    </div>
                                    <p>“Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.”</p>
                                    <ul class="star-list">
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                    </ul>
                                </div>

                                <div class="testimonials-style-card">
                                    <div class="info">
                                        <i class='bx bxs-quote-alt-left'></i>
                                        <h4>Leslie Williamson</h4>
                                        <span>Homeowners</span>
                                    </div>
                                    <p>“Donec sollicitudin molestie malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Quisque velit nisi, pretium ut lacinia in, elementum id enim.”</p>
                                    <ul class="star-list">
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                        <li><i class='bx bx-star'></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        {/* <!-- End Testimonials Style Area -->

        <!-- Start Let’s Contact Area --> */}
        <div id="contact" class="let-contact-area">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="let-contact-form">
                            <span class="sub-title">Let’s Contact</span>
                            <h3>Contact With Us</h3>

                            <form id="contactForm">
                                <div class="row m-0">
                                    <div class="col-sm-6 col-lg-6">
                                        <div class="form-group">
                                            <label>Name</label>
                                            <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="William"/>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
        
                                    <div class="col-sm-6 col-lg-6">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="support@bcf.ae"/>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 col-lg-6">
                                        <div class="form-group">
                                            <label>Phone Number</label>
                                            <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="+971 42014000"/>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
            
                                    <div class="col-sm-6 col-lg-6">
                                        <div class="form-group">
                                            <label>Subject</label>
                                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="your subject"/>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
        
                                    <div class="col-md-12 col-lg-12">
                                        <div class="form-group">
                                            <label>Your Message</label>
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message" placeholder="write your message"></textarea>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
        
                                    <div class="col-md-12 col-lg-12">
                                        <div class="let-contact-btn">
                                            <button type="submit" class="main-default-btn">Send Message</button>
                                            <div id="msgSubmit" class="h3 text-center hidden"></div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="let-contact-map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96873.54747595973!2d-74.01503723537454!3d40.64535309481121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24416947c2109%3A0x82765c7404007886!2sBrooklyn%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1644602327980!5m2!1sen!2sbd"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {/* <!-- End Let’s Contact Area --> */}

        
<Footer/>
        {/* <!-- dark version --> */}
        {/* <div class="dark-version">
            <label id="switch" class="switch">
                <input type="checkbox" onchange="toggleTheme()" id="slider"/>
                <span class="slider round"></span>
            </label>
        </div> */}
        {/* <!-- dark version --> */}

        {/* <!-- Essential JS --> */}
        <script src="/assets/js/jquery.min.js"></script>
        <script src="/assets/js/bootstrap.bundle.min.js"></script>
        {/* <!-- Magnific Popup JS --> */}
        <script src="/assets/js/jquery.magnific-popup.min.js"></script>
        {/* <!-- Owl Carousel JS --> */}
        <script src="/assets/js/owl.carousel.min.js"></script>
        {/* <!-- Nice Select JS --> */}
        <script src="/assets/js/jquery.nice-select.min.js"></script>
        {/* <!-- Form Ajaxchimp JS --> */}
		<script src="/assets/js/jquery.ajaxchimp.min.js"></script>
		{/* <!-- Form Validator JS --> */}
		<script src="/assets/js/form-validator.min.js"></script>
		{/* <!-- Contact JS --> */}
        <script src="/assets/js/contact-form-script.js"></script>
        {/* <!-- Mixitup JS --> */}
        <script src="/assets/js/jquery.mixitup.min.js"></script>
        {/* <!-- Custom JS --> */}
        <script src="/assets/js/custom.js"></script>
    </body>
        
    )
  }
  
  export default Mortgage
