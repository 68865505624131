import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Auth } from 'aws-amplify'
import LoginComponent from '../../components/Auth/Login'

const Login = () => {
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState()
  const [loading, setLoading] = useState(false)
  const onLogin = async () => {
    setLoading(true)
    try {
      console.log(email); 
      let result = await Auth.signIn(email);
      window.cognitoUser=result            
      navigate('/confirmation')
    } 
    catch (error) {     
      try {
        await Auth.signUp({
          username: email,
          password: email,
          attributes: {
            email: email,
            phone_number: phone,
          }          
        });
        let result=await Auth.signIn(email);
        window.cognitoUser=result
        navigate('/confirmation')
      }
      catch(ex) {
        console.log(ex);
      }
      
    }
    setLoading(false)
  }
  return (
  <LoginComponent 
    email={email} 
    phone={phone}
    setEmail={setEmail}
    setPhone={setPhone}
    onLogin={onLogin} 
    loading={loading}
    />
  )
  }

export default Login